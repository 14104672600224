var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-toolbar',{attrs:{"height":_vm.toolbar_height,"text":"","color":_vm.$vuetify.theme.dark ? '' : 'white',"loading":_vm.isLoading}},[_c('v-row',[(_vm.toolbar_height > 40)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$emit('go-back')}}},[_vm._v(" "+_vm._s(_vm.$t("custom.goback"))+" ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","text":"","color":"green darken-1","to":"/calendars/new"}},[_vm._v(" "+_vm._s(_vm.$t("custom.to_calendar"))+" ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","text":"","color":"blue darken-1","to":"/bookings"}},[_vm._v(" "+_vm._s(_vm.$t("bookings.to_list"))+" ")])],1):_vm._e(),_c('v-col',{class:_vm.isMobile ? 'd-flex justify-center align-center' : 'd-flex justify-end align-center',attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('tooltips.booking_history'),
          placement: 'bottom-center',
          classes: ['black--text', 'white'],
          targetClasses: ['it-has-a-tooltip'],
          delay: { show: 500, hide: 500 },
        }),expression:"{\n          content: $t('tooltips.booking_history'),\n          placement: 'bottom-center',\n          classes: ['black--text', 'white'],\n          targetClasses: ['it-has-a-tooltip'],\n          delay: { show: 500, hide: 500 },\n        }"}],attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('get-history')}}},[_c('v-icon',[_vm._v("mdi-clipboard-text-clock-outline")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('tooltips.download_icalendar'),
          placement: 'bottom-center',
          classes: ['black--text', 'white'],
          targetClasses: ['it-has-a-tooltip'],
          delay: { show: 500, hide: 500 },
        }),expression:"{\n          content: $t('tooltips.download_icalendar'),\n          placement: 'bottom-center',\n          classes: ['black--text', 'white'],\n          targetClasses: ['it-has-a-tooltip'],\n          delay: { show: 500, hide: 500 },\n        }"}],attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('download-icalendar', _vm.booking.id)}}},[_c('v-icon',[_vm._v("mdi-calendar-export")])],1),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.connected ? _vm.$t('custom.connected') : _vm.$t('custom.disconnected'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: { show: 500, hide: 500 },
            }),expression:"{\n              content: connected ? $t('custom.connected') : $t('custom.disconnected'),\n              placement: 'bottom-center',\n              classes: ['info'],\n              targetClasses: ['it-has-a-tooltip'],\n              delay: { show: 500, hide: 500 },\n            }"}],class:'pulse mt-3 mx-2 ' + (_vm.connected ? 'pulse_success' : 'pulse_error')}),_c('v-btn',{attrs:{"icon":"","href":`https://rentprog.${_vm.$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${_vm.$root.$i18n.locale}/categories/2`,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-help-circle")])],1)],1)],1)],1),(_vm.booking && !_vm.isLoading)?_c('v-card-title',{staticClass:"pt-0 pb-0 start-tour-booking-1"},[_c('v-row',{class:!_vm.booking.technical ? 'd-flex align-center mt-1' : 'd-flex align-center blue-grey lighten-4 mt-1'},[_c('v-col',{staticClass:"d-flex align-center mb-1",attrs:{"cols":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_c('v-row',[_c('v-col',{class:_vm.isMobile ? 'd-flex align-center justify-center' : 'd-flex align-center',attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[(_vm.booking.vseprokaty_id)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.booking.active ? 'warning' : 'secondary',"text-color":"white"}},[_c('v-avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('agregator.from_agregator'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: { show: 500, hide: 500 },
              }),expression:"{\n                content: $t('agregator.from_agregator'),\n                placement: 'bottom-center',\n                classes: ['info'],\n                targetClasses: ['it-has-a-tooltip'],\n                delay: { show: 500, hide: 500 },\n              }"}],attrs:{"left":"","color":"secondary darken-2"}},[_c('v-icon',[_vm._v("mdi-alpha-a-circle-outline")])],1),(_vm.booking.fast_booking)?_c('v-avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('agregator.fast_booking'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: { show: 500, hide: 500 },
              }),expression:"{\n                content: $t('agregator.fast_booking'),\n                placement: 'bottom-center',\n                classes: ['info'],\n                targetClasses: ['it-has-a-tooltip'],\n                delay: { show: 500, hide: 500 },\n              }"}],attrs:{"left":"","color":"error darken-2"}},[_c('v-icon',[_vm._v("mdi-lightning-bolt-circle")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("bookings.booking"))+" "+_vm._s(_vm.$t("custom.number"))+_vm._s(_vm.booking.id)+" ")],1):_c('h3',{class:_vm.isMobile ? 'headline text-center' : 'headline text-center mx-2',attrs:{"id":"registration-step-64","nowrap":""}},[_vm._v(" "+_vm._s(_vm.$t("bookings.booking"))+" "+_vm._s(_vm.$t("custom.number"))+_vm._s(_vm.booking.id)+" ")])],1),_c('v-col',{class:_vm.isMobile ? 'd-flex align-center justify-center' : 'd-flex align-center justify-end',attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},_vm._l((_vm.statesList),function(state){return (_vm.booking.state === state.value)?_c('v-menu',{key:state.value,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":"","color":_vm.getStateColor(state.value),"loading":_vm.isLoadingSaveState}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.getStateIcon(_vm.booking.state)))]),_vm._v(" "+_vm._s(_vm.getStateTooltip(_vm.booking.state))+" ")],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.bookings_states),function(menuState,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.$emit('save-state', menuState.value, _vm.booking.id)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.getStateColor(menuState.value)}},[_vm._v(_vm._s(_vm.getStateIcon(menuState.value)))])],1),_c('v-list-item-title',[_vm._v(_vm._s(menuState.text))])],1)}),1)],1):_vm._e()}),1)],1)],1)],1)],1):_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }