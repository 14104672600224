<template>
  <v-container v-resize="checkMobile" :style="isMobile ? 'padding: 5px !important;' : ''">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-expansion-panels v-if="client" v-model="dataPanel" :style="user_role == 'guest' || user_role == 'partner'
          ? 'display: none;'
          : ''
          ">
          <v-expansion-panel v-if="!client.entity">
            <v-expansion-panel-header>
              <h2 v-if="!isMobile" class="mb-0 d-flex">
                {{ client.lastname }} {{ client.name }} {{ client.middlename }}
                {{ client.phone }}
              </h2>
              <h4 v-else class="subtitle-1 mb-0 d-flex">
                {{ client.lastname }} {{ client.name }} {{ client.middlename }}
                {{ client.phone }}
              </h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container grid-list-md :style="isMobile ? 'padding: 5px !important;' : ''">
                <v-layout row wrap>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.lastname" :label="$t('bookings.lastname')" outlined hide-details
                      :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.name" :label="$t('bookings.name')" outlined hide-details :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.middlename" :label="$t('bookings.middlename')" outlined hide-details
                      :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.fio" :label="$t('clients.fio')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.phone" :label="$t('custom.phone')" type="tel" outlined hide-details
                      :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.email" :label="$t('custom.email')" type="email" outlined hide-details
                      :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-if="showEditBirthday" v-model="client.birthday" @click="showEditBirthday = true"
                      :label="$t('bookings.birthdate')" :placeholder="$t('bookings.birthdate_plc')" outlined
                      hide-details :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                    <v-text-field v-else v-model="birthday_non_format" :label="$t('bookings.birthdate')"
                      v-mask="'##.##.####'" :placeholder="$t('bookings.birthdate_plc')" outlined hide-details :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-select v-model="client.category" :items="customer_category" item-text="text" item-value="value"
                      :label="$t('bookings.client_category')" outlined></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md2>
                    <v-text-field v-model="client.passport_series" :label="$t('custom.passport_series')" outlined
                      hide-details :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.passport_number" :label="$t('custom.passport_number')" outlined
                      hide-details :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.passport_issued" :label="$t('custom.passport_issued')" outlined
                      hide-details :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.inn" :label="$t('custom.tax_number')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.country" :label="$t('custom.country')" outlined
                      hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.city" :label="$t('custom.city')" outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.address" :label="$t('custom.address')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-select v-model="client.lang" :items="languages" item-text="text" item-value="value"
                      :label="$t('companies.locale')" :placeholder="$t('companies.locale_plc')" outlined></v-select>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.driver_series" :label="$t('custom.license_series')" outlined
                      hide-details :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.driver_number" :label="$t('custom.license_number')" outlined
                      hide-details :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.driver_issued" :label="$t('custom.license_issued')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                  <v-flex md12>
                    <v-text-field v-model="client.taxi_license" :label="$t('custom.taxi_license')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.sale" :label="$t('custom.sale_perc')" outlined
                      hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.sale_cash" :label="$t('custom.sale_rub', { currency: currency })"
                      outlined hide-details></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-select v-if="clients_sources" v-model="client.source" :items="clients_sources"
                      :label="$t('custom.source')" outlined></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-textarea v-model="client.dop_info" :label="$t('clients.add_info')" :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex class="text-center">
                    <v-btn color="success" class="mr-2" @click="editSave">{{ $t("custom.save")
                      }}<v-icon class="ml-1">
                        mdi-content-save-check-outline
                      </v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-else>
            <v-expansion-panel-header>
              <h2 v-if="!isMobile" class="mb-0 d-flex">
                {{ client.reg_form }} {{ client.entity_name }} {{ client.entity_phone }}
              </h2>
              <h4 v-else class="subtitle-1 mb-0 d-flex">
                {{ client.reg_form }} {{ client.entity_name }} {{ client.entity_phone }}
              </h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.entity_name" :label="$t('custom.company_name')"
                      :placeholder="$t('custom.company_name_placeholder')" outlined :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.short_entity_name" :label="$t('custom.company_name_short')"
                      :placeholder="$t('custom.company_name_short_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.reg_form" :label="$t('custom.reg_form')"
                      :placeholder="$t('custom.reg_form_plc')" outlined></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.inn" :label="$t('custom.tax_number')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.ogrn" :label="$t('custom.entity_number')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.ceo" :label="$t('custom.face')" :placeholder="$t('custom.face_plc')"
                      outlined :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.entity_phone" :label="$t('custom.entity_phone')" type="tel" outlined
                      :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.email" :label="$t('custom.email')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-select v-model="client.category" :items="customer_category"
                      :label="$t('bookings.client_category')" outlined></v-select>
                  </v-flex>
                  <v-flex xs12 sm12 md2>
                    <v-text-field v-model="client.country" :label="$t('custom.country')" outlined></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md2>
                    <v-text-field v-model="client.city" :label="$t('custom.city')" outlined></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-textarea v-model="client.entity_adress" :label="$t('custom.entity_address_2')" outlined rows="1"
                      auto-grow :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-textarea>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-textarea v-model="client.address" :label="$t('custom.entity_address_3')" outlined rows="1"
                      auto-grow :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.acc_number" :label="$t('custom.entity_bank_number')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.bank" :label="$t('custom.bank_name')"
                      :placeholder="$t('custom.bank_name_plc')" outlined :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.korr" :label="$t('custom.bank_correspondent')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field v-model="client.bik" :label="$t('custom.bank_number')" outlined :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.sale" :label="$t('custom.sale_perc')" outlined></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.sale_cash" :label="$t('custom.sale_rub', { currency: currency })"
                      outlined></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field v-model="client.doc_number" :label="$t('custom.doc_number')" outlined
                      :placeholder="$t('custom.doc_number_plc')" :style="user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                        "></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4 class="mx-auto">
                    <v-select v-if="clients_sources" v-model="client.source" :items="clients_sources"
                      :label="$t('custom.source')" outlined></v-select>
                  </v-flex>
                </v-layout>
                <v-divider class="mt-2 mb-2"></v-divider>
                <v-layout row wrap>
                  <v-flex>
                    <v-textarea v-model="client.dop_info" :label="$t('clients.add_info')" :style="user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                      "></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex class="text-center">
                    <v-btn color="success" @click="editSave">{{ $t("custom.save")
                      }}<v-icon class="ml-1">
                        mdi-content-save-check-outline
                      </v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-flex class="d-flex justify-space-between">
          <v-switch v-model="client.problems" :label="$t('clients.bad')" hide-details class="mt-0 mx-2"></v-switch>
          <v-icon v-if="company && company.whatsapp && client.phone && client.phone.length > 6" class="mr-2 green--text"
            @click="replaceToWhatsAppPage()">
            mdi-whatsapp
          </v-icon>
        </v-flex>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-container grid-list-md :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container v-if="!client.entity" :style="isMobile ? 'padding: 5px !important;' : ''">
            <v-layout row wrap>
              <v-flex md12 v-if="client.debtor">
                <h2 class="orange darken-4">{{ $t("clients.have_debt") }}</h2>
              </v-flex>
              <v-flex md12 v-if="client.problems">
                <h2 class="red lighten-1">{{ $t("clients.bad_warn") }}</h2>
              </v-flex>
              <v-flex md12>
                <v-textarea v-if="client.problems" v-model="client.problems_description" outlined></v-textarea>
              </v-flex>
              <v-flex md12 class="d-flex justify-space-between align-middle mb-3">
                <v-text-field v-model="client.balance" readonly :label="$t('other.balance')" hide-details
                  prepend-icon="mdi-wallet-outline" :style="user_role == 'guest' || user_role == 'partner'
                    ? 'display: none;'
                    : ''
                    "></v-text-field>
                <v-icon class="ml-2 mt-3 info--text" @click="showTopUpBalanceDialog = true" v-tooltip="{
                  content: $t('other.add_balance'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 800,
                    hide: 500,
                  },
                }">
                  mdi-wallet-plus
                </v-icon>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container v-else>
            <v-layout row wrap>
              <v-flex md12 v-if="client.debtor">
                <h2 class="orange darken-4">{{ $t("clients.have_debt") }}</h2>
              </v-flex>
              <v-flex md12 v-if="client.problems">
                <h2 class="red lighten-1 white--text">
                  {{ $t("clients.bad_warn") }}
                </h2>
              </v-flex>
              <v-flex md12 v-if="client.problems">
                <v-textarea v-if="client.problems" v-model="client.problems_description" outlined></v-textarea>
              </v-flex>
              <v-flex md12 class="d-flex justify-space-between align-middle mb-3">
                <v-text-field v-model="client.balance" readonly :label="$t('other.balance')" hide-details
                  prepend-icon="mdi-wallet-outline" :style="user_role == 'guest' || user_role == 'partner'
                    ? 'display: none;'
                    : ''
                    "></v-text-field>
                <v-icon class="ml-2 mt-3 info--text" @click="showTopUpBalanceDialog = true" v-tooltip="{
                  content: $t('other.add_balance'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 800,
                    hide: 500,
                  },
                }">
                  mdi-wallet-plus
                </v-icon>
              </v-flex>
            </v-layout>
          </v-container>
          <!-- Долги -->
          <v-card-text v-if="debts.length > 0">
            <h1>{{ $t('custom.debts') }}</h1>
            <v-data-table :headers="debts_headers" :items="debts" :loading="isLoading"
              :loading-text="$t('custom.loading_table')" :search="search" :sort-by="['created_at']" :sort-desc="[true]"
              dense mobile-breakpoint="100">
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-center">
                    <router-link :to="{ path: `/debts/${props.item.id}` }">{{
                      props.item.id
                    }}</router-link>
                  </td>
                  <td class="text-center">
                    {{ returnDate(props.item.created_at) }}
                  </td>
                  <td class="text-center" :style="user_role == 'guest' || user_role == 'partner'
                    ? 'display: none;'
                    : ''
                    ">
                    <router-link :to="{ path: `/clients/${props.item.client_id}` }">{{ props.item.client_name
                      }}</router-link>
                  </td>
                  <td class="text-center">
                    <router-link :to="{ path: `/bookings/${props.item.booking_id}` }">{{ props.item.booking_id
                      }}</router-link>
                  </td>
                  <td class="text-center">{{ props.item.group }}</td>
                  <td class="text-center">{{ props.item.description }}</td>
                  <td class="text-center">
                    {{ props.item.sum }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{ props.item.paid }}{{ currency }}
                  </td>
                  <!-- Состояние -->
                  <td class="text-center">
                    <v-btn :color="stateDebtColor(props.item.state)" small block>
                      {{ props.item.state }}
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
                {{ $t("tables.no_search_result", { msg: search }) }}
              </v-alert>
            </v-data-table>
          </v-card-text>
          <!-- Пополнения баланса -->
          <v-card-text v-if="counts.length > 0">
            <h2 class="mb-3">
              {{ $t("bookings.counts") }}
              <v-icon v-if="(user_role == 'superadmin' || user_role == 'admin')" @click="toXlsx()" class="mx-2"
                style="cursor: pointer" color="success">
                mdi-file-excel-outline
              </v-icon>
            </h2>
            <v-data-table :headers="counts_headers" :items="counts" :loading="isLoading"
              :loading-text="$t('custom.loading_table')" item-key="id" class="elevation-1" dense :items-per-page="10"
              mobile-breakpoint="100" :sort-by="['created_at']" :sort-desc="[true]" :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [7, 14, 50, 100, -1],
              }">
              <template slot="header" slot-scope="props">
                <tr>
                  <th v-for="header in props.headers" :key="header.text">
                    {{ header.text }}
                  </th>
                </tr>
              </template>
              <template slot="item" slot-scope="props">
                <tr :class="props.item.operation && props.item.sum < 0
                  ? 'red--text'
                  : ''
                  ">
                  <td class="text-center">{{ props.item.id }}</td>
                  <td class="text-center" nowrap>
                    {{ returnDate(props.item.created_at) }}
                  </td>
                  <td class="text-center">
                    {{ translateCategory(props.item.group) }}
                  </td>
                  <td class="text-center">{{ props.item.description }}</td>
                  <td class="text-center">
                    {{ props.item.sum }}{{ currency }}
                  </td>
                  <td class="text-center" v-if="props.item.operation">
                    <v-icon class="text-center" style="color: green">
                      mdi-plus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="!props.item.operation">
                    <v-icon class="text-center" style="color: tomato">
                      mdi-minus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash">
                    <v-icon class="text-center" color="success" v-tooltip="{
                      content: $t('tooltips.cash_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-cash
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cashless">
                    <v-icon class="text-center" color="warning" v-tooltip="{
                      content: $t('tooltips.terminal_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.entity">
                    <v-icon class="text-center" color="secondery" v-tooltip="{
                      content: $t('tooltips.entity_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-bank
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash_card">
                    <v-icon class="text-center" color="info" v-tooltip="{
                      content: $t('money.card_to_card'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-credit-card-check-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.client_balance">
                    <v-icon class="text-center" color="error" v-tooltip="{
                      content: $t('money.client_balance'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-account-arrow-right
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <!-- Привязанные карты -->
          <v-card-text v-if="company.tinkoff && company.tinkoff.tinkoff_enabled">
            <h2 class="mb-3">
              {{ $t("tinkoff.card_list") }}: {{ client.tinkoff_card_number }}
              <v-icon @click="addCardCreate()" class="mx-2" style="cursor: pointer" color="success">
                mdi-credit-card-plus-outline
              </v-icon>
              <v-icon @click="removeCardCreate()" v-if="client.tinkoff_card_number" class="mx-2" style="cursor: pointer"
                color="error">
                mdi-credit-card-remove-outline
              </v-icon>

            </h2>
            <v-data-table :headers="tinkoff_headers" :items="tinkoff_payments" :loading="isLoading"
              :loading-text="$t('custom.loading_table')" :items-per-page="10" :sort-by="['created_at']"
              :sort-desc="[true]" :class="!isMobile ? '' : 'is-mobile'" :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [10, 20, 50, -1],
              }">
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-center">
                    {{ props.item.id }}
                  </td>
                  <td class="text-center">
                    {{ formatDate(props.item.created_at) }}
                  </td>
                  <td class="text-center">
                    {{ props.item.sum / 100 }}
                  </td>
                  <td class="text-center">
                    {{ props.item.card_number }}
                  </td>
                  <td class="text-center">
                    {{ props.item.payment_id }}
                  </td>
                  <td class="text-center">
                    {{ props.item.status }}
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
            </v-data-table>
          </v-card-text>
          <!-- Брони -->
          <v-card-text v-if="bookings.length > 0">
            <h2 class="mb-3">{{ $t("custom.bookings") }}</h2>
            <v-data-table :headers="headers" :items="bookings" :loading="isLoading"
              :loading-text="$t('custom.loading_table')" item-key="id" class="elevation-1" dense mobile-breakpoint="100"
              :items-per-page="10" :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [10, 30, 50, 100, -1],
              }">
              <template slot="header" slot-scope="props">
                <tr>
                  <th v-for="header in props.headers" :key="header.text">
                    {{ header.text }}
                  </th>
                </tr>
              </template>
              <template slot="item" slot-scope="props">
                <tr :class="{
                  'grey lighten-1': !props.item.ride,
                  'green lighten-4': props.item.ride,
                }">
                  <td class="text-center" :key="props.item.id">
                    {{ props.item.id }}
                  </td>
                  <td class="text-center">
                    {{ formatCreatedAt(props.item.created_at) }}
                  </td>
                  <td class="text-center">
                    <v-icon @click="replaceToBooking(props.item)" :title="$t('archive.booking_info')">
                      mdi-eye-outline
                    </v-icon>
                  </td>
                  <td class="text-center">{{ props.item.car_name }}</td>
                  <td class="text-center">{{ props.item.start_date }}</td>
                  <td class="text-center">{{ props.item.end_date }}</td>
                  <td class="text-center">{{ props.item.days }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <!-- Штрафы -->
          <v-card-text v-if="fines.length > 0">
            <h2 class="mb-3">{{ $t("custom.fines") }}</h2>
            <v-data-table :headers="fines_headers" :items="fines" :search="search_fines" :loading="isLoading"
              :loading-text="$t('custom.loading_table')" :items-per-page="10" :sort-by="['created_at']"
              :sort-desc="[true]" :class="!isMobile ? '' : 'is-mobile'" :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }">
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-center">
                    <router-link :to="{ path: `/fines/${props.item.number}` }">{{ props.item.number }}</router-link>
                  </td>
                  <td class="text-center" v-if="props.item.date">
                    {{ returnDate(props.item.date) }}
                  </td>
                  <td class="text-center" v-else>
                    {{ formatCreatedAtWithYear(props.item.violation_date) }}
                  </td>
                  <td class="text-center">
                    <router-link :to="{ path: `/bookings/${props.item.booking_id}` }">{{ props.item.booking_id
                      }}</router-link>
                  </td>
                  <td class="text-center">
                    {{ props.item.sum }}{{ currency }}
                  </td>
                  <td class="text-center description-trunc">
                    <read-more v-if="props.item.description &&
                      props.item.description.length > 0
                    " :more-str="$t('custom.read')" :text="props.item.description" link="#"
                      :less-str="$t('custom.less')" :max-chars="20"></read-more>
                  </td>
                  <td class="text-center" v-if="props.item.paid">
                    <v-icon class="text-center" style="color: green">
                      mdi-plus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="!props.item.paid">
                    <v-icon class="text-center" style="color: tomato">
                      mdi-minus-circle-outline
                    </v-icon>
                  </td>
                  <!-- Состояние -->
                  <td class="text-center">
                    <v-btn :color="stateFineColor(props.item.state)" small block>
                      {{ props.item.state }}
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
                {{ $t("tables.no_search_result", { msg: search_fines }) }}
              </v-alert>
            </v-data-table>
          </v-card-text>
          <!-- Счета -->
          <v-card-text md12 v-if="invoices.length > 0">
            <v-divider class="mt-3 mb-3"></v-divider>
            <h2>{{ $t("custom.invoices") }}</h2>
            <v-data-table :headers="headers_invoices" :items="invoices" :search="search_invoices" :loading="isLoading"
              :loading-text="$t('custom.loading_table')" :items-per-page="10" :sort-by="['created_at']"
              mobile-breakpoint="100" :sort-desc="[true]" :class="!isMobile ? '' : 'is-mobile'" :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }">
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-center">
                    <router-link :to="{ path: `/invoices/${props.item.number}` }">{{ props.item.number }}</router-link>
                  </td>
                  <td class="text-center">{{ returnDate(props.item.date) }}</td>
                  <td class="text-center" :style="user_role == 'guest' || user_role == 'partner'
                    ? 'display: none;'
                    : ''
                    ">
                    <router-link :to="{ path: `/clients/${props.item.client_id}` }">{{ props.item.client_name
                      }}</router-link>
                  </td>
                  <td class="text-center">
                    <router-link :to="{ path: `/bookings/${props.item.booking_id}` }">{{ props.item.booking_id
                      }}</router-link>
                  </td>
                  <td class="text-center">
                    <read-more v-if="props.item.description &&
                      props.item.description.length > 0
                    " :more-str="$t('custom.read')" :text="props.item.description" link="#"
                      :less-str="$t('custom.less')" :max-chars="20"></read-more>
                  </td>
                  <td class="text-center">
                    {{
                      props.item.rent +
                      props.item.deposit +
                      props.item.delivery +
                      props.item.delivery_end +
                      props.item.clean +
                      props.item.gas +
                      props.item.damage +
                      props.item.fines +
                      props.item.mileage +
                      props.item.hours_cost +
                      props.item.insurance +
                      props.item.equipment +
                      props.item.other
                    }}{{ currency }}
                  </td>
                  <td class="text-center">{{ paidInvoice(props.item) }}</td>
                  <td class="text-center">
                    {{ props.item.paid ? props.item.paid : 0 }}{{ currency }}
                  </td>
                  <!-- Состояние -->
                  <td class="text-center">
                    <v-btn :color="stateInvoiceColor(props.item.state)" small block>
                      {{ props.item.state }}
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
                {{ $t("tables.no_search_result", { msg: search_invoices }) }}
              </v-alert>
            </v-data-table>
          </v-card-text>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 sm12 md12>
                <gallery :documents="client.clients_files || []"></gallery>
              </v-flex>
            </v-layout>
          </v-card-text>
          <!-- <v-card-text v-if="!uploadDocuments">
            <v-layout row wrap centered class="d-flex text-center justify-center">
              <v-flex xs12 sm12 md12 class="d-flex justify-center">
                <v-checkbox :label="$t('custom.upload_docs')" v-model="uploadDocuments"></v-checkbox>
              </v-flex>
            </v-layout>
          </v-card-text> -->
          <v-card-text class="text-center">
            <!-- <h3 mb-3>{{ $t("custom.uploading_files") }}</h3> -->
            <!-- <v-flex xs12 sm12 md12 class="d-flex text-center justify-center"> -->
              <uppy @updateClientFiles="updateClientFiles" :max-file-size-in-bytes="10000000" width="100%" height="250px"
                :theme="$vuetify.theme.dark ? 'dark' : 'light'" ></uppy>
            <!-- </v-flex> -->
          </v-card-text>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showTopUpBalanceDialog" :retain-focus="false" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          {{ $t('clients.top_up_balance') }}
        </v-card-title>
        <v-card-text>
          <v-flex md12>
            <v-text-field v-model.number="count.sum" :label="$t('custom.amount')" type="number" @click="count.sum = ''"
              outlined></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-textarea v-model="count.description" :label="$t('custom.description')" rows="1" auto-grow
              outlined></v-textarea>
          </v-flex>
          <v-flex md12>
            <v-flex v-if="!isMobile" xs12 sm12 md12 class="d-inline-flex align-center">
              <v-checkbox v-if="company && company.cash_payment" v-model="count.cash"
                :disabled="count.cashless || count.cash_card || count.entity" class="mt-0 mr-2" dense hide-details
                :style="count.cashless || count.cash_card || count.entity
                  ? 'opacity: 0.4;'
                  : ''
                  ">
                <template v-slot:label>
                  <v-icon class="text-center mr-2" color="success" size="30">
                    mdi-cash
                  </v-icon>
                  <span>{{ $t("money.cash") }}</span>
                </template>
              </v-checkbox>
              <v-checkbox v-if="company && company.cashless_payment" v-model="count.cashless"
                :label="$t('money.terminal')" :disabled="count.cash ||
                  count.cash_card ||
                  count.entity
                  " class="mt-0 mr-2" dense hide-details :style="count.cash ||
                    count.cash_card ||
                    count.entity
                    ? 'opacity: 0.4;'
                    : ''
                    " v-tooltip="{
                      content: $t('tooltips.terminal_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                <template v-slot:label>
                  <v-icon class="text-center mr-2" color="warning">
                    mdi-contactless-payment-circle-outline
                  </v-icon>
                  <span>{{ $t("money.terminal") }}</span>
                </template>
              </v-checkbox>
              <v-checkbox v-if="company && company.card_payment" v-model="count.cash_card" :disabled="count.cash ||
                count.cashless ||
                count.entity
                " class="mt-0 mr-2" dense hide-details :style="count.cash ||
                  count.cashless ||
                  count.entity
                  ? 'opacity: 0.4;'
                  : ''
                  " v-tooltip="{
                    content: $t('money.card_to_card_2'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }">
                <template v-slot:label>
                  <v-icon class="text-center mr-2" color="info">
                    mdi-credit-card-check-outline
                  </v-icon>
                  <span>{{ $t("money.to_card") }}</span>
                </template>
              </v-checkbox>
              <v-checkbox v-if="company && company.entity_payment" v-model="count.entity"
                :label="$t('money.to_entity_short')" class="mt-0 mr-0" dense hide-details :disabled="count.cashless ||
                  count.cash_card ||
                  count.cash
                  " :style="count.cashless ||
                    count.cash_card ||
                    count.cash
                    ? 'opacity: 0.4;'
                    : ''
                    " v-tooltip="{
                      content: $t('money.from_entity_2'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                <template v-slot:label>
                  <v-icon class="text-center mr-2" color="secondery">
                    mdi-bank
                  </v-icon>
                  <span>{{ $t("money.to_entity") }}</span>
                </template>
              </v-checkbox>
            </v-flex>
            <v-flex v-else xs12 sm12 md12 class="d-flex align-center justify-space-between">
              <v-flex v-if="company && company.cash_payment">
                <v-checkbox v-model="count.cash" color="success" :disabled="count.cashless || count.cash_card || count.entity
                  " class="mt-0 mr-0" dense hide-details :style="count.cashless || count.cash_card || count.entity
                    ? 'opacity: 0.4;'
                    : ''
                    ">
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="success" size="30" v-tooltip="{
                      content: $t('tooltips.cash_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-cash
                    </v-icon>
                  </template>
                </v-checkbox>
              </v-flex>
              <v-flex v-if="company && company.cashless_payment">
                <v-checkbox v-model="count.cashless" color="warning" :disabled="count.cash ||
                  count.cash_card ||
                  count.entity
                  " class="mt-0 mr-0" dense hide-details :style="count.cash ||
                    count.cash_card ||
                    count.entity
                    ? 'opacity: 0.4;'
                    : ''
                    ">
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="warning" v-tooltip="{
                      content: $t('tooltips.terminal_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                  </template>
                </v-checkbox>
              </v-flex>
              <v-flex v-if="company && company.card_payment">
                <v-checkbox v-model="count.cash_card" color="info" :disabled="count.cash ||
                  count.cashless ||
                  count.entity
                  " class="mt-0 mr-0" dense hide-details :style="count.cash ||
                    count.cashless ||
                    count.entity
                    ? 'opacity: 0.4;'
                    : ''
                    ">
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="info" v-tooltip="{
                      content: $t('money.card_to_card'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-credit-card-check-outline
                    </v-icon>
                  </template>
                </v-checkbox>
              </v-flex>
              <v-flex v-if="company && company.entity_payment">
                <v-checkbox v-model="count.entity" color="secondery" class="mt-0 mr-0" dense hide-details :disabled="count.cashless ||
                  count.cash_card ||
                  count.cash
                  " :style="count.cashless ||
                    count.cash_card ||
                    count.cash
                    ? 'opacity: 0.4;'
                    : ''
                    " v-tooltip="{
                      content: $t('tooltips.entity_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                  <template v-slot:label>
                    <v-icon class="text-center" color="secondery">
                      mdi-bank
                    </v-icon>
                  </template>
                </v-checkbox>
              </v-flex>
            </v-flex>
          </v-flex>

        </v-card-text>
        <v-card-actions class="text-center">
          <v-flex class="d-flex justify-center">
            <v-btn text @click="showTopUpBalanceDialog = false, payment = {}" class="mr-2">
              {{ $t("custom.close") }}
            </v-btn>
            <v-btn color="success" @click="saveCount()">
              {{ $t("custom.save") }}
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
/* eslint-disable */
import Gallery from "./Gallary";
import Uppy from "../../../plugins/uppy";
let uuid = require("uuid");
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { saveAs } from "file-saver";
import xlsx from "json-as-xlsx";
import PullToRefresh from "pulltorefreshjs";

export default {
  data() {
    return {
      client: {},
      count: {
        group: "Пополнение баланса клиента",
        operation: true,
        description: null,
        sum: 0,
        cash: false,
        cashless: false,
        cash_card: false,
        entity: false,
      },
      counts: [],
      documents: [],
      files: [],
      filepond_server: {
        // created
      },
      bookings: [],
      fines: [],
      debts: [],
      debt: {
        sum: null,
        state: this.$t("debts_state.created"),
        description: null,
        group: this.$t("add_services.rent"),
        client_id: null,
        booking_id: null,
      },
      counts_headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.group"), value: "group", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.operation"),
          value: "operation",
          align: "center",
        },
        { text: this.$t("custom.type"), value: "cashless", align: "center" },
      ],
      debts_headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
        },
        { text: this.$t("custom.group"), value: "group", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("bookings.paid"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      paymentData: {
        sum: null,
        type: null,
        state: "Оплачен",
        group: null,
        debt_id: null,
      },
      dataPanel: false,
      paymentDebtDialog: false,
      showTopUpBalanceDialog: false,
      search: null,
      gallery_items: [],
      editedFine: [],
      uploadDocuments: false,
      fines_state: [
        { text: this.$t("fines_state.created"), value: "Создан" },
        { text: this.$t("fines_state.notify"), value: "Оповещён" },
        { text: this.$t("fines_state.paid"), value: "Оплачен" },
        { text: this.$t("fines_state.refund"), value: "Возмещён" },
      ],
      headers: [
        { text: this.$t("custom.number"), value: "id", align: "center" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.editor"), align: "center" },
        { text: this.$t("cars.name"), value: "car_name", align: "center" },
        { text: this.$t("custom.start"), value: "start_date", align: "center" },
        { text: this.$t("custom.end"), value: "end_date", align: "center" },
        { text: this.$t("custom.days"), value: "days", align: "center" },
      ],
      fines_headers: [
        { text: this.$t("fines.number"), align: "center", value: "number" },
        { text: this.$t("custom.date"), value: "date", align: "center" },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("bookings.payment"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      invoices: [],
      search_invoices: null,
      headers_invoices: [
        { text: this.$t("custom.number"), align: "center", value: "number" },
        {
          text: this.$t("counts.date_initial"),
          value: "date",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
        },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("invoices.control"), sortable: false, align: "center" },
        { text: this.$t("invoices.get"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      // configPicker: {
      //   altFormat: "d-m-Y H:i",
      //   enableTime: true,
      //   altInput: true,
      //   time_24hr: true,
      //   plugins: [new ConfirmDatePlugin({ confirmText: "Ok" })]
      // },
      date: null,
      clients: [],
      isLoadingClients: false,
      showClientSearch: false,
      birthday_non_format: null,
      showEditBirthday: false,
      search_client: null,
      sortingBookings: { sortBy: "created_at", descending: true },
      showEdit: false,
      paymentData: {
        sum: null,
        type: null,
        state: null,
        number: null,
      },
      paymentDialog: false,
      editDialog: false,
      editDebtDialog: false,
      search_fines: null,
      customer_category: [
        { text: this.$t("customer_category.new"), value: "Новый" },
        { text: this.$t("customer_category.loyal"), value: "Лояльный" },
        { text: this.$t("customer_category.statement"), value: "Постоянный" },
        { text: this.$t("customer_category.problem"), value: "Серый" },
        { text: this.$t("customer_category.bad"), value: "Чёрный" },
      ],
      languages: [
        { text: this.$t("companies.default_lang"), value: null },
        { text: "English", value: "en" },
        { text: "Русский", value: "ru" },
        { text: "Português", value: "pt" },
        { text: "Српски", value: "sr" },
        { text: "Thai", value: "th" },
        { text: "Hindi", value: "hi" },
        { text: "Turkish", value: "tr" },
        { text: "French", value: "fr" },
        { text: "German", value: "de" },
        { text: "Spanish", value: "es" },
        { text: "Italian", value: "it" },
        { text: "Indonesian", value: "id" },
        { text: "Polski", value: "pl" },
        { text: "Română", value: "ro" },
      ],
      birthday_menu: false,
      birthday: null,
      tinkoff_headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("tinkoff.card_number"), value: "card_number", align: "center" },
        { text: this.$t("tinkoff.payment_id"), value: "payment_id", align: "center" },
        { text: this.$t("custom.status"), value: "status", align: "center" },
      ],
      tinkoff_payments: [],
      isMobile: false,
      isLoading: false,
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/client_card/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.client = response.data.client;
          this.birthday_non_format = moment(
            this.client.birthday,
            "YYYY-MM-DD"
          ).format("DD.MM.YYYY");

          this.birthday = moment(this.client.birthday, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          );
          this.counts = response.data.counts;
          this.bookings = response.data.bookings;
          this.debts = response.data.debts;
          this.fines = response.data.fines;
          this.invoices = response.data.invoices;
          this.tinkoff_payments = response.data.tinkoff_payments;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.client_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    birthday() {
      // this.client.birthday = moment(this.birthday, "YYYY-MM-DD").format(
      //   "DD-MM-YYYY"
      // );
      // console.log(this.birthday);
    },
    search_client(val) {
      // Items have already been loaded
      if (this.clients.length > 0) return;

      // Items have already been requested
      if (this.isLoadingClients) return;

      this.isLoadingClients = true;

      // Lazily load input items
      this.axios
        .get("/api/v1/clients", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.clients.push(element.attributes);
          });
          this.clients.forEach((client) => {
            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.short_entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                client.ogrn;
            }
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingClients = false));
    },
  },
  computed: {
    clients_sources() {
      if (this.$store.getters.getCompany.company.clients_sources) {
        return this.$store.getters.getCompany.company.clients_sources;
      } else {
        return [];
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    company() {
      if (this.$store.getters.getCompany) {
        return this.$store.getters.getCompany.company;
      } else {
        return null;
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
  },
  methods: {
    editSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let client = this.client;
        let self = this;
        let name = this.capitalizeFirst(client.name);
        let middlename = this.capitalizeFirst(client.middlename);
        let lastname = this.capitalizeFirst(client.lastname);
        this.axios
          .patch(
            `/api/v1/clients/${client.id}`,
            {
              clients: {
                name: name,
                lastname: lastname,
                middlename: middlename,
                fio: client.fio,
                phone: client.phone,
                email: client.email,
                country: client.country,
                city: client.city,
                address: client.address,
                sale: client.sale,
                sale_cash: client.sale_cash,
                passport_series: client.passport_series,
                passport_number: client.passport_number,
                passport_issued: client.passport_issued,
                driver_series: client.driver_series,
                driver_number: client.driver_number,
                driver_issued: client.driver_issued,
                birthday:
                  this.birthday_non_format &&
                    moment(this.birthday_non_format, "DD.MM.YYYY").isValid
                    ? moment(this.birthday_non_format, "DD.MM.YYYY").format(
                      "YYYY-MM-DD"
                    )
                    : this.birthday_non_format,
                category: client.category,
                dop_info: client.dop_info,
                debt: client.debt,
                debt_description: client.debt_description,
                problems: client.problems,
                problems_description: client.problems_description,
                entity_name: client.entity_name,
                short_entity_name: client.short_entity_name,
                ceo: client.ceo,
                reg_form: client.reg_form,
                entity_phone: client.entity_phone,
                entity_adress: client.entity_adress,
                inn: client.inn,
                ogrn: client.ogrn,
                acc_number: client.acc_number,
                bank: client.bank,
                korr: client.korr,
                bik: client.bik,
                doc_number: client.doc_number,
                source: client.source,
                taxi_license: client.taxi_license,
                lang: client.lang,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.showEdit = false;
            client.birthday = client.birthday;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("clients.edited_title"),
              text: this.$t("clients.edited_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.client_edit"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.client_edit"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveCount() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let count = this.count;
        let self = this;
        if (
          (count.sum > 0 || count.sum < 0) &&
          (count.cash > 0 ||
            count.cashless > 0 ||
            count.cash_card > 0 ||
            count.entity > 0 ||
            count.cash < 0 ||
            count.cashless < 0 ||
            count.cash_card < 0 ||
            count.entity < 0)
        ) {
          this.isLoading = true;
          this.axios
            .post(
              "/api/v1/counts",
              {
                counts: {
                  group: count.group,
                  operation: true,
                  description: count.description,
                  sum: count.sum,
                  cash: count.cash,
                  cashless: count.cashless,
                  cash_card: count.cash_card,
                  entity: count.entity,
                  client_id: this.client.id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.client.balance = parseFloat(
                parseFloat(this.client.balance) + parseFloat(count.sum)
              ).toFixed(1);
              this.showTopUpBalanceDialog = false;
              this.count = {
                group: "Пополнение баланса клиента",
                operation: true,
                description: null,
                sum: 0,
                cash: false,
                cashless: false,
                cash_card: false,
                entity: false,
              };
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("counts.add_payment"),
                text: this.$t("counts.add_payment_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.count_add"));
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    addCardCreate() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        // Создаём транзакцию у нас
        this.axios
          .post(
            "/api/v1/integrations/tinkoff/add_card",
            {
              client_id: this.client.id,
              current_url: window.location.href,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            if (response.data.Success == true && response.data.PaymentURL) {
              console.log(response.data);
              // redirect new window
              window.open(response.data.PaymentURL, "_blank");
            } else {
              this.$swal({
                icon: "error",
                title: response.data.Message,
                text: response.data.Details,
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.error"),
              text: error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    removeCardCreate() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("tinkoff.remove_card_title"),
          text: this.$t("tinkoff.remove_card_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            // Создаём транзакцию у нас
            this.axios
              .post(
                "/api/v1/integrations/tinkoff/remove_card",
                {
                  client_id: this.client.id,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then(() => {
                this.client.tinkoff_card_number = "-";
              })
              .catch((error) => {
                this.isLoading = false;
                this.setError(error, this.$t("errors.error"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "error",
                  title: this.$t("errors.error"),
                  text: error,
                });
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateClientFiles(event) {
      this.client.clients_files.unshift(event);
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("DD MMM H:mm");
      } else {
        return "-";
      }
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
        case "Пополнение баланса клиента":
          group_text = this.$t("groups_counts.top_up_client_balance");
          break;
        case "Выплаты агентам":
          group_text = this.$t("groups_counts.agent_payments");
          break;
      }
      return group_text;
    },
    stateFineColor(state) {
      if (state == "Оповещён") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "error";
      }
      if (state == "Возмещён") {
        return "success";
      }
      if (state == "Не возмещён") {
        return "error";
      }
    },
    stateDebtColor(state) {
      if (state == "Оповещён") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "success";
      }
      if (state == "Не оплачен") {
        return "red";
      }
    },
    stateInvoiceColor(state) {
      if (state == "Выставлен") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "success";
      }
      if (state == "Частично оплачен") {
        return "pink";
      }
    },
    paidInvoice(invoice) {
      let invoice_sum =
        invoice.rent +
        invoice.deposit +
        invoice.delivery +
        invoice.clean +
        invoice.gas +
        invoice.damage +
        invoice.fines +
        invoice.mileage +
        invoice.hours_cost +
        invoice.insurance +
        invoice.equipment +
        invoice.other;
      if (invoice_sum == invoice.paid && invoice_sum > 0 && invoice.paid > 0) {
        return this.$t("invoices.paid");
      } else if (invoice_sum < invoice.paid) {
        return this.$t("invoices.extra_paid");
      } else if (invoice_sum > 0 && invoice_sum > invoice.paid) {
        return this.$t("invoices.low_paid");
      } else if (invoice_sum > 0 && invoice.paid == 0) {
        return this.$t("invoices.no_income");
      } else {
        return `-`;
      }
    },
    toXlsx() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        let data = [
          {
            sheets: "Payments",
            columns: [
              { label: "ID", value: "id" },
              { label: this.$t("clients.created_at"), value: "created_at" },
              { label: this.$t("custom.amount"), value: "sum" },
              { label: this.$t("custom.operation"), value: "operation" },
              { label: this.$t("counts.about"), value: "description" },
              { label: this.$t("money.terminal"), value: "cashless" },
              { label: this.$t("money.card_to_card"), value: "cash_card" },
              { label: this.$t("money.cash"), value: "cash" },
              { label: this.$t("money.from_entity"), value: "entity" },
              {
                label: this.$t("custom.group"), value: (row) => {
                  return this.translateCategory(row.group);
                }
              },
              { label: this.$t("custom.car"), value: "car_code" },
              { label: this.$t("bookings.booking"), value: "booking_id" },
              { label: this.$t("debts.debt"), value: "debt_id" },
              { label: this.$t("employers.partner"), value: "investor_id" },
              { label: this.$t("counts.completed"), value: "completed" },
            ],
            content: this.filtered_counts && this.filtered_counts.length > 0 ? this.filtered_counts : this.counts,
          },
        ];
        let settings = {
          fileName: "client_payments", // Name of the resulting spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: "write", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {
            bookType: "xlsx",
            type: "array",
          }, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        };
        let wbout = xlsx(data, settings);
        var blob_data = new Blob([new Uint8Array(wbout)], {type:"application/octet-stream"});
        if (!this.isNative) {
          saveAs(blob_data, 'client_payments.xlsx');
        } else {
          if (Filesystem.checkPermissions()) {
            const getBase64FromBlob = async (blob) => {
              return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                  const base64data = reader.result;
                  resolve(base64data);
                }
              });
            }
            getBase64FromBlob(blob_data).then(
              (base64) => {
                // save file
                async function writeFile() {
                  return await Filesystem.writeFile({
                    path: 'client_payments.xlsx',
                    data: base64,
                    directory: Directory.Documents,
                  });
                };
                let file_uri = null
                let writedFile = writeFile().then((getUriResult) => {
                  // share file opened file
                  let shareFile = async () => {
                    await Share.share({
                      title: 'Download document',
                      files: [getUriResult.uri],
                    }).catch((error) => {
                      console.log("shareFile", error)
                    });
                  };
                  let sharedFile = shareFile();
                }).catch((error) => {
                  console.log("shareFile", error)
                });
              }
            );
          } else {
            Filesystem.requestPermissions();
          }
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    replaceToWhatsAppPage() {
      if (this.client && this.client.id) {
        this.$router.push({ path: `/whatsapp_client/${this.client.id}` });
      }
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    capitalizeFirst(text) {
      if (text) {
        return text[0].toUpperCase() + text.substring(1);
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm");
    },
    formatCreatedAtWithYear(date) {
      if (date) {
        return moment.parseZone(date).format("DD-MM-YY H:mm");
      } else {
        return "-";
      }
    },
    returnDate(item) {
      return moment.parseZone(item, "YYYY-MM-DD H:mm").format("DD MMM H:mm");
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
        this.paymentsPanel = [];
      } else {
        this.paymentsPanel = 0;
        this.isMobile = false;
      }
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    Gallery,
    Uppy,
  },
};
</script>
