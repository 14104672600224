<template>
  <v-flex md12 v-if="debts.length > 0">
    <v-divider class="mt-3 mb-3"></v-divider>
    <h2>{{ $t("custom.debts") }}</h2>
    <v-data-table
      :headers="debts_headers"
      :loading="isLoading"
      :loading-text="$t('custom.loading_table')"
      :items="debts"
      :sort-by="['created_at']"
      :sort-desc="[true]"
      mobile-breakpoint="100"
      dense
      hide-default-footer
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td class="text-center">
            <router-link :to="{ path: `/debts/${bookingId}` }">{{
              props.item.id
            }}</router-link>
          </td>
          <td class="text-center">
            {{ returnDate(props.item.created_at) }}
          </td>
          <td class="text-center">
            <router-link :to="{ path: `/clients/${props.item.client_id}` }">{{ props.item.client_name }}</router-link>
          </td>
          <td class="text-center">
            <router-link :to="{ path: `/bookings/${props.item.booking_id}` }">{{ props.item.booking_id }}</router-link>
          </td>
          <td class="text-center">{{ props.item.group }}</td>
          <td class="text-center">{{ props.item.description }}</td>
          <td class="text-center">{{ props.item.sum }}</td>
          <td class="text-center">{{ props.item.paid }}</td>
          <td class="text-center">
            <v-btn :color="stateDebtColor(props.item.state)" class="my-2" small block>
              {{ props.item.state }}
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="no-data">
        {{ $t("custom.no_data_in_table") }}
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
export default {
  name: 'BookingDebts',
  props: {
    debts: {
      type: Array,
      required: true
    },
    bookingId: {
      type: [String, Number],
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    debts_headers: {
      type: Array,
      required: true
    }
  },
  methods: {
    returnDate(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY')
      } else {
        return date
      }
    },
    stateDebtColor(state) {
      // Implement color logic based on state
      switch(state) {
        case 'paid':
          return 'success'
        case 'partial':
          return 'warning'
        default:
          return 'error'
      }
    }
  }
}
</script>
