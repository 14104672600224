<template>
  <v-container grid-list-md text-xs-center v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card v-if="!short_client_view || show_full_fields">
      <v-card-title>
        <v-row class="align-center text-center px-3">
          <h2 v-if="!isMobile">{{ $t("custom.add_client_2") }}</h2>
          <h3 v-else>{{ $t("custom.add_client_2") }}</h3>
          <v-spacer></v-spacer>
          <v-switch
            v-model="client.entity"
            :label="$t('custom.company_client')"
          ></v-switch>
          <v-switch
            v-model="show_full_fields"
            :label="$t('clients.show_full_fields')"
            class="ml-3"
          ></v-switch>
        </v-row>
      </v-card-title>

      <v-card-text v-if="!client.entity">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.lastname"
                :label="$t('bookings.lastname')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.name"
                :label="$t('bookings.name')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.middlename"
                :label="$t('bookings.middlename')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.phone"
                @blur="checkUniqClientPhone"
                :label="$t('bookings.main_phone')"
                v-mask="'#############'"
                type="tel"
                outlined
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.email"
                @blur="checkUniqClientEmail"
                :label="$t('custom.email')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model="birthday_non_format"
                @blur="checkAge()"
                v-mask="'##.##.####'"
                :label="$t('bookings.birthdate')"
                :placeholder="$t('bookings.birthdate_plc')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-select
                v-if="clients_sources"
                v-model="client.source"
                :items="clients_sources"
                :label="$t('custom.source')"
                outlined
              ></v-select>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-select
                v-model="client.category"
                :items="customer_category"
                item-text="text"
                item-value="value"
                :label="$t('bookings.client_category')"
                :value="$t('customer_category.new')"
                outlined
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm12 md2>
              <v-text-field
                v-model.trim="client.passport_series"
                :label="$t('custom.passport_series')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.passport_number"
                :label="$t('custom.passport_number')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.passport_issued"
                :label="$t('custom.passport_issued')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.inn"
                :label="$t('custom.tax_number')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-autocomplete
                v-model="client.country"
                flat
                hide-no-data
                hide-details
                :label="$t('custom.country')"
                :items="countries"
                item-text="both"
                item-value="name"
                outlined
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.city"
                :label="$t('custom.city')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.address"
                :label="$t('custom.address')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-select
                v-model="client.lang"
                :items="languages"
                item-text="text"
                item-value="value"
                :label="$t('companies.locale')"
                :placeholder="$t('companies.locale_plc')"
                outlined
              ></v-select>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.driver_series"
                :label="$t('custom.license_series')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.driver_number"
                :label="$t('custom.license_number')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.driver_issued"
                :label="$t('custom.license_issued')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-text-field
                v-model="client.taxi_license"
                :label="$t('custom.taxi_license')"
                outlined
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm12 md6>
              <v-text-field
                v-model.trim="client.sale"
                :label="$t('custom.sale_perc')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-text-field
                v-model.trim="client.sale_cash"
                :label="$t('custom.sale_rub', { currency: currency })"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-switch
                v-model="showDopInfo"
                :label="$t('other.add_client_info')"
              ></v-switch>
            </v-flex>
          </v-layout>
          <v-layout row wrap v-if="showDopInfo">
            <v-flex>
              <v-textarea
                v-model="client.dop_info"
                clearable
                :label="$t('other.add_client_info_2')"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-row justify="center">
              <v-btn color="success" @click="submit()">{{
                $t("custom.save")
              }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card-text>
      <v-card-text v-else>
        <v-container grid-list-md>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model.trim="client.entity_name"
                  :label="$t('custom.company_name')"
                  :placeholder="$t('custom.company_name_placeholder')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model.trim="client.short_entity_name"
                  :label="$t('custom.company_name_short')"
                  :placeholder="$t('custom.company_name_short_plc')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model.trim="client.reg_form"
                  :label="$t('custom.reg_form')"
                  :placeholder="$t('custom.reg_form_plc')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model.trim="client.inn"
                  :label="$t('custom.tax_number')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model.trim="client.ogrn"
                  :label="$t('custom.entity_number')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model.trim="client.ceo"
                  :label="$t('custom.face')"
                  :placeholder="$t('custom.face_plc')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model.trim="client.entity_phone"
                  :label="$t('custom.entity_phone')"
                  type="tel"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.email"
                  @blur="checkUniqClientEmail"
                  :label="$t('custom.email')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-select
                  v-model="client.country"
                  :items="countries"
                  :label="$t('custom.country')"
                  item-text="both"
                  item-value="name"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model.trim="client.city"
                  :label="$t('custom.city')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md6>
                <v-textarea
                  v-model.trim="client.entity_adress"
                  :label="$t('custom.entity_address_2')"
                  outlined
                  rows="1"
                  auto-grow
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm12 md6>
                <v-textarea
                  v-model.trim="client.address"
                  :label="$t('custom.entity_address_3')"
                  outlined
                  rows="1"
                  auto-grow
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model.trim="client.acc_number"
                  :label="$t('custom.entity_bank_number')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model.trim="client.bank"
                  :label="$t('custom.bank_name')"
                  :placeholder="$t('custom.bank_name_plc')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model.trim="client.korr"
                  :label="$t('custom.bank_correspondent')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md2>
                <v-text-field
                  v-model.trim="client.bik"
                  :label="$t('custom.bank_number')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md2>
                <v-text-field
                  v-model.trim="client.doc_number"
                  :label="$t('custom.doc_number')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md5>
                <v-select
                  v-model="client.source"
                  :items="clients_sources"
                  :label="$t('custom.source')"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12 md5>
                <v-select
                  v-model="client.lang"
                  :items="languages"
                  item-text="text"
                  item-value="value"
                  :label="$t('companies.locale')"
                  :placeholder="$t('companies.locale_plc')"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-row justify="center">
              <v-btn color="success" @click="submit()">{{
                $t("custom.save")
              }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <v-row class="align-center text-center px-3">
          <h2 v-if="!isMobile">{{ $t("custom.add_client_2") }}</h2>
          <h3 v-else>{{ $t("custom.add_client_2") }}</h3>
          <v-spacer></v-spacer>
          <v-switch
            v-model="client.entity"
            :label="$t('custom.company_client')"
          ></v-switch>
          <v-switch
            v-model="show_full_fields"
            :label="$t('clients.show_full_fields')"
            class="ml-3"
          ></v-switch>
        </v-row>
      </v-card-title>

      <v-card-text v-if="!client.entity">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model="client.lastname"
                :label="$t('bookings.lastname')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model="client.name"
                :label="$t('bookings.name')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model="client.middlename"
                :label="$t('bookings.middlename')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-select
                v-if="clients_sources"
                v-model="client.source"
                :items="clients_sources"
                :label="$t('custom.source')"
                outlined
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model="client.phone"
                @blur="checkUniqClientPhone"
                :label="$t('bookings.main_phone')"
                v-mask="'#############'"
                type="tel"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model="client.email"
                @blur="checkUniqClientEmail"
                :label="$t('custom.email')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model="birthday_non_format"
                @blur="checkAge()"
                v-mask="'##.##.####'"
                :label="$t('bookings.birthdate')"
                :placeholder="$t('bookings.birthdate_plc')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-select
                v-model="client.category"
                :items="customer_category"
                item-text="text"
                item-value="value"
                :label="$t('bookings.client_category')"
                :value="$t('customer_category.new')"
                outlined
              ></v-select>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-row justify="center">
              <v-btn color="success" @click="submit()">{{
                $t("custom.save")
              }}</v-btn>
            </v-row>
          </v-card-actions>
          <v-expansion-panels class="mb-3 mt-5">
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t("clients.extra_fields") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md2>
                    <v-text-field
                      v-model="client.passport_series"
                      :label="$t('custom.passport_series')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field
                      v-model="client.passport_number"
                      :label="$t('custom.passport_number')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field
                      v-model="client.passport_issued"
                      :label="$t('custom.passport_issued')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field
                      v-model="client.inn"
                      :label="$t('custom.tax_number')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-autocomplete
                      v-model="client.country"
                      flat
                      hide-no-data
                      hide-details
                      :label="$t('custom.country')"
                      :items="countries"
                      item-text="both"
                      item-value="name"
                      outlined
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field
                      v-model="client.city"
                      :label="$t('custom.city')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field
                      v-model="client.address"
                      :label="$t('custom.address')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-select
                      v-model="client.lang"
                      :items="languages"
                      item-text="text"
                      item-value="value"
                      :label="$t('companies.locale')"
                      :placeholder="$t('companies.locale_plc')"
                      outlined
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field
                      v-model="client.driver_series"
                      :label="$t('custom.license_series')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field
                      v-model="client.driver_number"
                      :label="$t('custom.license_number')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field
                      v-model="client.driver_issued"
                      :label="$t('custom.license_issued')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex md12>
                    <v-text-field
                      v-model="client.taxi_license"
                      :label="$t('custom.taxi_license')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-textarea
                      v-model="client.dop_info"
                      clearable
                      :label="$t('other.add_client_info_2')"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t("clients.sale_fields") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md6>
                    <v-text-field
                      v-model="client.sale"
                      :label="$t('custom.sale_perc')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md6>
                    <v-text-field
                      v-model="client.sale_cash"
                      :label="$t('custom.sale_rub', { currency: currency })"
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-actions class="mt-5">
            <v-row justify="center">
              <v-btn color="success" @click="submit()">{{
                $t("custom.save")
              }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card-text>
      <v-card-text v-else>
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.entity_name"
                :label="$t('custom.company_name')"
                :placeholder="$t('custom.company_name_placeholder')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.short_entity_name"
                :label="$t('custom.company_name_short')"
                :placeholder="$t('custom.company_name_short_plc')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.reg_form"
                :label="$t('custom.reg_form')"
                :placeholder="$t('custom.reg_form_plc')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.inn"
                :label="$t('custom.tax_number')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.ogrn"
                :label="$t('custom.entity_number')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.ceo"
                :label="$t('custom.face')"
                :placeholder="$t('custom.face_plc')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.entity_phone"
                :label="$t('custom.entity_phone')"
                type="tel"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model="client.email"
                @blur="checkUniqClientEmail"
                :label="$t('custom.email')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-select
                v-model="client.country"
                :items="countries"
                :label="$t('custom.country')"
                item-text="both"
                item-value="name"
                outlined
              ></v-select>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.city"
                :label="$t('custom.city')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-textarea
                v-model.trim="client.entity_adress"
                :label="$t('custom.entity_address_2')"
                outlined
                rows="1"
                auto-grow
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-textarea
                v-model.trim="client.address"
                :label="$t('custom.entity_address_3')"
                outlined
                rows="1"
                auto-grow
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm12 md4>
              <v-text-field
                v-model.trim="client.acc_number"
                :label="$t('custom.entity_bank_number')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.bank"
                :label="$t('custom.bank_name')"
                :placeholder="$t('custom.bank_name_plc')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md3>
              <v-text-field
                v-model.trim="client.korr"
                :label="$t('custom.bank_correspondent')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md2>
              <v-text-field
                v-model.trim="client.bik"
                :label="$t('custom.bank_number')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md2>
              <v-text-field
                v-model.trim="client.doc_number"
                :label="$t('custom.doc_number')"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md5>
              <v-select
                v-model="client.source"
                :items="clients_sources"
                :label="$t('custom.source')"
                outlined
              ></v-select>
            </v-flex>
            <v-flex xs12 sm12 md5>
              <v-select
                v-model="client.lang"
                :items="languages"
                item-text="text"
                item-value="value"
                :label="$t('companies.locale')"
                :placeholder="$t('companies.locale_plc')"
                outlined
              ></v-select>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-row justify="center">
              <v-btn color="success" @click="submit()">{{
                $t("custom.save")
              }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import { isMobile } from "mobile-device-detect";
import { countries } from "./../countries";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
export default {
  data() {
    return {
      client: {
        name: "",
        lastname: "",
        middlename: "",
        phone: "",
        country: null,
        city: "",
        address: "",
        sale: 0,
        sale_cash: 0,
        passport: "",
        driver: "",
        birthday: null,
        category: "Новый",
        email: null,
      },
      clients: [],
      customer_category: [
        { text: this.$t("customer_category.new"), value: "Новый" },
        { text: this.$t("customer_category.loyal"), value: "Лояльный" },
        { text: this.$t("customer_category.statement"), value: "Постоянный" },
        { text: this.$t("customer_category.problem"), value: "Серый" },
        { text: this.$t("customer_category.bad"), value: "Чёрный" },
      ],
      languages: [
        { text: this.$t("companies.default_lang"), value: null },
        { text: "English", value: "en" },
        { text: "Русский", value: "ru" },
        { text: "Português", value: "pt" },
        { text: "Српски", value: "sr" },
        { text: "Thai", value: "th" },
        { text: "Hindi", value: "hi" },
        { text: "Turkish", value: "tr" },
        { text: "French", value: "fr" },
        { text: "German", value: "de" },
        { text: "Spanish", value: "es" },
        { text: "Italian", value: "it" },
        { text: "Indonesian", value: "id" },
        { text: "Polski", value: "pl" },
        { text: "Română", value: "ro" },
      ],
      age_limit: 26,
      showDopInfo: false,
      countries: this.$i18n.locale == "ru" ? countries : countries.sort((a, b) => {
        if (a.native < b.native) {
          return -1;
        }
        if (a.native > b.native) {
          return 1;
        }
        return 0;
      }),
      coloredDate: false,
      birthday_non_format: null,
      show_full_fields: false,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.axios
        .get(`/api/v1/company`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          if (response.data.age_limit) {
            this.age_limit = response.data.age_limit;
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.company_load"));
          console.log(error);
        });
      this.axios
        .get("/api/v1/clients", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.clients.push(element.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    birthday_non_format: function (val) {
      if (val && val.length == 10) {
        this.client.birthday = moment(val, "DD.MM.YYYY").format("YYYY-MM-DD");
      }
    },
  },
  computed: {
    clients_sources() {
      if(this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.clients_sources) {
        return this.$store.getters.getCompany.company.clients_sources;
      } else {
        return [];
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    short_client_view() {
      return this.$store.getters.getUserSettings.short_client_view;
    },
  },
  methods: {
    checkAge() {
      if (this.client.birthday) {
        console.log(this.client.birthday, "this.client.birthday")
        let startDate = moment(this.client.birthday, ["DD.MM.YYYY", "YYYY-MM-DD"]);
        let endDate = moment();
        let duration = moment.duration(endDate.diff(startDate));
        let years = duration.asYears();

        if (years < this.age_limit) {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("bookings.low_age_title"),
            text: this.$t("bookings.low_age_text", { years: parseInt(years) }),
          });
        }
      }
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t("errors.add_client_title"),
          text: this.$t("errors.bad_email_2"),
        });
      }
    },
    checkUniqClientPhone() {
      if (
        this.clients.filter((client) => client.phone == this.client.phone)
          .length > 0
      ) {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t("bookings.phone_uniq_title"),
          text: this.$t("bookings.phone_uniq_text"),
        });
      }
    },
    checkUniqClientEmail() {
      if (this.client.email) {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            this.client.email
          )
        ) {
          if (
            this.clients.filter((client) => client.email == this.client.email)
              .length > 0
          ) {
            this.$swal({
              showConfirmButton: true,
              icon: "warning",
              title: this.$t("bookings.email_uniq_title"),
              text: this.$t("bookings.email_uniq_text"),
            });
          }
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("errors.bad_email_2"),
            text: this.$t("errors.bad_email_desc"),
          });
        }
      }
    },
    submit() {
       if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        let client = this.client;
        let middlename, year, birthday;

        if (client.middlename) {
          middlename = this.capitalizeFirst(client.middlename);
        } else {
          middlename = "";
        }

        if (client.birthday) {
          console.log(client.birthday);
          birthday = client.birthday;

          year = birthday.split("-")[0];
        }
        if ((client.name && client.lastname) || client.entity) {
          this.newClientDialog = false;
          this.showClients = false;

          let name = client.name ? this.capitalizeFirst(client.name) : "";
          let lastname = client.lastname
            ? this.capitalizeFirst(client.lastname)
            : "";

          this.axios
            .post(
              "/api/v1/clients.json",
              {
                clients: {
                  name: name,
                  lastname: lastname,
                  middlename: middlename,
                  fio:
                    middlename && !client.entity
                      ? name[0] + ". " + middlename[0] + ". " + lastname
                      : name[0] + ". " + lastname,
                  email: client.email,
                  phone: client.phone,
                  country: client.country,
                  city: client.city,
                  address: client.address,
                  sale: client.sale,
                  sale_cash: client.sale_cash,
                  passport_series: client.passport_series,
                  passport_number: client.passport_number,
                  passport_issued: client.passport_issued,
                  driver_series: client.driver_series,
                  driver_number: client.driver_number,
                  driver_issued: client.driver_issued,
                  birthday: client.birthday ? client.birthday : null,
                  year: year,
                  category: client.category,
                  dop_info: client.dop_info,
                  entity: client.entity,
                  entity_name: client.entity_name,
                  short_entity_name: client.short_entity_name,
                  ceo: client.ceo,
                  reg_form: client.reg_form,
                  entity_phone: client.entity_phone,
                  entity_adress: client.entity_adress,
                  inn: client.inn,
                  ogrn: client.ogrn,
                  acc_number: client.acc_number,
                  bank: client.bank,
                  korr: client.korr,
                  bik: client.bik,
                  doc_number: client.doc_number,
                  source: client.source,
                  taxi_license: client.taxi_license,
                  lang: client.lang,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.$router.replace(`/clients/${response.data.id}`);

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("custom.add_client_title"),
                text: this.$t("custom.add_client_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.add_client_title"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.add_client_title"),
                text: error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: this.$t("errors.all_fields_title"),
            text: this.error,
          });
          this.isLoading = false;
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    capitalizeFirst(text) {
      return text[0].toUpperCase() + text.substring(1).toLowerCase();
    },
    FormattedMomentjs() {
      // console.log(this.birthday_non_format, moment(this.birthday_non_format, "YYYY-MM-DD").format('DD-MM-YYYY'))
      return (this.client.birthday = moment(
        this.birthday_non_format,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY"));
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
