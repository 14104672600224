<template>
  <v-flex md12 v-if="fines && fines.length > 0">
    <v-divider class="mt-3 mb-3"></v-divider>
    <h2>{{ $t("custom.fines") }}</h2>
    <v-data-table
      :headers="fines_headers"
      :loading="isLoading"
      :loading-text="$t('custom.loading_table')"
      :items="fines"
      :sort-by="['created_at']"
      :sort-desc="[true]"
      mobile-breakpoint="100"
      dense
      hide-default-footer
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td class="text-center">
            <router-link :to="{ path: `/fines/${props.item.number}` }">{{
              props.item.number
              }}</router-link>
          </td>
          <td class="text-center">
            {{ returnDate(props.item.date) }}
          </td>
          <td class="text-center">{{ props.item.sum }}</td>
          <td class="text-center description-trunc">
            <read-more v-if="props.item.description &&
              props.item.description.length > 0
            " :more-str="$t('custom.read')" :text="props.item.description" link="#" :less-str="$t('custom.less')"
              :max-chars="20"></read-more>
          </td>
          <!-- Состояние -->
          <td class="text-center">
            <v-btn :color="stateFineColor(props.item.state)" class="my-2" small block>
              {{ translateFinesState(props.item.state) }}
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="no-data">
        {{ $t("custom.no_data_in_table") }}
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'

export default {
  name: 'BookingFines',
  props: {
    entity: {
      type: Boolean,
      required: false,
    },
    fines: {
      type: Array,
      required: true,
      default: () => []
    },
    fines_headers: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    returnDate(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY')
      } else {
        return date
      }
    },
    stateFineColor(state) {
      switch(state) {
        case 'paid':
          return 'success'
        case 'partial':
          return 'warning'
        default:
          return 'error'
      }
    },
    translateFinesState(state) {
      let state_text = "";
      if (state == "Создан") {
        state_text = this.$t("fines_state.created");
      }
      if (state == "Оповещён") {
        state_text = this.$t("fines_state.notify");
      }
      if (state == "Оплачен") {
        state_text = this.$t("fines_state.paid");
      }
      if (state == "Возмещён") {
        state_text = this.$t("fines_state.refund");
      }
      return state_text;
    },
  }
}
</script>
