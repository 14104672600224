<template>
  <v-flex md12 v-if="invoices.length > 0">
    <v-divider class="mt-3 mb-3"></v-divider>
    <h2>{{ $t("custom.invoices") }}</h2>
    <v-data-table
      :headers="headers_invoices"
      :items="invoices"
      :loading="isLoading"
      :loading-text="$t('custom.loading_table')"
      :items-per-page="20"
      mobile-breakpoint="100"
      dense
      :sort-by="['created_at']"
      :sort-desc="[true]"
      :class="!isMobile ? '' : 'is-mobile'"
      hide-default-footer
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td class="text-center">
            <router-link :to="{ path: `/invoices/${props.item.number}` }">{{ props.item.number }}</router-link>
          </td>
          <td class="text-center">
            {{ returnDate(props.item.date) }}
          </td>
          <td class="text-center">
            <read-more
              v-if="props.item.description && props.item.description.length > 0"
              :more-str="$t('custom.read')"
              :text="props.item.description"
              link="#"
              :less-str="$t('custom.less')"
              :max-chars="20"
            ></read-more>
          </td>
          <td class="text-center">
            {{
              props.item.rent +
              props.item.deposit +
              props.item.delivery +
              props.item.delivery_end +
              props.item.clean +
              props.item.gas +
              props.item.damage +
              props.item.fines +
              props.item.mileage +
              props.item.hours_cost +
              props.item.insurance +
              props.item.equipment +
              props.item.other
            }}
          </td>
          <td class="text-center">{{ paidInvoice(props.item) }}</td>
          <td class="text-center">
            {{ props.item.paid ? props.item.paid : 0 }}
          </td>
          <td class="text-center">
            <v-btn :color="stateInvoiceColor(props.item.state)" small block>
              {{ props.item.state }}
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="no-data">
        {{ $t("custom.no_data_in_table") }}
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'

export default {
  name: 'BookingInvoices',
  props: {
    invoices: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      headers_invoices: [
        { text: this.$t("custom.number"), align: "center", value: "number" },
        { text: this.$t("invoices.date"), value: "date", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("invoices.control"), sortable: false, align: "center" },
        { text: this.$t("invoices.get"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
    }
  },
  methods: {
    returnDate(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY')
      } else {
        return date
      }
    },
    paidInvoice(invoice) {
      let invoice_sum =
        invoice.rent +
        invoice.deposit +
        invoice.delivery +
        invoice.delivery_end +
        invoice.clean +
        invoice.gas +
        invoice.damage +
        invoice.fines +
        invoice.mileage +
        invoice.hours_cost +
        invoice.insurance +
        invoice.add_drivers_cost +
        invoice.equipment +
        invoice.other;
      if (invoice_sum == invoice.paid && invoice_sum > 0 && invoice.paid > 0) {
        return this.$t("invoices.paid");
      } else if (invoice_sum < invoice.paid) {
        return this.$t("invoices.extra_paid");
      } else if (invoice_sum > 0 && invoice_sum > invoice.paid) {
        return this.$t("invoices.low_paid");
      } else if (invoice_sum > 0 && invoice.paid == 0) {
        return this.$t("invoices.no_income");
      } else {
        return `-`;
      }
    },
    stateInvoiceColor(state) {
      if (state == "Выставлен") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "success";
      }
      if (state == "Частично оплачен") {
        return "pink";
      }
    },
  }
}
</script>
