<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-flex class="cy-dashboard-loaded">
      <v-flex md12 :class="$vuetify.theme.dark ? 'dark-theme start-tour-0' : 'light-theme start-tour-0'">
        <v-flex class="d-flex justify-end">
          <v-btn v-if="editWidgets" icon @click="editWidgets = false, makeWidgetsStatic()">
            <v-icon>mdi-pencil-off</v-icon>
          </v-btn>
          <v-btn v-else icon @click="editWidgets = true, makeWidgetsDontStatic()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="showNewWidgetDialog = true">
            {{ $t("widgets.library") }}
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-flex>
        <grid-layout :layout.sync="widgets" :col-num="12" :row-height="30" :is-draggable="isMobile ? false : true"
          :is-resizable="isMobile ? false : true" :responsive="true" :vertical-compact="false"
          :use-css-transforms="true" :prevent-collision="true" :auto-size="true"
          :class="editWidgets ? 'edit-widget' : ''" ref="gridLayout"
          :layout-config="layoutConfig"
          @layout-updated="layoutUpdated"
        >
          <grid-item v-for="item in widgets" :key="item.i" :static="item.static" :x="item.x" :y="item.y" :w="item.w"
            :h="item.h" :i="item.i" :min-h="item.minH" :isResizable="isMobile ? false : true" @moved="moveEventWidget"
            @resized="resizedEventWidget">
            <v-flex v-if="item.widget_type == 'active_bookings_size'">
              <v-card>
                <v-img class="success white--text align-end" height="110px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text">mdi-airplane</v-icon>
                  </v-flex>
                  <v-card-title :class="isMobile
                    ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                    : 'd-flex mt-0 pt-1 justify-center'
                    ">
                    <span class="d-none d-xs-none d-sm-none d-md-none d-lg-flex">{{ $t("home.active_bookings")
                      }}:&nbsp;</span>
                    {{ active_bookings_count }}
                  </v-card-title>
                </v-img>
              </v-card>
            </v-flex>
            <v-flex v-else-if="item.widget_type == 'yesterday_bookings_size'">
              <v-card>
                <v-img class="warning white--text align-end" height="110px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text">mdi-calendar-today-outline</v-icon>
                  </v-flex>
                  <v-card-title :class="isMobile
                    ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                    : 'd-flex mt-0 pt-1 justify-center'
                    "><span class="d-none d-xs-none d-sm-none d-md-none d-lg-flex">{{ $t("home.yesterday_bookings")
                      }}:&nbsp;</span>
                    {{ bookings_yesterday_count }}</v-card-title>
                </v-img>
              </v-card>
            </v-flex>
            <v-flex v-else-if="item.widget_type == 'car_on_parking'">
              <v-card>
                <v-img class="info white--text align-end" height="110px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text">mdi-car-brake-parking</v-icon>
                  </v-flex>
                  <v-card-title :class="isMobile
                    ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                    : 'd-flex mt-0 pt-1 justify-center'
                    "><span class="d-none d-xs-none d-sm-none d-md-none d-lg-flex">{{ $t("home.cars_on_parking")
                      }}:&nbsp;</span>
                    {{ free_cars_count }}</v-card-title>
                </v-img>
              </v-card>
            </v-flex>
            <v-flex v-else-if="item.widget_type == 'clients_size'">
              <v-card>
                <v-img class="secondary white--text align-end" height="110px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text">mdi-face-man</v-icon>
                  </v-flex>
                  <v-card-title :class="isMobile
                    ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                    : 'd-flex mt-0 pt-1 justify-center'
                    "><span class="d-none d-xs-none d-sm-none d-md-none d-lg-flex">{{ $t("home.all_clients")
                      }}:&nbsp;</span>
                    {{ clients_count }}</v-card-title>
                </v-img>
              </v-card>
            </v-flex>
            <v-card v-else-if="item.widget_type == 'bookings_late_end_date'">
              <v-card-title>
                {{ $t("widgets.bookings_late_end_date") }}
              </v-card-title>
              <v-card-text>
                <v-data-table :items="bookings_late_end_date" :headers="bookings_late_end_date_headers"
                  :loading="isLoading" :loading-text="$t('custom.loading_table')" :class="!isMobile ? '' : 'is-mobile'"
                  mobile-breakpoint="100" height="250px" dense :items-per-page="6" :footer-props="{
                    itemsPerPageText: '',
                    showFirstLastPage: false,
                    itemsPerPageOptions: [6, 12, 24, -1],
                    disableItemsPerPage: false,
                    showCurrentPage: false,
                  }">
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td nowrap class="text-center">
                        <router-link :to="{ name: 'BookingCard', params: { id: props.item.id } }">{{ props.item.id
                          }}</router-link>
                      </td>
                      <td nowrap class="text-center">
                        <router-link :to="{ path: `/cars/${props.item.car_id}` }">
                          {{ props.item.car_code }}
                        </router-link>
                      </td>
                      <td nowrap class="text-center">
                        {{ formatDateBookingDates(props.item.start_date) }}
                        {{ formatTimeBookingDates(props.item.start_date) }}
                      </td>
                      <td nowrap class="text-center" :style="checkLateDate(props.item.end_date)">
                        {{ formatDateBookingDates(props.item.end_date) }}
                        {{ formatTimeBookingDates(props.item.end_date) }}
                      </td>
                      <td nowrap class="text-center">
                        <v-btn x-small :color="selectColorPaid(props.item)" v-if="props.item.counts.length > 0 ||
                          (props.item.in_rent && !props.item.technical)
                        " style="cursor: default" :disabled="!props.item.active &&
                          (calculatePayments(props.item) != $t('bookings.paid')
                            ? false
                            : true)
                          " v-tooltip="{
                            content: `${calculatePayments(props.item) != $t('bookings.paid')
                              ? $t('bookings.partly_paid')
                              : $t('bookings.fully_paid')
                              }`,
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }">{{ calculatePayments(props.item) }}</v-btn>
                      </td>
                    </tr>
                  </template>
                  <template slot="no-data">
                    {{ $t("custom.no_data_in_table") }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card v-else-if="item.widget_type == 'personal_bookings_plan'">
              <v-card-title>
                {{ $t("widgets.personal_bookings_plan") }}
              </v-card-title>
              <v-card-text>
                <v-data-table :items="personal_bookings_plan" :headers="personal_bookings_plan_headers"
                  :loading="isLoading" :loading-text="$t('custom.loading_table')" :class="!isMobile ? '' : 'is-mobile'"
                  mobile-breakpoint="100" height="250px" dense hide-default-header :items-per-page="6" :footer-props="{
                    itemsPerPageText: '',
                    showFirstLastPage: false,
                    itemsPerPageOptions: [6, 12, 24, -1],
                    disableItemsPerPage: false,
                    showCurrentPage: false,
                  }">
                  <template slot="item" slot-scope="props">
                    <tr @click="replaceToBooking(props.item)">
                      <td nowrap class="text-center">
                        <v-avatar icon :color="!selectBookingState(props.item) ? 'success' : 'primary'" size="30px"
                          class="my-1">
                          <v-icon color="white">
                            {{ !selectBookingState(props.item) ? 'mdi-home' : 'mdi-airplane-takeoff' }}
                          </v-icon>
                        </v-avatar>
                      </td>
                      <!-- <td nowrap class="text-center">
                          <router-link
                            :to="{ name: 'BookingCard', params: { id: props.item.id } }"
                            >{{ props.item.id }}</router-link
                          >
                        </td> -->
                      <td nowrap class="text-center">
                        <router-link :to="{ path: `/cars/${props.item.car_id}` }">
                          {{ props.item.car_code }}
                        </router-link>
                      </td>
                      <td v-if="!selectBookingState(props.item)" nowrap class="text-center">
                        {{ formatTimeBookingDates(props.item.start_date) }}
                      </td>
                      <td v-else nowrap class="text-center">
                        {{ formatTimeBookingDates(props.item.end_date) }}
                      </td>
                      <td nowrap class="text-center">
                        {{
                          !selectBookingState(props.item)
                            ? props.item.location_end
                            : props.item.location_start
                        }}
                      </td>
                    </tr>
                  </template>
                  <template slot="no-data">
                    {{ $t("custom.no_data_in_table") }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card v-else-if="item.widget_type == 'technical_bookings'">
              <v-card-title>
                {{ $t("widgets.technical_bookings") }}
              </v-card-title>
              <v-card-text>
                <v-data-table :items="technical_bookings" :loading="isLoading"
                  :loading-text="$t('custom.loading_table')" :class="!isMobile ? '' : 'is-mobile'"
                  mobile-breakpoint="100" height="250px" dense hide-default-header :items-per-page="6" :footer-props="{
                    itemsPerPageText: '',
                    showFirstLastPage: false,
                    itemsPerPageOptions: [6, 12, 24, -1],
                    disableItemsPerPage: false,
                    showCurrentPage: false,
                  }">
                  <template slot="item" slot-scope="props">
                    <tr @click="replaceToBooking(props.item)">
                      <td nowrap class="text-center">
                        <v-avatar icon :color="!selectBookingState(props.item) ? 'success' : 'primary'" size="30px"
                          class="my-1">
                          <v-icon color="white">
                            {{ !selectBookingState(props.item) ? 'mdi-home' : 'mdi-airplane-takeoff' }}
                          </v-icon>
                        </v-avatar>
                      </td>
                      <!-- <td nowrap class="text-center">
                          <router-link
                            :to="{ name: 'BookingCard', params: { id: props.item.id } }"
                            >{{ props.item.id }}</router-link
                          >
                        </td> -->
                      <td nowrap class="text-center">
                        <router-link :to="{ path: `/cars/${props.item.car_id}` }">
                          {{ props.item.car_code }}
                        </router-link>
                      </td>
                      <td v-if="!selectBookingState(props.item)" nowrap class="text-center">
                        {{ formatTimeBookingDates(props.item.start_date) }}
                      </td>
                      <td v-else nowrap class="text-center">
                        {{ formatTimeBookingDates(props.item.end_date) }}
                      </td>
                      <td nowrap class="text-center">
                        {{
                          !selectBookingState(props.item)
                            ? props.item.location_end
                            : props.item.location_start
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          props.item.responsible
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          props.item.description
                        }}
                      </td>
                    </tr>
                  </template>
                  <template slot="no-data">
                    {{ $t("custom.no_data_in_table") }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card v-else-if="item.widget_type == 'return_deposits'">
              <v-card-title>
                {{ $t("widgets.return_deposits") }}
              </v-card-title>
              <v-card-text>
                <v-data-table :items="return_deposits" :loading="isLoading" :loading-text="$t('custom.loading_table')"
                  :class="!isMobile ? '' : 'is-mobile'" mobile-breakpoint="100" height="130px" dense hide-default-header
                  :items-per-page="4" :footer-props="{
                    itemsPerPageText: '',
                    showFirstLastPage: false,
                    itemsPerPageOptions: [4, 8, 12, -1],
                    disableItemsPerPage: false,
                    showCurrentPage: false,
                  }">
                  <template slot="item" slot-scope="props">
                    <tr @click="replaceToBooking(props.item)">
                      <td nowrap class="text-center">
                        <router-link :to="{ name: 'BookingCard', params: { id: props.item.id } }">{{ props.item.id
                          }}</router-link>
                      </td>
                      <td nowrap class="text-center">
                        <router-link :to="{ path: `/cars/${props.item.car_id}` }">
                          {{ props.item.car_code }}
                        </router-link>
                      </td>
                      <td nowrap class="text-center">
                        {{ formatBookingDates(props.item.end_date) }}
                      </td>
                      <td class="text-center">
                        {{
                          props.item.responsible
                        }}
                      </td>
                    </tr>
                  </template>
                  <template slot="no-data">
                    {{ $t("custom.no_data_in_table") }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card v-else-if="item.widget_type == 'fines_sale_end' && ygibdd_active">
              <v-card-title>
                {{ $t("widgets.fines_sale_end") }}
              </v-card-title>
              <v-card-text>
                <v-data-table :items="fines_sale_end" :headers="fines_sale_end_headers" :loading="isLoading"
                  :loading-text="$t('custom.loading_table')" :class="!isMobile ? '' : 'is-mobile'"
                  mobile-breakpoint="100" height="250px" dense :items-per-page="6" :footer-props="{
                    itemsPerPageText: '',
                    showFirstLastPage: false,
                    itemsPerPageOptions: [6, 12, 24, -1],
                    disableItemsPerPage: false,
                    showCurrentPage: false,
                  }">
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td nowrap class="text-center">
                        <router-link :to="{ path: `/fines/${props.item.number}` }">{{
                          props.item.number
                          }}</router-link>
                      </td>
                      <td v-if="showSale(props.item.discount_date)" class="text-center" :style="checkLastDaySale(props.item.discount_date) ? 'color: red;' : ''
                        ">
                        {{ formatCreatedAtNoTime(props.item.discount_date) }}
                      </td>
                      <td class="text-center" v-else-if="!showSale(props.item.discount_date)">
                        -
                      </td>
                      <td class="text-center" v-tooltip="{
                        content: `${props.item.article_description}`,
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }">
                        {{ props.item.article }}
                      </td>
                      <td class="text-center">
                        <v-btn :color="stateFineColor(props.item.state)" x-small>
                          {{ props.item.state ? translateFinesState(props.item.state) : $t("fines.fresh") }}
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                  <template slot="no-data">
                    {{ $t("custom.no_data_in_table") }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card v-else-if="item.widget_type == 'schedule'" height="389px">
              <v-card-title>
                {{ $t("custom.schedule") }}
              </v-card-title>
              <v-card-text>
                <v-calendar ref="calendar" :weekdays="[1, 2, 3, 4, 5, 6, 0]" type="month" :events="schedule_events"
                  event-overlap-mode="column" :event-overlap-threshold="30" :locale="$i18n.locale"
                  :start="'2023-11-15'"></v-calendar>
              </v-card-text>
            </v-card>
            <v-card v-else-if="item.widget_type == 'work_now'">
              <v-card-title>
                {{ $t("widgets.work_now") }}
              </v-card-title>
              <v-card-text>
                <v-data-table :items="work_now" sort-by="name" hide-default-header :loading="isLoading"
                  :loading-text="$t('custom.loading_table')" :class="!isMobile ? '' : 'is-mobile'"
                  mobile-breakpoint="100" height="250px" dense :items-per-page="6" :footer-props="{
                    itemsPerPageText: '',
                    showFirstLastPage: false,
                    itemsPerPageOptions: [6, 12, 24, -1],
                    disableItemsPerPage: false,
                    showCurrentPage: false,
                  }">
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td nowrap class="text-center">
                        {{ props.item.name ? props.item.name : props.item.email }}
                      </td>
                      <td nowrap class="text-center">
                        <v-icon class="mr-2" color="success" @click="voteUp(props.item)" :title="props.item.vote_up">
                          mdi-thumb-up-outline
                        </v-icon>
                        <v-icon color="grey" @click="voteDown(props.item)" :title="props.item.vote_down">
                          mdi-thumb-down-outline
                        </v-icon>
                      </td>
                      <td nowrap class="text-center">
                        <v-avatar color="primary" size="30px">
                          {{ props.item.vote_up - props.item.vote_down }}
                        </v-avatar>
                      </td>
                    </tr>
                  </template>
                  <template slot="no-data">
                    {{ $t("custom.no_data_in_table") }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card v-else-if="item.widget_type == 'bookings_month'" height="389px">
              <v-card-title>
                {{ $t("widgets.bookings_month") }}
              </v-card-title>
              <v-card-text>
                <Bar :chart-options="chartOptionsBookingsMonth" :chart-data="chartDataBookingsMonth"
                  :height="(item.h * 30) - 10" />
              </v-card-text>
            </v-card>
            <v-card v-else-if="item.widget_type == 'cars_statuses'" height="389px">
              <v-card-title>
                {{ $t("widgets.cars_statuses") }}
              </v-card-title>
              <v-card-text>
                <Pie :chart-options="chartOptionsCarsStatuses" :chart-data="chartDataCarsStatuses"
                  :height="(item.h * 30)" />
              </v-card-text>
            </v-card>
            <v-card v-else-if="item.widget_type == 'cars_admin_calculation'" height="389px">
              <v-card-title>
                {{ $t("widgets.cars_admin_calculation") }}
              </v-card-title>
              <v-card-text>
                <Doughnut :chart-options="chartOptionsCarsAdminCalculation" :chart-data="chartDataCarsAdminCalculation"
                  :height="(item.h * 30)" />
              </v-card-text>
            </v-card>
            <v-flex v-else>{{ item.widget_type }}</v-flex>
            <span v-if="editWidgets" class="remove" @click="removeWidget(item.i)">
              <v-icon>mdi-close</v-icon>
            </span>
          </grid-item>
        </grid-layout>
      </v-flex>
      <v-flex md12 v-if="widgets && widgets.length == 0">
        <v-container fluid class="start-tour-0">
          <v-row>
            <v-col>
              <v-card>
                <v-img class="success white--text align-end" height="100px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text">mdi-airplane</v-icon>
                  </v-flex>
                  <v-card-title :class="isMobile
                    ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                    : 'd-flex mt-0 pt-1 justify-center'
                    ">
                    <span class="d-none d-xs-none d-sm-none d-md-none d-lg-flex">{{ $t("home.active_bookings")
                      }}:&nbsp;</span>
                    {{ active_bookings_count }}
                  </v-card-title>
                </v-img>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-img class="warning white--text align-end" height="100px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text">mdi-calendar-today-outline</v-icon>
                  </v-flex>
                  <v-card-title :class="isMobile
                    ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                    : 'd-flex mt-0 pt-1 justify-center'
                    "><span class="d-none d-xs-none d-sm-none d-md-none d-lg-flex">{{ $t("home.yesterday_bookings")
                      }}:&nbsp;</span>
                    {{ bookings_yesterday_count }}</v-card-title>
                </v-img>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-img class="info white--text align-end" height="100px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text">mdi-car-brake-parking</v-icon>
                  </v-flex>
                  <v-card-title :class="isMobile
                    ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                    : 'd-flex mt-0 pt-1 justify-center'
                    "><span class="d-none d-xs-none d-sm-none d-md-none d-lg-flex">{{ $t("home.cars_on_parking")
                      }}:&nbsp;</span>
                    {{ free_cars_count }}</v-card-title>
                </v-img>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-img class="secondary white--text align-end" height="100px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text">mdi-face-man</v-icon>
                  </v-flex>
                  <v-card-title :class="isMobile
                    ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                    : 'd-flex mt-0 pt-1 justify-center'
                    "><span class="d-none d-xs-none d-sm-none d-md-none d-lg-flex">{{ $t("home.all_clients")
                      }}:&nbsp;</span>
                    {{ clients_count }}</v-card-title>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>
      <v-flex md12 v-if="show_main_table || show_main_table == null">
        <v-divider class="mt-2 mb-2"></v-divider>
        <v-toolbar flat :color="$vuetify.theme.dark ? '' : 'white'">
          <v-flex sm12 md6>
            <v-toolbar-title>{{ $t("home.cars_on_parking") }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-flex>
          <v-flex v-if="!isMobile" sm12 md6 class="d-flex">
            <v-text-field v-model="searchInBookings" append-icon="mdi-magnify" :label="$t('custom.search')" single-line
              clearable hide-details></v-text-field>
            <div v-if="editWidgets" @click="updateUserSetting()">
              <v-icon style="cursor:pointer;">mdi-close</v-icon>
            </div>
          </v-flex>
          <v-flex v-else sm12 md6 class="d-flex">
            <v-spacer></v-spacer>
            <div v-if="editWidgets" @click="updateUserSetting()">
              <v-icon style="cursor:pointer;">mdi-close</v-icon>
            </div>
          </v-flex>
        </v-toolbar>
        <v-data-table :items="free_cars_start_dates" :headers="filteredCarsHeaders" :search="searchInBookings" dense
          :loading="isLoading" :loading-text="$t('custom.loading_table')" mobile-breakpoint="100"
          :class="!isMobile ? 'start-tour-1' : 'start-tour-1 is-mobile'" :footer-props="{
            itemsPerPage: 100,
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [50, 100, 200, -1],
          }">
          <template slot="item" slot-scope="props">
            <tr :key="props.item.car_id">
              <td v-if="isMobile" class="text-center">
                <router-link id="tour_car_id" class="" :to="{ path: `/cars/${props.item.car_id}` }">{{
                  props.item.car_code }}</router-link>
              </td>
              <td v-else class="text-center" nowrap>
                <router-link id="tour_car_id" style="text-decoration: none;"
                  :to="{ path: `/cars/${props.item.car_id}` }">
                  <span class="success--text mr-2 subtitle-1">{{ props.item.car_code }}</span>
                  <span class="info--text subtitle-1">{{ props.item.number }}</span>
                  <br>
                  <span class="subtitle-1">{{ props.item.car_name }}</span>
                </router-link>
              </td>
              <!-- Состояние -->
              <td class="text-center" data-cy="car_state">
                <v-menu offset-y v-if="props.item.state == 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('states.normal'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="success">
                      mdi-thumb-up
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(state, index) in states" :key="index"
                      @click="updateCarState(props.item.car_id, state.id)">
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.state == 2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('states.in_service'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="secondary">
                      mdi-tools
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(state, index) in states" :key="index"
                      @click="updateCarState(props.item.car_id, state.id)">
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.state == 3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('states.critical'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="error">
                      mdi-alert-box
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(state, index) in states" :key="index"
                      @click="updateCarState(props.item.car_id, state.id)">
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.state == 4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('states.long_rent'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="pink">
                      mdi-all-inclusive
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(state, index) in states" :key="index"
                      @click="updateCarState(props.item.car_id, state.id)">
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.state == 5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('states.no_rent'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="info">
                      mdi-airplane-off
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(state, index) in states" :key="index"
                      @click="updateCarState(props.item.car_id, state.id)">
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.state == 6">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('states.service_needed'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="orange">
                      mdi-alarm-light
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(state, index) in states" :key="index"
                      @click="updateCarState(props.item.car_id, state.id)">
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <!-- Мойка -->
              <td class="text-center">
                <v-menu offset-y v-if="props.item.clean_state">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('states.clean'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="info">
                      mdi-flare
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="updateCleanState(props.item.car_id, true)">
                      <v-list-item-title>
                        {{ $t("states.clean") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateCleanState(props.item.car_id, false)">
                      <v-list-item-title>
                        {{ $t("states.durty") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('states.durty'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="brown">
                      mdi-spray-bottle
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="updateCleanState(props.item.car_id, true)">
                      <v-list-item-title>
                        {{ $t("states.clean") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateCleanState(props.item.car_id, false)">
                      <v-list-item-title>
                        {{ $t("states.durty") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <!-- Бензин -->
              <td class="text-center">
                <v-menu offset-y v-if="props.item.tank_state">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('states.gas_full'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="light-green">
                      mdi-battery
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="updateTankState(props.item.car_id, true)">
                      <v-list-item-title>
                        {{ $t("states.gas_full") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateTankState(props.item.car_id, false)">
                      <v-list-item-title>
                        {{ $t("states.gas_empty") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('states.gas_not_full'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="red">
                      mdi-battery-10
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="updateTankState(props.item.car_id, true)">
                      <v-list-item-title>
                        {{ $t("states.gas_full") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateTankState(props.item.car_id, false)">
                      <v-list-item-title>
                        {{ $t("states.gas_empty") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <td :nowrap="isMobile ? true : false" class="text-center" v-if="props.item.start_date">
                <router-link :to="{ path: `/bookings/${props.item.booking_id}` }">
                  {{ props.item.start_date }}
                </router-link>
              </td>
              <td class="text-center" v-else>
                {{ $t("bookings.no_bookings") }}
              </td>
              <!-- Обслуживание -->
              <td v-if="show_repairs" class="text-center white--text">
                <v-alert v-for="item in props.item.maintenances" :key="item.id" border="left"
                  :color="selectCriticalityClass(item.criticality)" dense dark class="my-2 white--text caption"
                  style="cursor: pointer; "
                  @click="maintenance = item, loadContractors(), showEndMaintenance(), maintenance.last_maintenanced_mileage = props.item.mileage"
                  :icon="checkMaintenanceIcon(item)">
                  {{ item.title }}
                </v-alert>
              </td>
              <!-- Местонахождение -->
              <td v-if="show_store_place" class="text-center">
                <v-flex>
                  <v-flex v-if="main_company.beacons_on && props.item.traccar_id"
                    class="d-flex align-middle justify-center">
                    <div class="text-center pt-2 pb-2" style="cursor: pointer" @click="
                      (store_place = props.item.store_place),
                      (car_id = props.item.car_id),
                      (updatePlaceDialog = true)
                      ">
                      {{ props.item.store_place ? props.item.store_place : "" }}
                    </div>
                    <v-btn
                      v-if="!props.item.store_place || props.item.store_place == '' || props.item.store_place == ' '"
                      color="secondary" :title="$t('cars.current_position')" class="ml-1" icon @click="
                        (store_place = props.item.store_place),
                        (car_id = props.item.car_id),
                        (updatePlaceDialog = true)
                        ">
                      <v-icon>mdi-text-box-plus-outline</v-icon>
                    </v-btn>
                    <v-btn @click="getCurrentPosition(props.item.traccar_id)" color="info"
                      :title="$t('cars.current_position')" class="ml-1" icon
                      :disabled="main_company.beacons_on == false || user_exists_in_traccar == false || props.item.traccar_id == null">
                      <v-icon>mdi-map-marker-radius-outline</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex v-else>
                    <p :class="props.item.store_place" style="cursor: pointer" @click="
                      (store_place = props.item.store_place),
                      (car_id = props.item.car_id),
                      (updatePlaceDialog = true)
                      ">
                      {{ props.item.store_place ? props.item.store_place : "-" }}
                    </p>
                  </v-flex>
                </v-flex>
              </td>
              <!-- Последнее ТО -->
              <td v-if="show_to" class="text-center" :nowrap="isMobile ? true : false">
                <p @click="
                  (car_id = props.item.car_id), (inspection_dialog = true)
                  " style="cursor: pointer">
                  {{
                    props.item.last_inspection
                      ? formatedDatesLastInspection(props.item.last_inspection)
                      : "-"
                  }}
                </p>
              </td>
              <!-- Резина -->
              <td v-if="show_tire" class="text-center">
                <v-menu offset-y v-if="props.item.tire_type == 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('wheels.summer'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="yellow">
                      mdi-weather-sunny
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(tire_type, index) in tire_types" :key="index"
                      @click="updateTireType(props.item.car_id, tire_type.id)">
                      <v-list-item-title>
                        {{ tire_type.tire_type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.tire_type == 2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('wheels.winter'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="info">
                      mdi-snowflake
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(tire_type, index) in tire_types" :key="index"
                      @click="updateTireType(props.item.car_id, tire_type.id)">
                      <v-list-item-title>
                        {{ tire_type.tire_type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.tire_type == 3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('wheels.all_seasons'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="info">
                      mdi-theme-light-dark
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(tire_type, index) in tire_types" :key="index"
                      @click="updateTireType(props.item.car_id, tire_type.id)">
                      <v-list-item-title>
                        {{ tire_type.tire_type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.tire_type == 4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('wheels.both'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="info">
                      mdi-circle-half-full
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(tire_type, index) in tire_types" :key="index"
                      @click="updateTireType(props.item.car_id, tire_type.id)">
                      <v-list-item-title>
                        {{ tire_type.tire_type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.tire_type == 5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-tooltip="{
                      content: $t('wheels.spike'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" v-bind="attrs" v-on="on" color="light-green">
                      mdi-pine-tree
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(tire_type, index) in tire_types" :key="index"
                      @click="updateTireType(props.item.car_id, tire_type.id)">
                      <v-list-item-title>
                        {{ tire_type.tire_type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
            {{ $t("tables.no_search_result", { msg: searchInBookings }) }}
          </v-alert>
        </v-data-table>
      </v-flex>
    </v-flex>
    <v-dialog :retain-focus="false" v-model="updatePlaceDialog" max-width="500px" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("home.car_location") }}</span>
          <v-icon class="ml-2 green--text" @click="storePlaceSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="
            (updatePlaceDialog = false), (store_place = null), (car_id = null)
            ">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12 justify="space-around">
                    <v-text-field v-model="store_place" clearable :label="$t('home.location')"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="
            (updatePlaceDialog = false), (store_place = null), (car_id = null)
            ">{{ $t("custom.close") }}</v-btn>
          <v-btn color="success" @click="storePlaceSave()">{{
            $t("custom.save")
            }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" v-model="inspection_dialog" max-width="500px" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title id="registration-step-2">
          <span class="headline">{{ $t("to.to") }}</span>
          <v-icon class="ml-2 green--text" @click="inspectionSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="
            (inspection_dialog = false),
            (car_id = null),
            (inspection_date = null),
            (inspection_date_menu = false),
            (inspection_description = null)
            ">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12 justify="space-around">
                    <v-menu v-model="inspection_date_menu" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="inspection_date_format" :label="$t('to.date')"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="mt-5"></v-text-field>
                      </template>
                      <v-date-picker v-model="inspection_date" :locale="$i18n.locale"
                        @input="inspection_date_menu = false" first-day-of-week="1"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="inspection_description" clearable rows="2" auto-grow outlined
                      :label="$t('to.total')"></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="
            (inspection_dialog = false),
            (car_id = null),
            (inspection_date = null),
            (inspection_date_menu = false),
            (inspection_description = null)
            ">{{ $t("custom.close") }}</v-btn>
          <v-btn color="success" @click="inspectionSave()">{{
            $t("custom.save")
            }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEndMaintenanceDialog" :retain-focus="false" persistent max-width="600px"
      :fullscreen="isMobile ? true : false">
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ returnEndMaintenanceTitle(maintenance) }}
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="showEndMaintenanceDialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex v-if="maintenance.periodic && maintenance.maintenance_type == 4" md12 class="mb-6 mt-6">
            <v-menu offset-y :close-on-content-click="false" max-width="252px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-bind="attrs" v-on="on"
                  :value="maintenance.start_insurance_date ? formatLastMaintenancedDate(maintenance.start_insurance_date) : $t('maintenance.start_insurance_date')"
                  :label="$t('maintenance.start_insurance_date')" :attributes="attrs_calendar" outlined
                  hide-details></v-text-field>
              </template>
              <vc-date-picker v-model="maintenance.start_insurance_date" mode="date" :masks="{ input: 'YYYY-MM-DD' }"
                :locale="$i18n.locale">
              </vc-date-picker>
            </v-menu>
          </v-flex>
          <v-flex v-if="maintenance.periodic && maintenance.maintenance_type == 4" md12 class="mb-6 mt-6">
            <v-menu offset-y :close-on-content-click="false" max-width="252px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-bind="attrs" v-on="on" readonly :attributes="attrs_calendar"
                  :value="maintenance.end_insurance_date ? formatLastMaintenancedDate(maintenance.end_insurance_date) : $t('maintenance.end_insurance_date')"
                  :label="$t('maintenance.end_insurance_date')" outlined hide-details></v-text-field>
              </template>
              <vc-date-picker v-model="maintenance.end_insurance_date" mode="date" :masks="{ input: 'YYYY-MM-DD' }"
                :locale="$i18n.locale">
              </vc-date-picker>
            </v-menu>
          </v-flex>
          <v-flex v-if="maintenance.periodic && maintenance.maintenance_type == 5" md12 class="mb-6 mt-6">
            <v-menu offset-y :close-on-content-click="false" max-width="252px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-bind="attrs" v-on="on"
                  :value="maintenance.start_insurance_date ? formatLastMaintenancedDate(maintenance.start_insurance_date) : $t('maintenance.last_payment_date')"
                  :label="$t('maintenance.last_payment_date')" :attributes="attrs_calendar" outlined
                  hide-details></v-text-field>
              </template>
              <vc-date-picker v-model="maintenance.start_insurance_date" mode="date" :masks="{ input: 'YYYY-MM-DD' }"
                :locale="$i18n.locale">
              </vc-date-picker>
            </v-menu>
          </v-flex>
          <v-flex v-if="maintenance.periodic && maintenance.maintenance_type == 5" md12 class="mb-6">
            <v-menu offset-y :close-on-content-click="false" max-width="252px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-bind="attrs" v-on="on" readonly :attributes="attrs_calendar"
                  :value="maintenance.end_insurance_date ? formatLastMaintenancedDate(maintenance.end_insurance_date) : $t('maintenance.next_payment_date')"
                  :label="$t('maintenance.next_payment_date')" outlined hide-details></v-text-field>
              </template>
              <vc-date-picker v-model="maintenance.end_insurance_date" mode="date" :masks="{ input: 'YYYY-MM-DD' }"
                :locale="$i18n.locale">
              </vc-date-picker>
            </v-menu>
          </v-flex>
          <!-- <v-flex md12>
            <v-text-field
              v-model.trim="maintenance.last_maintenanced_mileage"
              :label="$t('maintenance.last_maintenanced_mileage')"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-flex> -->
          <v-flex md12 class="mb-5">
            <v-autocomplete v-model="maintenance.contractor_id" item-text="short_name" item-value="id"
              :loading="isLoadingContractors" :search-input.sync="search_contractor" outlined clearable hide-no-data
              hide-details dense :label="$t('contractors.select_contractor')" :items="contractors">
            </v-autocomplete>
          </v-flex>
          <v-flex md12>
            <v-textarea v-model="maintenance.description" :label="$t('maintenance.description')" outlined rows="1"
              auto-grow dense></v-textarea>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showEndMaintenanceDialog = false">{{
            $t("custom.close")
            }}</v-btn>
          <v-btn v-if="maintenance.maintenance_type != 4 && maintenance.maintenance_type != 5" color="success"
            @click="saveEndMaintenance()">{{
              $t("maintenance.save_end_maintenance")
            }}</v-btn>
          <v-btn v-if="maintenance.maintenance_type == 4" color="success" @click="saveEndInsurance()">{{
            $t("maintenance.save_end_maintenance")
            }}</v-btn>
          <v-btn v-if="maintenance.maintenance_type == 5" color="success" @click="saveEndInsurance()">{{
            $t("maintenance.end_payment_notify")
            }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCurrentPositionDialog" :retain-focus="false"
      @click:outside="showCurrentPositionDialog = false, latitude = null, longitude = null" persistent max-width="620px"
      :style="isMobile ? 'padding: 5px !important;' : ''">
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click.stop="showCurrentPositionDialog = false, latitude = null, longitude = null">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-flex v-if="latitude && longitude" md12 class="my-2 mx-auto">
            <current-position :latitude="latitude" :longitude="longitude"></current-position>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="showCurrentPositionDialog = false, latitude = null, longitude = null">{{
            $t("custom.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNewWidgetDialog" :retain-focus="false" @click:outside="showNewWidgetDialog = false"
      persistent max-width="620px" :style="isMobile ? 'padding: 5px !important;' : ''">
      <v-card>
        <v-card-title :class="isMobile ? '' : 'headline'">
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click.stop="showNewWidgetDialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-group v-for="item in available_widgets" :key="item.group" v-model="item.active" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.group"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="child in item.children" :key="child.widget_type">
                <v-list-item-content>
                  <v-list-item-title :class="isMobile ? 'text-center' : 'd-flex justify-space-between'">
                    <v-flex wrap>
                      {{ child.text }}
                    </v-flex>
                    <v-flex v-if="child.widget_type != 'parking_table'"
                      :class="isMobile ? 'text-center' : 'd-flex justify-end'">
                      <v-btn color="success" small @click="addWidget(child.widget_type)"
                        :disabled="checkWidget(child.widget_type)" :class="isMobile ? 'mt-2' : ''">
                        {{ $t("custom.add") }}
                      </v-btn>
                    </v-flex>
                    <v-flex v-if="child.widget_type == 'parking_table'"
                      :class="isMobile ? 'text-center' : 'd-flex justify-end'">
                      <v-btn color="success" small @click="updateUserSetting()" :disabled="show_main_table"
                        :class="isMobile ? 'mt-2' : ''">
                        {{ $t("custom.add") }}
                      </v-btn>
                    </v-flex>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="showNewWidgetDialog = false">{{
            $t("custom.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// // moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
import CurrentPosition from "./dashboard/gps/CurrentPosition";
import { GridLayout, GridItem } from "vue-grid-layout"
import { Capacitor } from "@capacitor/core";
import { Bar, Pie, Doughnut } from 'vue-chartjs/legacy'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)
export default {
  data: function () {
    return {
      widgets: [],
      available_widgets: [
        {
          group: this.$t('widgets.analitics'),
          children: [
            { widget_type: 'bookings_month', text: this.$t('widgets.bookings_month') },
            { widget_type: 'active_bookings_size', text: this.$t('widgets.active_bookings_size') },
            { widget_type: 'yesterday_bookings_size', text: this.$t('widgets.yesterday_bookings_size') },
            { widget_type: 'car_on_parking', text: this.$t('widgets.car_on_parking') },
            { widget_type: 'clients_size', text: this.$t('widgets.clients_size') },
            { widget_type: 'cars_statuses', text: this.$t('widgets.cars_statuses') },
            { widget_type: 'cars_admin_calculation', text: this.$t('widgets.cars_admin_calculation') },
          ],
          active: false
        },
        {
          group: this.$t('widgets.bookings'),
          children: [
            { widget_type: 'bookings_late_end_date', text: this.$t('widgets.bookings_late_end_date') },
            { widget_type: 'personal_bookings_plan', text: this.$t('widgets.personal_bookings_plan') },
            { widget_type: 'technical_bookings', text: this.$t('widgets.technical_bookings') },
            { widget_type: 'return_deposits', text: this.$t('widgets.return_deposits') },
          ],
          active: false
        },
        {
          group: this.$t('widgets.fines'),
          children: [{ widget_type: 'fines_sale_end', text: this.$t('widgets.fines_sale_end') }],
          active: false
        },
        {
          group: this.$t('custom.employers'),
          children: [{ widget_type: 'schedule', text: this.$t('custom.schedule') }, { widget_type: 'work_now', text: this.$t('widgets.work_now') }],
          active: false
        },
        {
          group: this.$t('widgets.common'),
          children: [{ widget_type: 'parking_table', text: this.$t('widgets.parking_table') }],
          active: false
        },
      ],
      bookings_late_end_date: [],
      bookings_late_end_date_counts: [],
      personal_bookings_plan: [],
      technical_bookings: [],
      return_deposits: [],
      work_now: [],
      schedule_events: [],
      fines_sale_end: [],
      cars_statuses: [],
      cars_admin_calculation: {},
      editWidgets: false,
      bookings_for_now: [],
      free_cars: [],
      free_cars_start_dates: [],
      active_bookings_count: 0,
      bookings_yesterday_count: 0,
      free_cars_count: 0,
      clients_count: 0,
      editedCar: {},
      search: "",
      searchInBookings: "",
      maintenance: {
        periodic: false,
        started: true,
        maintenance_type: 0,
        title: null,
        description: null,
        mileage_step: 0,
        days_step: 0,
        last_maintenanced_date: moment().format("YYYY-MM-DD"),
        notify_before: 0,
        criticality: 0,
        responsible: [],
        assign_to_all_cars: false,
        car_id: null,
        car_ids: [],
      },
      attrs_calendar: [
        {
          key: "today",
          highlight: {
            color: "blue",
            fillMode: "outline",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      contractors: [],
      chartDataBookingsMonth: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            label: this.$t('widgets.current_month'),
            backgroundColor: '#f87979',
            data: []
          },
          {
            label: this.$t('widgets.previous_month'),
            backgroundColor: '#4CAF50',
            data: []
          }
        ]
      },
      chartOptionsBookingsMonth: {
        responsive: true,
        maintainAspectRatio: false
      },
      chartDataCarsStatuses: {
        labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#FFCE56', '#36A2EB'],
            data: [40, 20, 80, 10]
          }
        ]
      },
      chartOptionsCarsStatuses: {
        responsive: true,
        maintainAspectRatio: false
      },
      // cars_count: 0,
      //     cars_in_rent_count: 0,
      //     free_cars: 0,
      //     free_cars_in_bad_state: 0,
      //     cars_booked_now: 0,
      //     cars_booked_tomorrow: 0,
      //     cars_booked_after_tomorrow: 0
      chartDataCarsAdminCalculation: {
        labels: [this.$t('widgets.free_cars'), this.$t('widgets.free_cars_in_bad_state'), this.$t('widgets.cars_booked_now'), this.$t('widgets.cars_booked_tomorrow'), this.$t('widgets.cars_booked_after_tomorrow')],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#FFCE56', '#36A2EB', '#452345'],
            data: [0, 0, 0, 0, 0, 0]
          }
        ]
      },
      chartOptionsCarsAdminCalculation: {
        responsive: true,
        maintainAspectRatio: false
      },
      isLoadingContractors: false,
      showEndMaintenanceDialog: false,
      search_contractor: null,
      states: [
        { id: 1, state: this.$i18n.t("states.normal") },
        { id: 2, state: this.$i18n.t("states.in_service") },
        { id: 3, state: this.$i18n.t("states.critical") },
        { id: 4, state: this.$i18n.t("states.long_rent") },
        { id: 5, state: this.$i18n.t("states.no_rent") },
        { id: 6, state: this.$i18n.t("states.service_needed") },
      ],
      tire_types: [
        { id: 1, tire_type: this.$i18n.t("wheels.summer") },
        { id: 2, tire_type: this.$i18n.t("wheels.winter") },
        { id: 3, tire_type: this.$i18n.t("wheels.all_seasons") },
        { id: 4, tire_type: this.$i18n.t("wheels.both") },
        { id: 5, tire_type: this.$i18n.t("wheels.spike") },
      ],
      headers_for_cars: [
        { text: this.$i18n.t("custom.car"), value: "car_code", align: "center" },
        { text: this.$i18n.t("custom.state"), value: "state", align: "center" },
        {
          text: this.$i18n.t("custom.clean"),
          value: "clean_state",
          align: "center",
        },
        {
          text: this.$i18n.t("custom.tank"),
          value: "tank_state",
          align: "center",
        },
        {
          text: this.$i18n.t("custom.close_booking"),
          value: "start_date",
          align: "center",
        },
        {
          text: this.$i18n.t("custom.repairs"),
          sortable: false,
          value: "repairs",
          align: "center",
        },
        {
          text: this.$i18n.t("custom.local"),
          sortable: false,
          value: "store_place",
          align: "center",
        },
        {
          text: this.$i18n.t("to.to_short"),
          value: "last_inspection",
          align: "center",
        },
        {
          text: this.$i18n.t("wheels.tire"),
          value: "tire_type",
          align: "center",
        },
      ],
      headers: [
        {
          text: this.$i18n.t("custom.date"),
          sortable: false,
          value: "created_at",
          class: "text-xs-center",
        },
        {
          text: this.$i18n.t("custom.description"),
          sortable: false,
          value: "description",
          class: "text-xs-center",
        },
      ],
      bookings_late_end_date_headers: [
        { text: this.$t("custom.number"), value: "id", align: "center" },
        { text: this.$t("custom.car"), value: "car_code", align: "center" },
        {
          text: this.$t("custom.start"),
          value: "start_date",
          align: "center",
          sort: (a, b) => {
            return moment(a, "DD-MM-YYYY H:mm").diff(
              moment(b, "DD-MM-YYYY H:mm")
            );
          },
        },
        {
          text: this.$t("custom.end"),
          value: "end_date",
          align: "center",
          sort: (a, b) => {
            return moment(a, "DD-MM-YYYY H:mm").diff(
              moment(b, "DD-MM-YYYY H:mm")
            );
          },
        },
        { text: this.$t("bookings.payment"), align: "center" },
      ],
      personal_bookings_plan_headers: [
        { text: '', sortable: false, align: "center" },
        // { text: this.$t("custom.number"), value: "id", align: "center" },
        { text: this.$t("custom.car"), value: "car_code", align: "center" },
        {
          text: this.$t("custom.start"),
          value: "start_date",
          align: "center",
          sort: (a, b) => {
            return moment(a, "DD-MM-YYYY H:mm").diff(
              moment(b, "DD-MM-YYYY H:mm")
            );
          },
        },
        {
          text: this.$t("custom.end"),
          value: "end_date",
          align: "center",
          sort: (a, b) => {
            return moment(a, "DD-MM-YYYY H:mm").diff(
              moment(b, "DD-MM-YYYY H:mm")
            );
          },
        },
        { text: '', sortable: false, align: "center" },
      ],
      technical_bookings_headers: [
        { text: '', sortable: false, align: "center" },
        // { text: this.$t("custom.number"), value: "id", align: "center" },
        { text: this.$t("custom.car"), value: "car_code", align: "center" },
        {
          text: this.$t("custom.start"),
          value: "start_date",
          align: "center",
          sort: (a, b) => {
            return moment(a, "DD-MM-YYYY H:mm").diff(
              moment(b, "DD-MM-YYYY H:mm")
            );
          },
        },
        {
          text: this.$t("custom.end"),
          value: "end_date",
          align: "center",
          sort: (a, b) => {
            return moment(a, "DD-MM-YYYY H:mm").diff(
              moment(b, "DD-MM-YYYY H:mm")
            );
          },
        },
        { text: '', sortable: false, align: "center" },
      ],
      fines_sale_end_headers: [
        { text: this.$t("fines.number"), align: "center", value: "number" },
        {
          text: this.$t("fines.sale_until"),
          value: "discount_date",
          align: "center",
        },
        { text: this.$t("fines.article"), value: "article", align: "center" },
        { text: this.$t("fines.state"), value: "state", align: "center" },
      ],
      updatePlaceDialog: false,
      inspection_date: null,
      inspection_date_format: null,
      inspection_date_menu: false,
      inspection_description: null,
      inspection_dialog: false,
      car_id: null,
      store_place: null,
      dialog_oil_engine: false,
      dialog_oil_transmission: false,
      dialog_grm: false,
      dialog_antifreeze: false,
      dialog_brake: false,
      dialog_candle: false,
      dialog_power_steering: false,
      dialog_salon_filter: false,
      dialog_akb: false,
      dialog_fuel_filter: false,
      showCurrentPositionDialog: false,
      showNewWidgetDialog: false,
      latitude: null,
      longitude: null,
      timeout: null,
      isMobile: false,
      isLoading: false,
      error: "",
      showGrid: false,
      layoutConfig: {
        isDraggable: false,
        isResizable: false,
      },
      isMobileMoving: false, // Добавляем флаг для мобильного перетаскивания
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
    this.getData();
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      if (this.$i18n.locale == "ru") {
        moment.locale("ru");
      } else {
        moment.locale("en-gb");
      }
      console.info(
        `App version ${this.$store.getters.appVersion} | ${process.env.VUE_APP_VERSION} | ${process.env.VUE_APP_ENV_VERSION}`
      );
    } else {
      this.$store.commit("unsetCurrentUser");
      // this.$router.push({ path: `/signin` }).catch(err => {});
    }
  },
  watch: {
    inspection_date() {
      if (this.inspection_date) {
        this.inspection_date_format = moment(
          this.inspection_date,
          "YYYY-MM-DD"
        ).format("DD-MM-YYYY");
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    current_user() {
      return this.$store.getters.getCurrentUser;
    },
    show_repairs() {
      return this.$store.getters.getUserSettings.show_repair_on_main;
    },
    show_main_table() {
      return this.$store.getters.getUserSettings.show_main_table;
    },
    show_store_place() {
      return this.$store.getters.getUserSettings.show_store_place_on_main;
    },
    show_tire() {
      return this.$store.getters.getUserSettings.show_tire_on_main;
    },
    show_to() {
      return this.$store.getters.getUserSettings.show_to_on_main;
    },
    ygibdd_active() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.ygibdd_active
      ) {
        return true;
      } else {
        return false;
      }
    },
    filteredCarsHeaders() {
      return this.headers_for_cars.filter((header) => {
        if (header.value == "store_place") {
          return this.show_store_place;
        } else if (header.value == "repairs") {
          return this.show_repairs;
        } else if (header.value == "tire_type") {
          return this.show_tire;
        } else if (header.value == "last_inspection") {
          return this.show_to;
        } else {
          return true;
        }
      });
    },
    company() {
      return this.$store.getters.getCompany.company;
    },
    main_company() {
      return this.$store.getters.getMainCompany;
    },
    user_exists_in_traccar() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.traccar_id != null;
      } else {
        return false;
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    computedOptions() {
      if (!this.widgets || !Array.isArray(this.widgets)) {
        return [];
      }
      return this.widgets.map(widget => ({
        ...widget,
        i: widget.i ? widget.i.toString() : widget.id ? widget.id.toString() : '0',
        static: false // Гарантируем, что виджеты можно перемещать
      }));
    },
    gridLayoutOptions() {
      return {
        isDraggable: this.layoutConfig.isDraggable || this.isMobileMoving,
        isResizable: this.layoutConfig.isResizable,
        useCssTransforms: true,
        verticalCompact: true,
        margin: [10, 10],
        colNum: this.isMobile || this.isNative ? 12 : 12,
        rowHeight: 30,
        mobileBreakPoint: 600,
        // Добавляем поддержку тач-событий
        draggableCancel: '',
        draggableHandle: '',
        touchAction: 'none'
      };
    }
  },
  methods: {
    getData() {
      let self = this;
      this.isLoading = true;
      this.axios
        .get(`/api/v1/dashboard_data?mobile=${this.isMobile || this.isNative}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.bookings_for_now = response.data.bookings_for_now;
          this.active_bookings_count = response.data.active_bookings_count;
          this.free_cars_count = response.data.free_cars_count;
          this.free_cars_start_dates = response.data.free_cars_start_dates;
          this.bookings_yesterday_count =
            response.data.bookings_yesterday_count;
          this.clients_count = response.data.clients_count;
          this.widgets = response.data.widgets;
          // удаляем отключенные на мобильном виджеты
          if ((this.isMobile || this.isNative) && this.widgets && this.widgets.length > 0) {
            this.widgets = this.widgets.filter((widget) => {
              return widget.on_mobile;
            });
            // расставляем виджеты друг под другом
            this.widgets.forEach((widget) => {
              // find index of widget
              let index = this.widgets.indexOf(widget);
              if (index == 0) {
                // set widget position on top
                widget.x = 0
                widget.y = 0
              }
              // find previous widget
              let prev_widget = this.widgets[index - 1];
              // if widget is not first
              if (index > 0) {
                // set widget position under previous widget
                widget.x = 0
                widget.y = prev_widget.y + prev_widget.h;
                console.log(widget.y, prev_widget.y, prev_widget.h)
              }
            })
          }
          this.bookings_late_end_date = response.data.bookings_late_end_date;
          this.bookings_late_end_date.forEach((item) => {
            item.counts = [];
          })
          this.bookings_late_end_date_counts = response.data.bookings_late_end_date_counts;
          if (this.bookings_late_end_date_counts && this.bookings_late_end_date_counts.length > 0) {
            this.bookings_late_end_date_counts.forEach((item) => {
              this.bookings_late_end_date.find((booking) => {
                if (booking.id == item.booking_id) {
                  booking.counts.push(item)
                }
              })
            })
          }
          // fines_sale_end widget
          this.fines_sale_end = response.data.fines_sale_end;
          // personal_bookings_plan widget
          this.personal_bookings_plan = response.data.personal_bookings_plan;
          // Добавляю время для сортировки
          this.personal_bookings_plan.forEach((booking) => {
            if (!this.selectBookingState(booking)) {
              booking.time = moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                "H:mm"
              );
            } else {
              booking.time = moment(
                booking.start_date,
                "DD-MM-YYYY H:mm"
              ).format("H:mm");
            }
          });
          // сортирую по времени
          this.personal_bookings_plan.sort(function (x1, x2) {
            if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
              return -1;
            if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
              return 1;
            return 0;
          });
          // technical_bookings widget
          this.technical_bookings = response.data.technical_bookings;
          // Добавляю время для сортировки
          this.technical_bookings.forEach((booking) => {
            if (!this.selectBookingState(booking)) {
              booking.time = moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                "H:mm"
              );
            } else {
              booking.time = moment(
                booking.start_date,
                "DD-MM-YYYY H:mm"
              ).format("H:mm");
            }
          });
          // сортирую по времени
          this.technical_bookings.sort(function (x1, x2) {
            if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
              return -1;
            if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
              return 1;
            return 0;
          });
          // schedule widget
          this.schedule_events = [];
          response.data.schedule_events.forEach((event) => {
            // push if not exist event.date in schedule_events
            if (!this.schedule_events.find((item) => moment(item.start).isSame(moment(event.date, "DD-MM-YYYY"), 'day'))) {
              self.schedule_events.push({
                id: event.id,
                name: self.translateScheduleEvent(event.category),
                start: moment(event.date, "DD-MM-YYYY").toDate(),
                end: moment(event.date, "DD-MM-YYYY").toDate(),
                color: self.selectColorScheduleEvent(event.category),
                timed: false,
              })
            }
          })
          // work_now widget
          this.work_now = response.data.work_now;
          // bookings_month widget
          let bookings_previous_month_data = response.data.bookings_previous_month;
          let bookings_last_month_data = response.data.bookings_last_month;
          // вычитаем месяц из массива предыдущего месяца чтобы сравнить с текущим
          bookings_previous_month_data = Object.keys(bookings_previous_month_data).reduce((object, key) => {
            let new_key = moment(key, "YYYY-MM-DD").subtract(1, 'month').format("YYYY-MM-DD");
            object[new_key] = bookings_previous_month_data[key];
            return object
          }, {})
          this.chartDataBookingsMonth.datasets[1].data = Object.values(bookings_previous_month_data);
          this.chartDataBookingsMonth.datasets[0].data = Object.values(bookings_last_month_data);
          bookings_last_month_data = response.data.bookings_last_month;
          bookings_last_month_data = Object.keys(bookings_last_month_data).reduce((object, key) => {
            let new_key = moment(key, "YYYY-MM-DD").format("DD ddd");
            object[new_key] = bookings_last_month_data[key];
            return object
          }, {})

          this.chartDataBookingsMonth.labels = Object.keys(bookings_last_month_data);
          // cars_statuses widget
          this.cars_statuses = response.data.cars_statuses;
          this.chartDataCarsStatuses.labels = this.cars_statuses.map((item) => this.translateCarsStatus(item.state));
          this.chartDataCarsStatuses.datasets[0].data = this.cars_statuses.map((item) => item.count);
          // return_deposits widget
          this.return_deposits = response.data.return_deposits;
          // cars_admin_calculation widget
          this.cars_admin_calculation = response.data.cars_admin_calculation;
          this.chartDataCarsAdminCalculation.datasets[0].data = Object.values(this.cars_admin_calculation);
          this.chartDataCarsAdminCalculation.labels.forEach((label, index) => {
            this.chartDataCarsAdminCalculation.labels[index] += ` (${this.chartDataCarsAdminCalculation.datasets[0].data[index]})`
          })
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.dashboard"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    translateScheduleEvent(category) {
      switch (category) {
        case 'work':
          return this.$t('schedules.work')
          break;
        case 'duty':
          return this.$t("schedules.duty")
          break;
        case 'duty_night':
          return this.$t("schedules.duty_night")
          break;
        case 'vacation':
          return this.$t("schedules.vacation")
          break;
        case 'vacation_no_salary':
          return this.$t("schedules.vacation_no_salary")
          break;
        case 'sick':
          return this.$t("schedules.sick_day")
          break;
        default:
          return '?';
      }
    },
    selectColorScheduleEvent(category) {
      switch (category) {
        case 'work':
          return 'success'
          break;
        case 'duty':
          return 'warning'
          break;
        case 'duty_night':
          return 'deep-purple'
          break;
        case 'vacation':
          return 'info'
          break;
        case 'vacation_no_salary':
          return 'yellow darken-1'
          break;
        case 'sick':
          return 'secondary'
          break;
        default:
          return '';
      }
    },
    checkWidget(widget_type) {
      // проверяем подключены ли штрафы
      if (widget_type == 'fines_sale_end' && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.ygibdd_active == false) {
        return true;
      } else {
        return this.widgets.find((widget) => widget.widget_type == widget_type) != undefined;
      }
    },
    getWidgetDimensions(widget_type) {
      switch (widget_type) {
        case "active_bookings_size":
        case "yesterday_bookings_size":
        case "car_on_parking":
        case "clients_size":
          return { w: 3, h: 3 };

        case "bookings_late_end_date":
        case "fines_sale_end":
        case "personal_bookings_plan":
        case "technical_bookings":
        case "schedule":
          return { w: 6, h: 10 };

        case "return_deposits":
          return { w: 3, h: 7 };

        case "work_now":
        case "cars_statuses":
        case "cars_admin_calculation":
          return { w: 3, h: 10 };

        case "bookings_month":
          return { w: 4, h: 10 };

        default:
          return { w: 3, h: 3 }; // Значения по умолчанию
      }
    },
    addWidget(widget_type) {
      this.showGrid = true;
      this.layoutConfig.isDraggable = true;
      this.layoutConfig.isResizable = true;
      this.editWidgets = true;

      let maxY = 0;
      this.widgets.forEach(widget => {
        const bottomY = widget.y + widget.h;
        if (bottomY > maxY) {
          maxY = bottomY;
        }
      });

      const dimensions = this.getWidgetDimensions(widget_type);

      let newWidget = {
        i: this.widgets.length.toString(),
        widget_type: widget_type,
        static: false,
        on_mobile: this.isMobile || this.isNative
      };

      if (this.isMobile || this.isNative) {
        Object.assign(newWidget, {
          x: 0,
          y: maxY,
          w: 12, // На мобильных всегда во всю ширину
          h: dimensions.h
        });
      } else {
        let x = 0;
        if (this.widgets.length > 0) {
          const lastWidget = this.widgets[this.widgets.length - 1];
          x = (lastWidget.x + lastWidget.w) % 12; // Учитываем ширину предыдущего виджета
          if (x + dimensions.w > 12) { // Если виджет не помещается в строку
            x = 0;
            maxY = Math.floor(maxY / dimensions.h) * dimensions.h + dimensions.h;
          }
        }

        Object.assign(newWidget, {
          x: x,
          y: maxY,
          w: dimensions.w,
          h: dimensions.h
        });
      }

      this.widgets.push(newWidget);
      this.$nextTick(() => {
        this.saveWidgets();
      });
    },
    isSpaceFree(x, y, w, h) {
      // Проверяем, не пересекается ли новый виджет с существующими
      return !this.widgets.some(widget => {
        return !(x + w <= widget.x ||
                 x >= widget.x + widget.w ||
                 y + h <= widget.y ||
                 y >= widget.y + widget.h);
      });
    },
    updateUserSetting() {
      this.isLoading = true;
      this.axios
        .patch(
          `/api/v1/update_user_setting`,
          {
            user_setting: {
              show_main_table: !this.show_main_table,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.$store.commit("setUserSettings", response.data);
          this.getData();
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("users.profile_updated"),
            text: this.$t("users.profile_updated_text"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.profile_update"));
        })
        .finally(() => (this.isLoading = false));
    },
    removeWidget(val) {
      this.axios
        .delete(
          `/api/v1/dashboard_widgets/${val}`,
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then(() => {
          const index = this.widgets.map(item => item.i).indexOf(val);
          this.widgets.splice(index, 1);
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
    },
    makeWidgetsDontStatic() {
      this.widgets.forEach((widget) => {
        widget.static = false;
      });
    },
    makeWidgetsStatic() {
      this.widgets.forEach((widget) => {
        widget.static = true;
      });
    },
    updateWidget(i, newX, newY) {
      this.axios
        .patch(
          `/api/v1/dashboard_widgets/${i}`,
          {
            dashboard_widgets: {
              x: newX,
              y: newY,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          console.log(i, "newX", newX, "newY", newY, this.widgets)
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
    },
    upWidget(widget) {
      this.axios
        .patch(
          `/api/v1/dashboard_widgets/${widget.i}`,
          {
            dashboard_widgets: {
              // x: widget.x + 1,
              y: widget.y > 1 ? widget.y - 1 : 0,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.widgets.find((item) => item.i == widget.i).y = widget.y > 1 ? widget.y - 1 : 0;
          console.log(response.data)
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
    },
    showWidgetOnMobile(i, on_mobile) {
      this.axios
        .patch(
          `/api/v1/dashboard_widgets/${i}`,
          {
            dashboard_widgets: {
              on_mobile: on_mobile,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          console.log(this.widgets)
          // this.widgets.find((widget) => widget.id == i).on_mobile = on_mobile;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("custom.updated"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
    },
    updateWidgetHeight(i, newH, newW, newHPx, newWPx) {
      // убрал h: newH,
      this.axios
        .patch(
          `/api/v1/dashboard_widgets/${i}`,
          {
            dashboard_widgets: {
              w: newW,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          console.log(i, newH, newW, newHPx, newWPx)
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
    },
    moveEventWidget(i, newX, newY) {
      let self = this;
      function debounce(i, newX, newY) {
        if (self.timeout) clearTimeout(self.timeout);

        self.timeout = setTimeout(() => {
          // your action
          self.updateWidget(i, newX, newY);
        }, 300); // delay
      }
      debounce(i, newX, newY);
    },
    resizedEventWidget(i, newH, newW, newHPx, newWPx) {
      console.log(i, newH, newW, newHPx, newWPx)
      let self = this;
      function debounce(i, newH, newW, newHPx, newWPx) {
        if (self.timeout) clearTimeout(self.timeout);

        self.timeout = setTimeout(() => {
          // your action
          self.updateWidgetHeight(i, newH, newW, newHPx, newWPx);
        }, 300); // delay
      }
      debounce(i, newH, newW, newHPx, newWPx);
    },
    inspectionSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        var self = this;
        this.axios
          .post(
            `/api/v1/cars/${this.car_id}/inspections`,
            {
              inspections: {
                description: this.inspection_description,
                date: this.inspection_date,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let index = this.free_cars_start_dates.findIndex(
              (car) => car.car_id == this.car_id
            );
            this.free_cars_start_dates[index].last_inspection =
              this.inspection_date;
            this.inspection_dialog = false;
            this.inspection_date = null;
            this.inspection_date_menu = false;
            this.inspection_description = null;
            this.car_id = null;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("to.success_title"),
              text: this.$t("to.success_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.update_to"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.update_to"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    voteUp(user) {
      if (this.current_user && this.current_user.id != user.id) {
        var self = this;
        this.axios
          .post(
            `/api/v1/employee_vote_up`,
            {
              id: user.id,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.work_now.find((employee) => employee.id == user.id).vote_up = parseInt(response.data);
          })
          .catch((error) => {
            this.setError(error,);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.error"),
              text: this.error,
            });
          })
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    voteDown(user) {
      if (this.current_user && this.current_user.id != user.id) {
        var self = this;
        this.axios
          .post(
            `/api/v1/employee_vote_down`,
            {
              id: user.id,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.work_now.find((employee) => employee.id == user.id).vote_down = parseInt(response.data);
          })
          .catch((error) => {
            this.setError(error,);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.error"),
              text: this.error,
            });
          })
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    storePlaceSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        var self = this;
        this.axios
          .patch(
            `/api/v1/cars/${this.car_id}`,
            {
              cars: {
                store_place: this.store_place,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let index = this.free_cars_start_dates.findIndex(
              (car) => car.car_id == this.car_id
            );
            this.free_cars_start_dates[index].store_place = this.store_place;
            this.car_id = null;
            this.store_place = null;
            this.updatePlaceDialog = false;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("cars.location_car_updated"),
            });
          })
          .catch((error) => {
            this.setError(error, "Ошибка при обновлении местонахождения авто!");
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: "Ошибка при обновлении местонахождения авто!",
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateCarState(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let car = this.free_cars_start_dates.find((car) => car.car_id == id);
        if (car) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/cars/${id}`,
              {
                cars: {
                  state: state,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.free_cars_start_dates.find((car) => car.car_id == id).state =
                state;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("cars.change_state"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.car_state_update"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.car_state_update"),
            text: this.$t("errors.car_not_found"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateTireType(id, tire_type) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (id) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/cars/${id}`,
              {
                cars: {
                  tire_type: tire_type,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.free_cars_start_dates.find(
                (car) => car.car_id == id
              ).tire_type = tire_type;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.change_car_tire_type"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateCleanState(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/cars/${id}`,
            {
              cars: {
                clean_state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.free_cars_start_dates.find(
              (car) => car.car_id == id
            ).clean_state = state;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.car_state_update"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateTankState(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/cars/${id}`,
            {
              cars: {
                tank_state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.free_cars_start_dates.find(
              (car) => car.car_id == id
            ).tank_state = state;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.car_state_update"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveEndMaintenance() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let car = this.free_cars_start_dates.find((car) => car.car_id == this.maintenance.car_id);
        if (car) {
          this.isLoading = true;
          this.axios
            .post(`/api/v1/end_maintenance/${this.maintenance.id}`,
              {
                maintenance: {
                  completed: true,
                  date_completed: moment().format("DD-MM-YYYY"),
                  mileage_completed: car.mileage,
                  whois_ended: this.$store.getters.getCurrentUser.id,
                  description: this.maintenance.description,
                  last_maintenanced_date: this.maintenance.last_maintenanced_date,
                  contractor_id: this.maintenance.contractor_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
            .then((response) => {
              // remove maintenance from array
              let car = this.free_cars_start_dates.find((car) => car.car_id == this.maintenance.car_id);
              let current_maintenances = car.maintenances
              current_maintenances.splice(current_maintenances.indexOf(this.maintenance), 1);
              car.maintenances = current_maintenances;
              this.free_cars_start_dates.splice(this.free_cars_start_dates.indexOf(car), 1, car);
              this.showEndMaintenanceDialog = false;
              this.maintenance = {
                maintenance_type: 0,
                title: null,
                description: null,
                mileage_step: 0,
                days_step: 0,
                last_maintenanced_date: moment().format("YYYY-MM-DD"),
                notify_before: 0,
                criticality: 0,
                responsible: [],
                assign_to_all_cars: true,
                car_id: null,
                car_ids: [],
                start_insurance_date: null,
                end_insurance_date: null,
              };
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.error"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.error"),
            text: this.$t("errors.error"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveEndInsurance() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if ((this.maintenance.start_insurance_date && this.maintenance.end_insurance_date) || this.maintenance.periodic == false) {
          let car = this.free_cars_start_dates.find((car) => car.id == this.maintenance.car_id);
          if (car) {
            this.isLoading = true;
            this.axios
              .post(`/api/v1/end_insurance/${this.maintenance.id}`,
                {
                  maintenance: {
                    description: this.maintenance.description,
                    days_step: moment(this.maintenance.end_insurance_date).diff(moment(this.maintenance.start_insurance_date), "days") + 1,
                    last_maintenanced_date: this.maintenance.start_insurance_date ? this.maintenance.start_insurance_date : moment().format("YYYY-MM-DD"),
                    contractor_id: this.maintenance.contractor_id,
                  },
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                })
              .then((response) => {
                // remove maintenance from array
                let car = this.free_cars_start_dates.find((car) => car.car_id == this.maintenance.car_id);
                let current_maintenances = car.maintenances
                current_maintenances.splice(current_maintenances.indexOf(this.maintenance), 1);
                car.maintenances = current_maintenances;
                this.free_cars_start_dates.splice(this.free_cars_start_dates.indexOf(car), 1, car);
                this.showEndMaintenanceDialog = false;
                this.maintenance = {
                  maintenance_type: 0,
                  title: null,
                  description: null,
                  mileage_step: 0,
                  days_step: 0,
                  last_maintenanced_date: moment().format("YYYY-MM-DD"),
                  notify_before: 0,
                  criticality: 0,
                  responsible: [],
                  assign_to_all_cars: true,
                  car_id: null,
                  car_ids: [],
                  start_insurance_date: null,
                  end_insurance_date: null,
                };
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.error"));
                console.log(error);
              })
              .finally(() => (this.isLoading = false));
          } else {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "warning",
              title: this.$t("errors.error"),
              text: this.$t("errors.error"),
            });
          }
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("custom.no_data"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    showEndMaintenance() {
      if (this.maintenance.maintenance_type == 4 || this.maintenance.maintenance_type == 5) {
        this.maintenance.start_insurance_date = moment(this.maintenance.last_maintenanced_date).add(this.maintenance.days_step, "days").format("YYYY-MM-DD");
      }
      this.showEndMaintenanceDialog = true;
    },
    returnEndMaintenanceTitle(maintenance) {
      if (maintenance.maintenance_type == 4) {
        return this.$t("maintenance.end_insurance")
      } else if (maintenance.maintenance_type == 5) {
        return this.$t("maintenance.end_payment")
      } else {
        return this.$t("maintenance.end_maintenance")
      }
    },
    loadContractors() {
      this.isLoadingContractors = true;
      this.axios
        .get(
          `/api/v1/contractors`,
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          response.data.data.forEach((element) => {
            this.contractors.push(element.attributes);
          });
        })
        .catch((error) => {
          this.isLoadingContractors = false;
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoadingContractors = false));
    },
    calculatePayments(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
          if (count.completed) {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return self.$t("bookings.paid");
        } else {
          return paid - booking_sum >= 0
            ? `+ ${Math.round(paid - booking_sum)}`
            : ` ${Math.round(paid - booking_sum)}`;
        }
      }
    },
    selectColorPaid(booking) {
      if (booking != undefined) {
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
          if (count.completed) {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return "success";
        } else if (paid - booking_sum > 0) {
          return "warning";
        } else if (paid - booking_sum < 0) {
          return "error";
        }
      }
    },
    checkMaintenanceIcon(maintenance) {
      function checkDate(maintenance) {
        if (moment(maintenance.last_maintenanced_date).add(maintenance.days_step, 'days').diff(moment(), 'days') <= 0) {
          return true;
        } else {
          return false;
        }
      }
      if (this.isMobile) {
        return false;
      } else {
        if (maintenance.periodic) {
          if (maintenance.last_maintenanced_date && maintenance.days_step && checkDate(maintenance)) {
            return "mdi-alert-box";
          } else {
            return "mdi-all-inclusive-box-outline";
          }
        } else {
          if (maintenance.last_maintenanced_date && maintenance.days_step && checkDate(maintenance)) {
            return "mdi-alert-box";
          } else {
            return "mdi-numeric-1-box-outline";
          }
        }
      }
    },
    getCurrentPosition(traccar_id) {
      this.isLoading = true;
      this.latitude = null;
      this.longitude = null;

      let self = this
      let email = this.$store.getters.getCurrentUser.email;
      let traccar_password = this.$store.getters.getCurrentUser.traccar_password;
      let data = new URLSearchParams({
        "email": email,
        "password": traccar_password
      })
      // authorization in socket
      let session_url = "https://vos-rentprog.ru/api/session"
      let new_session_resp = this.axios({
        method: "post",
        url: session_url,
        headers: {
          "Authorization": "Basic " + btoa(`${email}:${traccar_password}`),
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: data
      })
        .then(response => {
          let position_url = `https://vos-rentprog.ru/api/positions?deviceId=${traccar_id}`
          this.axios({
            method: "get",
            url: position_url,
            headers: {
              "Authorization": "Basic " + btoa(`${email}:${traccar_password}`),
              "Content-Type": "application/x-www-form-urlencoded"
            },
          })
            .then(position_response => {
              console.log(position_response.data)
              this.latitude = position_response.data[0].latitude
              this.longitude = position_response.data[0].longitude
              this.showCurrentPositionDialog = true;
            })
            .catch(error => {
              this.isLoading = false;
              this.setError(error, this.$t('errors.beacon_load'));
              console.log(error);
            })
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error)
        })
        .finally(() => (this.isLoading = false));
    },
    selectCriticalityClass(criticality) {
      if (criticality == 0) {
        return "secondary white--text";
      } else if (criticality == 1) {
        return "warning white--text";
      } else if (criticality == 2) {
        return "error white--text";
      }
    },
    repairEnd(repair) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.$swal({
          title: this.$t("services.repair_ended"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("services.repair_ended_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/cars/${repair.car_id}/repairs/${repair.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                let repairs = self.free_cars_start_dates.find(
                  (car) => car.car_id === repair.car_id
                ).repairs;
                let index = repairs.indexOf(repair);
                if (index !== -1) {
                  repairs.splice(index, 1);
                }
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.repair_delete"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("services.repair_ended_title"),
              text: this.$t("services.repair_ended_text"),
            });
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` }).catch((err) => { });
    },
    getCloseDate(id) {
      let start_date = this.free_cars_start_dates.find(
        (car) => car.car_id == id
      );
      if (start_date) {
        return start_date.start_date;
      }
    },
    formatedDates(date) {
      return moment(date, "YYYY-MM-DD H:mm").format("H:mm, DD.MM.YYYY");
    },
    formatedDatesLastInspection(date) {
      return moment(date, "YYYY-MM-DD").format("DD MMM");
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm");
    },
    formatBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD MMM H:mm");
    },
    formatLastMaintenancedDate(date) {
      if (date) {
        return moment(date, "YYYY-MM-DD").format("ll");
      } else {
        return null;
      }
    },
    formatDateBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD MMM");
    },
    formatTimeBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("H:mm");
    },
    checkLateDate(date) {
      if (!moment().isSameOrBefore(moment(date, "DD-MM-YYYY H:mm"), "day")) {
        return "color: tomato;";
      }
    },
    selectBookingState(booking) {
      if (
        moment(booking.start_date, "DD-MM-YYYY H:mm").isSame(
          moment(booking.end_date, "DD-MM-YYYY H:mm"),
          "days"
        ) &&
        booking.in_rent == true
      ) {
        // Бронь однодневка, чтобы не показывать когда выдана в выдаче, а показывать в приеме
        return false;
      } else if (
        // выдача
        moment(booking.start_date, "DD-MM-YYYY H:mm").isSame(
          moment(this.sort_date),
          "days"
        ) &&
        booking.ride == false
      ) {
        return true;
      } else {
        // прием
        return false;
      }
    },
    translateFinesState(state) {
      let state_text = "";
      if (state == "Создан") {
        state_text = this.$t("fines_state.created");
      }
      if (state == "Оповещён") {
        state_text = this.$t("fines_state.notify");
      }
      if (state == "Оплачен") {
        state_text = this.$t("fines_state.paid");
      }
      if (state == "Возмещён") {
        state_text = this.$t("fines_state.refund");
      }
      return state_text;
    },
    translateCarsStatus(status) {
      let status_text = "";
      if (status == 1) {
        status_text = this.$t("states.normal");
      }
      if (status == 2) {
        status_text = this.$t("states.in_service");
      }
      if (status == 3) {
        status_text = this.$t("states.critical");
      }
      if (status == 4) {
        status_text = this.$t("states.long_rent");
      }
      if (status == 5) {
        status_text = this.$t("states.no_rent");
      }
      if (status == 6) {
        status_text = this.$t("states.service_needed");
      }
      return status_text;
    },
    stateFineColor(state) {
      if (state == "Оповещён") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "red";
      }
      if (state == "Возмещён") {
        return "success";
      }
    },
    showSale(date) {
      if (moment().isAfter(moment(date), "day")) {
        return false;
      } else {
        return true;
      }
    },
    replaceToHistory() {
      this.$router.push({ path: `/history` }).catch((err) => { });
    },
    checkLastDaySale(date) {
      if (moment().isSame(moment(date), "day")) {
        return true;
      } else {
        return false;
      }
    },
    formatCreatedAtNoTime(date) {
      if (date && date != "0001-01-01") {
        return moment.parseZone(date).format("DD MMM");
        // return date
      } else {
        return "-";
      }
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    saveWidgets() {
      if (this.isLoading) return;

      this.isLoading = true;
      const newWidget = this.widgets[this.widgets.length - 1];

      this.axios
        .post(
          `/api/v1/dashboard_widgets`,
          {
            dashboard_widgets: {
              widget_type: newWidget.widget_type,
              x: newWidget.x,
              y: newWidget.y,
              w: newWidget.w,
              h: newWidget.h,
              on_mobile: this.isMobile || this.isNative
            }
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          const createdWidget = response.data;
          this.$set(this.widgets[this.widgets.length - 1], 'id', createdWidget.id);
          this.$set(this.widgets[this.widgets.length - 1], 'i', createdWidget.id.toString());

          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("custom.updated"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    layoutUpdated(newLayout) {
      if (!newLayout || !Array.isArray(newLayout)) return;

      // Используем Vue.set для реактивного обновления
      newLayout.forEach((item, index) => {
        if (this.widgets[index]) {
          this.$set(this.widgets[index], 'x', item.x);
          this.$set(this.widgets[index], 'y', item.y);
        }
      });
    },
  },
  components: {
    CurrentPosition,
    GridLayout,
    GridItem,
    Bar,
    Pie,
    Doughnut
  },
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}

.toolbar {
  background-color: white;
}

.is-mobile .v-data-footer__select {
  margin-right: 0 !important;
}

.is-mobile .v-data-footer__icons-before {
  margin: 0 auto !important;
}

.is-mobile .v-data-footer__icons-after {
  margin: 0 auto !important;
}

/* vue-grid-layout */
/* .grid::before {
    content: '';
    background-size: calc(calc(100% - 5px) / 12) 40px;
    background-image: linear-gradient(
            to right,
            lightgrey 1px,
            transparent 1px
    ),
    linear-gradient(to bottom, lightgrey 1px, transparent 1px);
    height: calc(100% - 5px);
    width: calc(100% - 5px);
    position: absolute;
    background-repeat: repeat;
    margin:5px;
} */
/* .vue-grid-layout {
    background: #eee;
} */

.edit-widget.vue-grid-layout::before {
  content: '';
  background-size: calc(calc(100% - 5px) / 12) 40px;
  background-image: linear-gradient(to right,
      lightgrey 1px,
      transparent 1px),
    linear-gradient(to bottom, lightgrey 1px, transparent 1px);
  height: calc(100% - 5px);
  width: calc(100% - 5px);
  position: absolute;
  background-repeat: repeat;
  margin: 5px;
}


.vue-grid-item:not(.vue-grid-placeholder) {
  /* background: #ccc;
    border: 1px solid black; */
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

/* .vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
} */

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.light-theme.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

.dark-theme.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#000'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

.remove {
  position: absolute;
  right: 4px;
  top: 0;
  cursor: pointer;
}

.widget_up {
  position: absolute;
  right: 30px;
  top: 0;
  cursor: pointer;
}
</style>
