<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <!-- <v-card-title>
        <h2>{{ $t("custom.branches") }}</h2>
      </v-card-title> -->
      <v-card-text>
        <v-card>
          <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('custom.search')"
              single-line
              clearable
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn small color="success" @click="newBranchDialog = true">{{
              $t("custom.new_branch")
            }}</v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="branches"
            :search="search"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :items-per-page="20"
            mobile-breakpoint="100"
            :class="!isMobile ? '' : 'is-mobile'"
            :sort-by="['name']"
            :sort-desc="[true]"
            :footer-props="{
              pageText: `{0} ${$t('custom.of')} {1}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [20, 50, 100, -1],
            }"
          >
            <template slot="item" slot-scope="props">
              <tr :class="props.item.id == company.id ? 'success' : ''">
                <td class="text-center">
                  {{ props.item.id }}
                </td>
                <td class="text-center">
                  {{ props.item.name }}
                </td>
                <td class="text-center">
                  {{ props.item.country }}
                </td>
                <td class="text-center">
                  {{ props.item.city }}
                </td>
                <td class="text-center">
                  {{ props.item.address }}
                </td>
                <td class="text-center">
                  <v-btn
                    v-if="props.item.id != company.id"
                    color="warning"
                    @click="selectBranch(props.item.id)"
                  >
                    {{ $t("custom.to_branch") }} ->
                  </v-btn>
                </td>
                <td class="text-center">
                  <!-- <v-icon class="mr-2" @click="editBranch(props.item.id)">
                    mdi-pencil-outline
                  </v-icon> -->
                  <v-icon
                    v-if="props.item.id != company.id"
                    color="red"
                    @click="deleteItem(props.item)"
                  >
                    mdi-delete-forever
                  </v-icon>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              {{ $t("custom.no_data_in_table") }}
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
            >
              {{ $t("tables.no_search_result", { msg: search }) }}
            </v-alert>
          </v-data-table>
          <v-flex class="text-center">
            <v-btn
              class="mb-2"
              color="error"
              small
              @click="deleteMainCompany()"
              >{{ $t("companies.remove_all_info") }}</v-btn
            >
          </v-flex>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="newBranchDialog"
      :retain-focus="false"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <h3 class="text-center w-full">
            {{ $t("custom.creating_branch") }}
            <br v-if="$store.getters.getMainCompany.plan && $store.getters.getMainCompany.plan.branches_limit > 0"><span v-if="$store.getters.getMainCompany.plan && $store.getters.getMainCompany.plan.branches_limit > 0" class="text-subtitle-1"><span :class="$store.getters.getMainCompany.companies_count >= $store.getters.getMainCompany.plan.branches_limit ? 'error--text' : ''">{{ $store.getters.getMainCompany.companies_count }}</span>/{{ $store.getters.getMainCompany.plan.branches_limit }}</span>
          </h3>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-text-field
            v-model.trim="branch.name"
            :label="$t('users.company_name')"
            :placeholder="$t('users.company_name_placeholder')"
          ></v-text-field>
          <v-autocomplete
            :label="$t('users.country')"
            v-model="branch.country"
            :items="countries"
            item-text="both"
            item-value="name"
          >
          </v-autocomplete>
          <v-text-field
            v-model.trim="branch.city"
            :label="$t('users.city')"
            :placeholder="$t('users.city_placeholder')"
          ></v-text-field>
          <v-text-field
            v-model.trim="branch.address"
            :label="$t('users.address')"
            :placeholder="$t('users.address_plc')"
          ></v-text-field>
          <v-autocomplete
            v-model="branch.locale"
            item-text="text"
            item-value="value"
            :items="locales"
            :label="$t('companies.notification_locale')"
            :placeholder="$t('companies.locale_plc')"
          ></v-autocomplete>
          <v-autocomplete
            v-model="branch.time_zone"
            item-text="text"
            item-value="value"
            :items="time_zones"
            :label="$t('users.time_zone')"
            :placeholder="$t('users.time_zone_placeholder')"
          ></v-autocomplete>
          <v-text-field
            v-model.trim="branch.phone"
            :label="$t('users.phone')"
            :placeholder="$t('users.phone_placeholder')"
          ></v-text-field>
          <v-text-field
            v-model="branch.email"
            :label="$t('users.email')"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="newBranchDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="submit()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { countries } from "./../countries";
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
// moment.locale("ru");
export default {
  data() {
    return {
      branches: [],
      countries: countries,
      branch: {
        name: null,
        country: "Россия",
        city: null,
        address: null,
        phone: null,
        email: null,
        time_zone: "Europe/Moscow",
        locale: "ru",
      },
      headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.company_name"),
          value: "name",
          align: "center",
        },
        { text: this.$t("custom.country"), value: "country", align: "center" },
        { text: this.$t("custom.city"), value: "city", align: "center" },
        {
          text: this.$t("custom.address"),
          sortable: false,
          value: "address",
          align: "center",
        },
        {
          text: this.$t("custom.to_branch_2"),
          sortable: false,
          align: "center",
        },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
      ],
      time_zones: [
        { text: "(GMT-12) International Date Line West", value: "Etc/GMT+12" },
        { text: "(GMT-11) Midway Island", value: "Pacific/Midway" },
        { text: "(GMT-11) American Samoa", value: "Pacific/Pago_Pago" },
        { text: "(GMT-10) Hawaii", value: "Pacific/Honolulu" },
        { text: "(GMT-9) Alaska", value: "America/Juneau" },
        {
          text: "(GMT-8) Pacific Time (US & Canada)",
          value: "America/Los_Angeles",
        },
        { text: "(GMT-8) Tijuana", value: "America/Tijuana" },
        {
          text: "(GMT-7) Mountain Time (US & Canada)",
          value: "America/Denver",
        },
        { text: "(GMT-7) Arizona", value: "America/Phoenix" },
        { text: "(GMT-7) Chihuahua", value: "America/Chihuahua" },
        { text: "(GMT-7) Mazatlan", value: "America/Mazatlan" },
        {
          text: "(GMT-6) Central Time (US & Canada)",
          value: "America/Chicago",
        },
        { text: "(GMT-6) Saskatchewan", value: "America/Regina" },
        { text: "(GMT-6) Guadalajara", value: "America/Mexico_City" },
        { text: "(GMT-6) Mexico City", value: "America/Mexico_City" },
        { text: "(GMT-6) Monterrey", value: "America/Monterrey" },
        { text: "(GMT-6) Central America", value: "America/Guatemala" },
        {
          text: "(GMT-5) Eastern Time (US & Canada)",
          value: "America/New_York",
        },
        {
          text: "(GMT-5) Indiana (East)",
          value: "America/Indiana/Indianapolis",
        },
        { text: "(GMT-5) Bogota", value: "America/Bogota" },
        { text: "(GMT-5) Lima", value: "America/Lima" },
        { text: "(GMT-5) Quito", value: "America/Lima" },
        { text: "(GMT-4) Atlantic Time (Canada)", value: "America/Halifax" },
        { text: "(GMT-4) Caracas", value: "America/Caracas" },
        { text: "(GMT-4) La Paz", value: "America/La_Paz" },
        { text: "(GMT-4) Newfoundland", value: "America/St_Johns" },
        { text: "(GMT-4) Georgetown", value: "America/Guyana" },
        { text: "(GMT-4) Puerto Rico", value: "America/Puerto_Rico" },
        { text: "(GMT-3) Santiago", value: "America/Santiago" },
        { text: "(GMT-3) Brasilia", value: "America/Sao_Paulo" },
        {
          text: "(GMT-3) Buenos Aires",
          value: "America/Argentina/Buenos_Aires",
        },
        { text: "(GMT-3) Montevideo", value: "America/Montevideo" },
        { text: "(GMT-3) Greenland", value: "America/Godthab" },
        { text: "(GMT-2) Mid-Atlantic", value: "Atlantic/South_Georgia" },
        { text: "(GMT-1) Azores", value: "Atlantic/Azores" },
        { text: "(GMT-1) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
        { text: "(GMT+0) Dublin", value: "Europe/Dublin" },
        { text: "(GMT+0) Edinburgh", value: "Europe/London" },
        { text: "(GMT+0) Lisbon", value: "Europe/Lisbon" },
        { text: "(GMT+0) London", value: "Europe/London" },
        { text: "(GMT+0) Monrovia", value: "Africa/Monrovia" },
        { text: "(GMT+0) UTC", value: "UTC" },
        { text: "(GMT+1) Casablanca", value: "Africa/Casablanca" },
        { text: "(GMT+1) Belgrade", value: "Europe/Belgrade" },
        { text: "(GMT+1) Bratislava", value: "Europe/Bratislava" },
        { text: "(GMT+1) Budapest", value: "Europe/Budapest" },
        { text: "(GMT+1) Ljubljana", value: "Europe/Ljubljana" },
        { text: "(GMT+1) Prague", value: "Europe/Prague" },
        { text: "(GMT+1) Sarajevo", value: "Europe/Sarajevo" },
        { text: "(GMT+1) Skopje", value: "Europe/Skopje" },
        { text: "(GMT+1) Warsaw", value: "Europe/Warsaw" },
        { text: "(GMT+1) Zagreb", value: "Europe/Zagreb" },
        { text: "(GMT+1) Brussels", value: "Europe/Brussels" },
        { text: "(GMT+1) Copenhagen", value: "Europe/Copenhagen" },
        { text: "(GMT+1) Madrid", value: "Europe/Madrid" },
        { text: "(GMT+1) Paris", value: "Europe/Paris" },
        { text: "(GMT+1) Amsterdam", value: "Europe/Amsterdam" },
        { text: "(GMT+1) Berlin", value: "Europe/Berlin" },
        { text: "(GMT+1) Bern", value: "Europe/Zurich" },
        { text: "(GMT+1) Zurich", value: "Europe/Zurich" },
        { text: "(GMT+1) Rome", value: "Europe/Rome" },
        { text: "(GMT+1) Stockholm", value: "Europe/Stockholm" },
        { text: "(GMT+1) Vienna", value: "Europe/Vienna" },
        { text: "(GMT+1) West Central Africa", value: "Africa/Algiers" },
        { text: "(GMT+2) Bucharest", value: "Europe/Bucharest" },
        { text: "(GMT+2) Cairo", value: "Africa/Cairo" },
        { text: "(GMT+2) Helsinki", value: "Europe/Helsinki" },
        { text: "(GMT+2) Kyiv", value: "Europe/Kiev" },
        { text: "(GMT+2) Riga", value: "Europe/Riga" },
        { text: "(GMT+2) Sofia", value: "Europe/Sofia" },
        { text: "(GMT+2) Tallinn", value: "Europe/Tallinn" },
        { text: "(GMT+2) Vilnius", value: "Europe/Vilnius" },
        { text: "(GMT+2) Athens", value: "Europe/Athens" },
        { text: "(GMT+2) Jerusalem", value: "Asia/Jerusalem" },
        { text: "(GMT+2) Harare", value: "Africa/Harare" },
        { text: "(GMT+2) Pretoria", value: "Africa/Johannesburg" },
        { text: "(GMT+2) Kaliningrad", value: "Europe/Kaliningrad" },
        { text: "(GMT+3) Istanbul", value: "Europe/Istanbul" },
        { text: "(GMT+3) Minsk", value: "Europe/Minsk" },
        { text: "(GMT+3) Moscow", value: "Europe/Moscow" },
        { text: "(GMT+3) St. Petersburg", value: "Europe/Moscow" },
        { text: "(GMT+3) Volgograd", value: "Europe/Volgograd" },
        { text: "(GMT+3) Kuwait", value: "Asia/Kuwait" },
        { text: "(GMT+3) Riyadh", value: "Asia/Riyadh" },
        { text: "(GMT+3) Nairobi", value: "Africa/Nairobi" },
        { text: "(GMT+3) Baghdad", value: "Asia/Baghdad" },
        { text: "(GMT+3) Tehran", value: "Asia/Tehran" },
        { text: "(GMT+4) Samara", value: "Europe/Samara" },
        { text: "(GMT+4) Abu Dhabi", value: "Asia/Muscat" },
        { text: "(GMT+4) Muscat", value: "Asia/Muscat" },
        { text: "(GMT+4) Baku", value: "Asia/Baku" },
        { text: "(GMT+4) Tbilisi", value: "Asia/Tbilisi" },
        { text: "(GMT+4) Yerevan", value: "Asia/Yerevan" },
        { text: "(GMT+4) Kabul", value: "Asia/Kabul" },
        { text: "(GMT+5) Ekaterinburg", value: "Asia/Yekaterinburg" },
        { text: "(GMT+5) Islamabad", value: "Asia/Karachi" },
        { text: "(GMT+5) Karachi", value: "Asia/Karachi" },
        { text: "(GMT+5) Tashkent", value: "Asia/Tashkent" },
        { text: "(GMT+5) Chennai", value: "Asia/Kolkata" },
        { text: "(GMT+5) Kolkata", value: "Asia/Kolkata" },
        { text: "(GMT+5) Mumbai", value: "Asia/Kolkata" },
        { text: "(GMT+5) Kathmandu", value: "Asia/Kathmandu" },
        { text: "(GMT+5) New Delhi", value: "Asia/Kolkata" },
        { text: "(GMT+5) Sri Jayawardenepura", value: "Asia/Colombo" },
        { text: "(GMT+6) Astana", value: "Asia/Dhaka" },
        { text: "(GMT+6) Dhaka", value: "Asia/Dhaka" },
        { text: "(GMT+6) Almaty", value: "Asia/Almaty" },
        { text: "(GMT+6) Rangoon", value: "Asia/Rangoon" },
        { text: "(GMT+6) Urumqi", value: "Asia/Urumqi" },
        { text: "(GMT+7) Novosibirsk", value: "Asia/Novosibirsk" },
        { text: "(GMT+7) Bangkok", value: "Asia/Bangkok" },
        { text: "(GMT+7) Hanoi", value: "Asia/Bangkok" },
        { text: "(GMT+7) Jakarta", value: "Asia/Jakarta" },
        { text: "(GMT+7) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
        { text: "(GMT+8) Beijing", value: "Asia/Shanghai" },
        { text: "(GMT+8) Chongqing", value: "Asia/Chongqing" },
        { text: "(GMT+8) Hong Kong", value: "Asia/Hong_Kong" },
        { text: "(GMT+8) Kuala Lumpur", value: "Asia/Kuala_Lumpur" },
        { text: "(GMT+8) Singapore", value: "Asia/Singapore" },
        { text: "(GMT+8) Taipei", value: "Asia/Taipei" },
        { text: "(GMT+8) Perth", value: "Australia/Perth" },
        { text: "(GMT+8) Irkutsk", value: "Asia/Irkutsk" },
        { text: "(GMT+8) Ulaanbaatar", value: "Asia/Ulaanbaatar" },
        { text: "(GMT+9) Seoul", value: "Asia/Seoul" },
        { text: "(GMT+9) Osaka", value: "Asia/Tokyo" },
        { text: "(GMT+9) Sapporo", value: "Asia/Tokyo" },
        { text: "(GMT+9) Tokyo", value: "Asia/Tokyo" },
        { text: "(GMT+9) Yakutsk", value: "Asia/Yakutsk" },
        { text: "(GMT+9) Darwin", value: "Australia/Darwin" },
        { text: "(GMT+10) Adelaide", value: "Australia/Adelaide" },
        { text: "(GMT+10) Brisbane", value: "Australia/Brisbane" },
        { text: "(GMT+10) Vladivostok", value: "Asia/Vladivostok" },
        { text: "(GMT+10) Guam", value: "Pacific/Guam" },
        { text: "(GMT+10) Port Moresby", value: "Pacific/Port_Moresby" },
        { text: "(GMT+11) Canberra", value: "Australia/Melbourne" },
        { text: "(GMT+11) Melbourne", value: "Australia/Melbourne" },
        { text: "(GMT+11) Sydney", value: "Australia/Sydney" },
        { text: "(GMT+11) Hobart", value: "Australia/Hobart" },
        { text: "(GMT+11) Magadan", value: "Asia/Magadan" },
        { text: "(GMT+11) Srednekolymsk", value: "Asia/Srednekolymsk" },
        { text: "(GMT+11) Solomon Is.", value: "Pacific/Guadalcanal" },
        { text: "(GMT+11) New Caledonia", value: "Pacific/Noumea" },
        { text: "(GMT+12) Fiji", value: "Pacific/Fiji" },
        { text: "(GMT+12) Kamchatka", value: "Asia/Kamchatka" },
        { text: "(GMT+12) Marshall Is.", value: "Pacific/Majuro" },
        { text: "(GMT+13) Auckland", value: "Pacific/Auckland" },
        { text: "(GMT+13) Wellington", value: "Pacific/Auckland" },
        { text: "(GMT+13) Nuku'alofa", value: "Pacific/Tongatapu" },
        { text: "(GMT+13) Tokelau Is.", value: "Pacific/Fakaofo" },
        { text: "(GMT+13) Chatham Is.", value: "Pacific/Chatham" },
        { text: "(GMT+13) Samoa", value: "Pacific/Apia" },
      ],
      locales: [
        { text: "English", value: "en" },
        { text: "Русский", value: "ru" },
        { text: "Português", value: "pt" },
        { text: "Српски", value: "sr" },
        { text: "Thai", value: "th" },
        { text: "Hindi", value: "hi" },
        { text: "Turkish", value: "tr" },
        { text: "French", value: "fr" },
        { text: "German", value: "de" },
        { text: "Spanish", value: "es" },
        { text: "Italian", value: "it" },
        { text: "Indonesian", value: "id" },
        { text: "Polski", value: "pl" },
        { text: "Română", value: "ro" },
      ],
      newBranchDialog: false,
      search: null,
      editDialog: false,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      if (this.$store.getters.isSuperAdmin || this.$store.getters.isGuest) {
        this.isLoading = true;
        this.axios
          .get("/api/v1/main_companies", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.branches = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.branch_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$router.push({ path: `/dashboard` }).catch((err) => {});
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("errors.admins_only"),
          text: this.error,
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    company() {
      return this.$store.getters.getCompany.company;
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    submit() {
      if(this.$store.getters.getMainCompany && this.$store.getters.getMainCompany.plan && this.$store.getters.getMainCompany.plan.branches_limit > 0 && this.$store.getters.getMainCompany.companies_count && this.$store.getters.getMainCompany.companies_count >= this.$store.getters.getMainCompany.plan.branches_limit) {
        this.$swal({
          title: this.$t('plans.branches_limit_reached_title'),
          text: this.$t('plans.branches_limit_reached_text'),
          icon: "warning",
          showCancelButton: false
        });
      } else {
        if (this.user_role != "guest" && this.user_role != "partner") {
          let branch = this.branch;
          let self = this;
          if (
            branch.name &&
            branch.country &&
            branch.city &&
            branch.address &&
            branch.email &&
            branch.phone
          ) {
            this.isLoading = true;
            this.axios
              .post(
                "/api/v1/create_company",
                {
                  name: branch.name,
                  country: branch.country,
                  city: branch.city,
                  time_zone: branch.time_zone,
                  locale: branch.locale,
                  address: branch.address,
                  email: branch.email,
                  phone: branch.phone,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                let data = response.data;
                this.branches.push(data);
                this.newBranchDialog = false;
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "success",
                  title: this.$t("branches.added"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.add_branch"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "error",
                  title: this.$t("errors.add_branch"),
                  text: this.error,
                });
              })
              .finally(() => (this.isLoading = false));
          } else {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              icon: "warning",
              title: this.$t("errors.all_fields_title"),
              text: this.$t("errors.all_fields_text_2"),
            });
          }
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.no_access"),
          });
        }
      }
    },
    selectBranch(id) {
      let self = this;
      this.isLoading = true;
      this.axios
        .post(
          "/api/v1/select_company",
          {
            id: id,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.$store.commit("setCompany", { company: response.data });
          self.axios
            .get("/me.json", {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.$store.commit("setCurrentUser", response.data);
            })
            .catch(function (error) {
              self.$store.commit("unsetCurrentUser");
              self.isLoading = false;
              self.$swal({
                title: self.$t("errors.error"),
                text: error,
                icon: "error",
              });
              // self.$router.push({ path: `/signin` }).catch(err => {});
              console.log(error);
            });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.change_branch"));
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: this.$t("errors.change_branch"),
            text: this.error,
          });
        })
        .finally(() => (this.isLoading = false));
    },
    deleteItem(branch) {
      if (this.user_role == "superadmin") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("branches.delete_branch"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/destroy_company/${branch.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.branches.splice(this.branches.indexOf(branch), 1);
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_branch"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal(
              this.$t("custom.deleted"),
              this.$t("branches.delete_success"),
              "success"
            );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteMainCompany() {
      if (this.user_role == "superadmin") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("branches.delete_branch"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/destroy_main_company`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.$store.commit("unsetCurrentUser");
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_branch"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal(
              this.$t("custom.deleted"),
              this.$t("branches.delete_success"),
              "success"
            );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("LL");
    },
    checkSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
