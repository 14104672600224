<template>
  <span
    v-resize="checkMobile"
    :style="isMobile ? 'padding: 5px !important;' : ''"
  >
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-toolbar
        v-if="!isMobile"
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
      >
        <v-flex class="d-flex align-center justify-space-between">
          <v-flex class="d-flex align-center justify-start mt-5">
            <v-switch
              v-model="showEntity"
              :label="$t('custom.entity_clients')"
              class="mr-2 my-auto"
            ></v-switch>
            <v-switch
              v-model="showDebtors"
              :label="$t('custom.debts_clients')"
            ></v-switch>
          </v-flex>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('custom.search')"
            single-line
            clearable
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn small color="success" :to="{ name: 'NewClient' }">{{
            $t("custom.add_client")
          }}</v-btn>
          <v-icon
            v-if="
              filtered_clients &&
              filtered_clients.length > 0 &&
              (user_role == 'superadmin' || user_role == 'admin')
            "
            @click="toXlsx"
            class="ml-2"
            style="cursor: pointer"
            color="success"
          >
            mdi-file-excel-outline
          </v-icon>
          <v-btn
            icon
            :href="`https://rentprog.${
              $root.$i18n.locale == 'ru' ? 'ru' : 'com'
            }/${$root.$i18n.locale}/categories/4/guides/14`"
            target="_blank"
          >
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </v-flex>
      </v-toolbar>
      <v-toolbar
        v-else
        height="150px"
        :color="$vuetify.theme.dark ? '' : 'white'"
      >
        <v-flex md12 class="text-center">
          <v-text-field
            v-model="search"
            class="mb-2"
            append-icon="mdi-magnify"
            :label="$t('custom.search')"
            single-line
            clearable
            hide-details
          ></v-text-field>
          <v-btn
            class="mt-2"
            small
            color="success"
            :to="{ name: 'NewClient' }"
            >{{ $t("custom.add_client") }}</v-btn
          >
          <v-icon
            v-if="
              filtered_clients &&
              filtered_clients.length > 0 &&
              (user_role == 'superadmin' || user_role == 'admin')
            "
            @click="toXlsx"
            class="mt-2 ml-2"
            style="cursor: pointer"
            color="success"
          >
            mdi-file-excel-outline
          </v-icon>
          <v-flex class="d-flex align-center justify-center mt-3">
            <v-switch
              v-model="showEntity"
              :label="$t('custom.entity_clients')"
              class="mr-2 my-auto"
              hide-details
              dense
            ></v-switch>
            <v-switch
              v-model="showDebtors"
              :label="$t('custom.debts_clients')"
              hide-details
              dense
            ></v-switch>
          </v-flex>
        </v-flex>
      </v-toolbar>
      <v-data-table
        v-if="!showEntity"
        :headers="headers"
        :items="filtered_clients"
        :search="search"
        :loading="isLoading"
        :loading-text="$t('custom.loading_table')"
        :items-per-page="50"
        mobile-breakpoint="100"
        :server-items-length.sync="total"
        :options.sync="tableOptions"
        :page.sync="page"
        sort-by="id"
        sort-desc
        :class="!isMobile ? '' : 'is-mobile'"
        dense
        :footer-props="{
          pageText: `{0} ${$t('custom.of')} ${total}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [50, 100, 200],
        }"
      >
        <template slot="item" slot-scope="props">
          <tr
            :class="checkClientCategory(props.item)"
            @dblclick="replaceToClient(props.item)"
            style="cursor: pointer"
          >
            <td class="text-center">
              <router-link :to="{ path: `/clients/${props.item.id}` }" class="text-decoration-none">{{ props.item.id
                }}</router-link>
            </td>
            <td class="text-center nowrap">
              <v-icon
                small
                class="mr-2"
                @click="replaceToClient(props.item)"
                :title="$t('clients.info')"
              >
                mdi-eye-outline
              </v-icon>
              <v-icon
                small
                @click="deleteItem(props.item)"
                v-if="user_role == 'superadmin' || user_role == 'admin'"
              >
                mdi-delete-forever
              </v-icon>
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ props.item.lastname }}
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ props.item.name }}
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ props.item.middlename }}
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ props.item.phone }}
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ props.item.email }}
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              <span v-if="props.item.balance >= 0">{{ props.item.balance }}</span>
              <span v-else>⚠️&nbsp;{{ props.item.balance }}</span>
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{
                props.item.birthday && props.item.birthday != "Invalid date"
                  ? birthdayFormat(props.item.birthday)
                  : ""
              }}
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ props.item.inn }}
            </td>
            <td class="text-center">{{ props.item.source }}</td>
            <td
              class="text-center"
              v-tooltip="{
                content: branchName(props.item.company_id),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              {{ branchCity(props.item.company_id) }}
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ $t("custom.no_data_in_table") }}
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
          {{ $t("tables.no_search_result", { msg: search }) }}
        </v-alert>
      </v-data-table>
      <v-data-table
        v-else
        :headers="headers_entity"
        :items="filtered_entity_clients"
        :loading="isLoading"
        :loading-text="$t('custom.loading_table')"
        :search="search"
        :sort-by="['created_at']"
        :sort-desc="[true]"
        :items-per-page="20"
        :server-items-length.sync="total"
        :options.sync="tableOptions"
        :page.sync="page"
        :class="!isMobile ? '' : 'is-mobile'"
        :footer-props="{
          pageText: `{0} ${$t('custom.of')} {1}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [20, 50, 100],
        }"
      >
        <template slot="item" slot-scope="props">
          <tr
            :class="checkClientCategory(props.item)"
            @dblclick="replaceToClient(props.item)"
            style="cursor: pointer"
          >
            <td class="text-center">{{ props.item.id }}</td>
            <td class="justify-center layout px-0 py-1">
              <v-icon
                small
                class="mr-2"
                @click="replaceToClient(props.item)"
                :title="$t('clients.info')"
              >
                mdi-eye-outline
              </v-icon>
              <v-icon small @click="deleteItem(props.item)">
                mdi-delete-forever
              </v-icon>
            </td>
            <td class="text-center">{{ props.item.entity_name }}</td>
            <td class="text-center">{{ props.item.short_entity_name }}</td>
            <td class="text-center">{{ props.item.reg_form }}</td>
            <td class="text-center">{{ props.item.entity_phone }}</td>
            <td class="text-center">{{ props.item.email }}</td>
            <td
              class="text-center"
            >
              <span v-if="props.item.balance >= 0">{{ props.item.balance }}</span>
              <span v-else>⚠️&nbsp;{{ props.item.balance }}</span>
            </td>
            <td class="text-center">{{ props.item.inn }}</td>
            <td class="text-center">{{ props.item.ogrn }}</td>
            <td class="text-center">{{ props.item.source }}</td>
            <td class="text-center">{{ branchCity(props.item.company_id) }}</td>
          </tr>
        </template>
        <template slot="no-data">
          {{ $t("custom.no_data_in_table") }}
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
          {{ $t("tables.no_search_result", { msg: search }) }}
        </v-alert>
      </v-data-table>
    </v-card>
  </span>
</template>
<script>
/* eslint-disable */
import { countries } from "./../countries";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { saveAs } from "file-saver";
import Uppy from "../../../plugins/uppy";
import { isMobile } from "mobile-device-detect";
import xlsx from "json-as-xlsx";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      clients: [],
      entity_clients: [],
      filtered_clients: [],
      filtered_entity_clients: [],
      // Костыль, ошибка при инициализации account.cash и account.deposit
      editedClient: null,
      customer_category: [
        { text: this.$t("customer_category.new"), value: "Новый" },
        { text: this.$t("customer_category.loyal"), value: "Лояльный" },
        { text: this.$t("customer_category.statement"), value: "Постоянный" },
        { text: this.$t("customer_category.problem"), value: "Серый" },
        { text: this.$t("customer_category.bad"), value: "Чёрный" },
      ],
      birthday_non_format: "",
      countries: countries,
      searchMenuVisibility: false,
      showEntity: false,
      showDebtors: false,
      showDebt: false,
      showDopInfo: false,
      showDocs: false,
      search: null,
      searchQuery: null,
      // meilisearchInstance: new MeiliSearch({
      //   host: "http://localhost:7700",
      //   apiKey: "v8N8uqBC3UvPzszXxqqInRxqFM6HsTd0s7Qc4lLipQE",
      // }),
      headers: [
        {
          text: this.$t("custom.number"),
          align: "center",
          value: "id",
        },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        {
          text: this.$t("bookings.lastname"),
          value: "lastname",
          align: "center",
        },
        { text: this.$t("bookings.name"), value: "name", align: "center" },
        {
          text: this.$t("bookings.middlename"),
          value: "middlename",
          align: "center",
        },
        {
          text: this.$t("custom.phone"),
          sortable: false,
          value: "phone",
          align: "center",
        },
        { text: this.$t("custom.email"), value: "email", align: "center" },
        { text: this.$t("other.balance"), value: "balance", align: "center" },
        {
          text: this.$t("bookings.birthdate"),
          sortable: false,
          value: "birthday",
          align: "center",
        },
        { text: this.$t("custom.tax_number"), value: "inn", align: "center" },
        { text: this.$t("custom.source"), value: "source", align: "center" },
        {
          text: this.$t("custom.branch"),
          value: "company_id",
          align: "center",
        },
      ],
      headers_entity: [
        {
          text: this.$t("custom.number"),
          sortable: false,
          align: "center",
          value: "id",
        },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        {
          text: this.$t("custom.company_name"),
          value: "entity_name",
          align: "center",
        },
        {
          text: this.$t("custom.company_name_short"),
          value: "short_entity_name",
          align: "center",
        },
        {
          text: this.$t("custom.entity_form"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("custom.phone"),
          sortable: false,
          value: "entity_phone",
          align: "center",
        },
        { text: this.$t("custom.email"), value: "email", align: "center" },
        { text: this.$t("other.balance"), value: "balance", align: "center" },
        {
          text: this.$t("custom.tax_number"),
          sortable: false,
          value: "inn",
          align: "center",
        },
        {
          text: this.$t("custom.entity_number"),
          sortable: false,
          value: "ogrn",
          align: "center",
        },
        { text: this.$t("custom.source"), value: "source", align: "center" },
        {
          text: this.$t("custom.branch"),
          value: "company_id",
          align: "center",
        },
      ],
      branches: [],
      tableOptions: {},
      page: 1,
      pageSize: 10,
      per_page: 20,
      sort_by: "id",
      direction: "desc",
      timeout: null,
      pagination: {
        sortBy: ["id"],
        sortDesc: [true],
        page: 1,
        rowsPerPage: 20,
      },
      total: 0,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/main_companies", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.branches = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.branch_load"));
          console.log(error);
        });
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    showDebtors() {
      this.page = 1;
      this.getDataFromApi();
    },
    showEntity() {
      this.page = 1;
      this.getDataFromApi();
    },
    tableOptions(pagination) {
      this.page = pagination.page;
      this.per_page = pagination.itemsPerPage;
      this.sort_by = pagination.sortBy[0] ? pagination.sortBy[0] : "created_at";
      this.direction = pagination.sortDesc[0] ? "desc" : "asc";
      this.getDataFromApi();
    },
    search() {
      if (this.search == "") {
        this.search = null;
      } else {
        let self = this;
        this.page = 1;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.getDataFromApi();
          }, 300); // delay
        }
        debounce();
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
  },
  methods: {
    getDataFromApi() {
      this.isLoading = true;
      this.axios
        .post(
          `/api/v1/index_clients_page`,
          {
            search: this.search,
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            direction: this.direction,
            debtor: this.showDebtors,
            entity: this.showEntity,
            meilisearch: true,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          let clients = [];
          let entity_clients = [];
          response.data.clients.forEach((element) => {
            if (!element.entity) {
              clients.push(element);
            } else {
              entity_clients.push(element);
            }
          });
          this.clients = clients;
          this.total = response.data.total;
          this.entity_clients = entity_clients;
          this.filtered_clients = clients;
          this.filtered_entity_clients = entity_clients;
        })
        .catch((error) => {
          console.log(error);
          this.setError(error, this.$t("errors.clients_load"));
        })
        .finally(() => (this.isLoading = false));
    },
    capitalizeFirst(text) {
      if (text) {
        return text[0].toUpperCase() + text.substring(1);
      }
    },
    checkClientCategory(client) {
      if (client && client.category == "Новый") {
        return "" + (client.debtor ? "warning lighten-2" : "");
      } else if (client && client.category == "Постоянный") {
        return `green--text ${client.debtor ? " warning lighten-2" : ""}`;
      } else if (client && client.category == "Серый") {
        return `grey--text text-decoration-line-through ${
          client.debtor ? " warning lighten-2" : ""
        }`;
      } else if (client && client.category == "Чёрный") {
        return `black--text text-decoration-line-through ${
          client.debtor ? " warning lighten-2" : ""
        }`;
      } else if (client && client.category == "Лояльный") {
        return `deep-purple--text ${client.debtor ? " warning lighten-2" : ""}`;
      }
    },
    deleteItem(client) {
      if (this.user_role != "guest" || this.user_role != "partner") {
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/clients/${client.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.clients.splice(this.clients.indexOf(client), 1);
                this.$swal(
                  this.$t("custom.deleted"),
                  this.$t("clients.remove"),
                  "success"
                );
              })
              .catch((error) => {
                // check if error is 406
                if(error.response.status == 406) {
                  this.$swal({
                    showConfirmButton: true,
                    icon: "error",
                    title: this.$t("errors.client_delete"),
                    text: this.$t("errors.delete_client_has_bookings"),
                  });
                } else {
                  this.setError(error, this.$t("errors.client_delete"))
                }
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    localeClientCategory(category) {
      if (category == "Новый") {
        return this.$t("customer_category.new");
      } else if (category == "Постоянный") {
        return this.$t("customer_category.statement");
      } else if (category == "Серый") {
        return this.$t("customer_category.problem");
      } else if (category == "Чёрный") {
        return this.$t("customer_category.bad");
      } else if (category == "Лояльный") {
        return this.$t("customer_category.loyal");
      } else {
        return "";
      }
    },
    toXlsx() {
      if (this.user_role != "guest" || this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/clients`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            let clients = [];
            let entity_clients = [];
            response.data.data.forEach((element) => {
              if (!element.attributes.entity) {
                clients.push(element.attributes);
              } else {
                entity_clients.push(element.attributes);
              }
            });
            let data = [
              {
                sheets: "Clients",
                columns: [
                  { label: "ID", value: "id" },
                  { label: this.$t("clients.created_at"), value: "created_at" },
                  { label: this.$t("bookings.name"), value: "name" },
                  {
                    label: this.$t("bookings.middlename"),
                    value: "middlename",
                  },
                  { label: this.$t("bookings.lastname"), value: "lastname" },
                  { label: this.$t("custom.phone"), value: "phone" },
                  { label: "Email", value: "email" },
                  {
                    label: this.$t("bookings.birthdate"),
                    value: (row) => {
                      return row.birthday && row.birthday != "Invalid date"
                        ? this.birthdayFormat(row.birthday)
                        : "";
                    },
                  }, // Custom format
                  {
                    label: this.$t("clients.category"),
                    value: (row) => {
                      return this.localeClientCategory(row.category);
                    },
                  },
                  { label: this.$t("clients.tax_number"), value: "inn" },
                  { label: this.$t("clients.debtor"), value: "debtor" },
                  { label: this.$t("clients.source"), value: "source" },
                  { label: this.$t("clients.country"), value: "country" },
                  { label: this.$t("clients.city"), value: "city" },
                  { label: this.$t("clients.address"), value: "address" },
                  {
                    label: this.$t("clients.branch"),
                    value: (row) => {
                      return this.branchCity(row.company_id);
                    },
                  },
                  {
                    label: this.$t("clients.driver_series"),
                    value: "driver_series",
                  },
                  {
                    label: this.$t("clients.driver_number"),
                    value: "driver_number",
                  },
                  {
                    label: this.$t("clients.driver_issued"),
                    value: "driver_issued",
                  },
                  {
                    label: this.$t("clients.passport_series"),
                    value: "passport_series",
                  },
                  {
                    label: this.$t("clients.passport_number"),
                    value: "passport_number",
                  },
                  {
                    label: this.$t("clients.passport_issued"),
                    value: "passport_issued",
                  },
                  { label: this.$t("clients.sale"), value: "sale" },
                  { label: this.$t("clients.sale_cash"), value: "sale_cash" },
                ],
                content: clients,
              },
              {
                sheets: "Entity Clients",
                columns: [
                  { label: "ID", value: "id" },
                  { label: this.$t("clients.created_at"), value: "created_at" },
                  { label: this.$t("clients.reg_form"), value: "reg_form" },
                  { label: this.$t("companies.name"), value: "entity_name" },
                  { label: this.$t("clients.ceo"), value: "ceo" },
                  { label: this.$t("custom.phone"), value: "entity_phone" },
                  { label: "Email", value: "email" },
                  {
                    label: this.$t("clients.category"),
                    value: (row) => {
                      return this.localeClientCategory(row.category);
                    },
                  },
                  { label: this.$t("clients.tax_number"), value: "inn" },
                  { label: this.$t("clients.debtor"), value: "debtor" },
                  {
                    label: this.$t("clients.branch"),
                    value: (row) => {
                      return this.branchCity(row.company_id);
                    },
                  },
                  { label: this.$t("clients.country"), value: "country" },
                  { label: this.$t("clients.city"), value: "city" },
                  { label: this.$t("clients.address"), value: "entity_adress" },
                  { label: this.$t("clients.tin"), value: "ogrn" },
                  { label: this.$t("clients.bank"), value: "bank" },
                  {
                    label: this.$t("clients.checking_account"),
                    value: "acc_number",
                  },
                  {
                    label: this.$t("clients.correspondent_account"),
                    value: "korr",
                  },
                ],
                content: entity_clients,
              },
            ];
            let settings = {
              fileName: "clients", // Name of the resulting spreadsheet
              extraLength: 3, // A bigger number means that columns will be wider
              writeMode: "write", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
              writeOptions: {
                bookType: "xlsx",
                type: "array",
              }, // Style options from https://docs.sheetjs.com/docs/api/write-options
              RTL: false, // Display the columns from right-to-left (the default value is false)
            };
            let wbout = xlsx(data, settings);
            var blob_data = new Blob([new Uint8Array(wbout)], {type:"application/octet-stream"});
            if (!this.isNative) {
              saveAs(blob_data, 'clients.xlsx');
            } else {
              if (Filesystem.checkPermissions()) {
                const getBase64FromBlob = async (blob) => {
                  return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                      const base64data = reader.result;
                      resolve(base64data);
                    }
                  });
                }
                getBase64FromBlob(blob_data).then(
                  (base64) => {
                    // save file
                    async function writeFile() {
                      return await Filesystem.writeFile({
                        path: 'clients.xlsx',
                        data: base64,
                        directory: Directory.Documents,
                      });
                    };
                    let file_uri = null
                    let writedFile = writeFile().then((getUriResult) => {
                      // share file opened file
                      let shareFile = async () => {
                        await Share.share({
                          title: 'Download document',
                          files: [getUriResult.uri],
                        }).catch((error) => {
                          console.log("shareFile", error)
                        });
                      };
                      let sharedFile = shareFile();
                    }).catch((error) => {
                      console.log("shareFile", error)
                    });
                  }
                );
              } else {
                Filesystem.requestPermissions();
              }
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.clients_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    birthdayFormat(date) {
      return date && moment(date, "YYYY-MM-DD").isValid
        ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")
        : "";
    },
    branchCity(id) {
      let branch = this.branches.find((branch) => {
        return branch.id == id;
      });
      if (branch && branch.city) {
        return branch.city;
      } else {
        return id;
      }
    },
    branchName(id) {
      let branch = this.branches.find((branch) => {
        return branch.id == id;
      });
      if (branch && branch.name) {
        return `ID ${branch.id} | ${branch.name} | ${branch.city}`;
      } else {
        return id;
      }
    },
    replaceToClient(client) {
      this.$router.push({ path: `/clients/${client.id}` });
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    Uppy,
  },
};
</script>
