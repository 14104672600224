<template>
  <div v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-toolbar dense :height="isMobile ? '100px' : '50px'">
        <v-row>
          <v-col cols="12" lg="8" md="8" sm="12" xsm="12" :class="isMobile ? 'd-flex align-center justify-center' : 'd-flex align-center justify-end'">
            <v-btn :small="isMobile" text color="info" @click="days_count += 1">+1</v-btn>
            <v-btn :small="isMobile" text color="info" @click="days_count += 7">+7</v-btn
            >
            <v-btn :small="isMobile" text color="info" @click="days_count += 30">+30</v-btn>
            <v-btn :small="isMobile" text color="info" @click="days_count -= 1">-1</v-btn>
            <v-btn :small="isMobile" text color="info" @click="days_count -= 7">-7</v-btn>
            <v-btn :small="isMobile" text color="info" @click="days_count -= 30">-30</v-btn>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12" xsm="12" :class="isMobile ? 'd-flex align-center justify-center' : 'd-flex align-center justify-end'">
            <!-- Добавляем поле поиска -->
            <v-text-field
              v-model="searchQuery"
              :label="$t('custom.search')"
              dense
              hide-details
              class="mx-2"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-skeleton-loader v-if="isLoading" type="item-list"></v-skeleton-loader>
      <v-row justify="space-around" class="mx-1 mt-1">
        <v-col cols="12" sm="12" md="12">
          <v-simple-table
            fixed-header
            :height="isMobile ? 'calc(100vh - 285px)' : 'calc(100vh - 178px)'"
            dense
            class="loading-calendar-table"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="border: transparent !important;"></th>
                  <th style="border: transparent !important;"></th>
                  <th style="border: transparent !important;"></th>
                  <th
                    v-for="n in number_days"
                    :key="n.index"
                    :class="$vuetify.theme.dark ? 'grey darken-4 white--text text-center' + checkToday(n + days_count) : checkToday(n + days_count) + ' text-center'"
                  >
                    {{ th_color }}
                    {{ month(n + days_count) }}
                  </th>
                </tr>
                <tr>
                  <th style="border: transparent !important;">
                    <!-- {{$t("custom.code")}} -->
                  </th>
                  <th style="border: transparent !important;">

                  </th>
                  <th style="border: transparent !important;">

                  </th>
                  <th
                    v-for="n in number_days"
                    :key="n.index"
                    :class="$vuetify.theme.dark ? 'grey darken-4 white--text text-center' + checkToday(n + days_count) : checkToday(n + days_count)"
                  >
                    <div v-html="dates(n + days_count)" class="px-2 text-center"></div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="car in filteredCars"
                  :key="car.id"
                  :class="$vuetify.theme.dark ? 'white--text' : 'grey--text'"
                  :id="`car_id_${car.id}`"
                >
                  <td class="px-1">
                    <router-link
                      :to="{ name: 'CarCard', params: { id: car.id } }"
                      :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
                      style="text-decoration: none;"
                      >{{ car.code }}</router-link
                    >
                  </td>
                  <td class="px-1 text-center">
                    <async-value :value="loadingPercent(car.id)" />
                  </td>
                  <!-- Состояние -->
                  <td>
                    <v-menu offset-y v-if="car.state == 1 || car.state == null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mx-1"
                          v-tooltip="{
                            content: $t('states.normal'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500
                            }
                          }"
                          v-bind="attrs"
                          v-on="on"
                          color="success"
                        >
                          mdi-thumb-up
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in states"
                          :key="index"
                          @click="updateCarState(car.id, state.id)"
                        >
                          <v-list-item-title>
                            {{ state.state }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu offset-y v-if="car.state == 2">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mx-1"
                          v-tooltip="{
                            content: $t('states.in_service'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500
                            }
                          }"
                          v-bind="attrs"
                          v-on="on"
                          color="secondary"
                        >
                          mdi-tools
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in states"
                          :key="index"
                          @click="updateCarState(car.id, state.id)"
                        >
                          <v-list-item-title>
                            {{ state.state }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu offset-y v-if="car.state == 3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mx-1"
                          v-tooltip="{
                            content: $t('states.critical'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500
                            }
                          }"
                          v-bind="attrs"
                          v-on="on"
                          color="error"
                        >
                          mdi-alert-box
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in states"
                          :key="index"
                          @click="updateCarState(car.id, state.id)"
                        >
                          <v-list-item-title>
                            {{ state.state }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu offset-y v-if="car.state == 4">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mx-1"
                          v-tooltip="{
                            content: $t('states.long_rent'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500
                            }
                          }"
                          v-bind="attrs"
                          v-on="on"
                          color="pink"
                        >
                          mdi-all-inclusive
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in states"
                          :key="index"
                          @click="updateCarState(car.id, state.id)"
                        >
                          <v-list-item-title>
                            {{ state.state }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu offset-y v-if="car.state == 5">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mx-1"
                          v-tooltip="{
                            content: $t('states.no_rent'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500
                            }
                          }"
                          v-bind="attrs"
                          v-on="on"
                          color="info"
                        >
                          mdi-airplane-off
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in states"
                          :key="index"
                          @click="updateCarState(car.id, state.id)"
                        >
                          <v-list-item-title>
                            {{ state.state }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu offset-y v-if="car.state == 6">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mx-1"
                          v-tooltip="{
                            content: $t('states.service_needed_2'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500
                            }
                          }"
                          v-bind="attrs"
                          v-on="on"
                          color="orange"
                        >
                          mdi-alarm-light
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(state, index) in states"
                          :key="index"
                          @click="updateCarState(car.id, state.id)"
                        >
                          <v-list-item-title>
                            {{ state.state }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td
                    v-for="(n, index) in number_days"
                    :key="n.index"
                    @mouseenter="highlightColumn(index + 3)"
                    @mouseleave="removeHighlight"
                    :class="checkDayPaid(n - 1 + days_count, car.bookings)"
                    @click="showBooking(n - 1 + days_count, car.bookings)"
                    :title="titleForDay(n - 1 + days_count, car.bookings)"
                  >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="dialog" :retain-focus="false" max-width="500px">
      <v-card>
        <v-card-title>
          <v-flex sm6 class="">
            <h4 class="">
              {{ $t("bookings.booking") }} {{ $t("custom.number")
              }}{{ booking.id }}
            </h4>
          </v-flex>
          <v-flex sm6 class="text-right">
            <v-btn
              text
              @click="(booking = {}), (dialog = false)"
            >
              {{ $t('custom.close') }}
            </v-btn>
          </v-flex>
        </v-card-title>
        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12 class="mx-1">
            <v-alert v-if="booking && booking.manual_editing" dense outlined prominent type="warning" class="mt-3"
              v-tooltip="{
                content: $t('tooltips.manual_editing'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
              {{ $t("bookings.manual_editing") }}
            </v-alert>
            <v-alert v-if="booking && booking.agent_id && user_role != 'agent'" dense outlined prominent type="warning"
              class="mt-3">
              {{ $t("agents.created_agents") }}
            </v-alert>
            <div v-if="booking.serial_number">
              {{ $t("bookings.serial_number") }}: {{ booking.serial_number }}
            </div>
            <div v-if="booking.source" v-tooltip="{
              content: $t('tooltips.booking_source'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">
              {{ $t("custom.created") }}:
              {{ formatCreatedAt(booking.created_at) }}
            </div>
            <div v-if="booking.vseprokaty_id">
              {{ $t("agregator.agregator_id") }}: {{ booking.vseprokaty_id }}
            </div>
            <div v-if="booking.fast_booking">
              {{ $t("agregator.fast_booking") }}: +
            </div>
            <div v-if="booking.vseprokaty_id && !booking.fast_booking">
              {{ $t("agregator.fast_booking") }}: -
            </div>
            <div v-if="booking.agent_id">
              {{ $t("agents.agent") }} {{ $t("custom.number") }}
              <router-link :to="{ path: `/agent_card/${booking.agent_id}` }">
                {{ booking.agent_id }}
              </router-link>
            </div>
            <v-divider class="my-2"></v-divider>
            <div v-if="!booking.entity && booking.first_name" :style="user_role == 'guest' || user_role == 'partner'
              ? 'display: none;'
              : ''
              ">
              {{ $t("custom.client") }}:
              <router-link :to="{ path: `/clients/${booking.client_id}` }">{{ booking.last_name }}
                {{ booking.first_name }}
                {{ booking.middle_name }}</router-link>
            </div>
            <div v-else :style="user_role == 'guest' || user_role == 'partner'
              ? 'display: none;'
              : ''
              ">
              {{ $t("custom.client") }}:
              <router-link :to="{ path: `/clients/${booking.client_id}` }">{{
                booking.entity_name
                }}</router-link>
            </div>
            <v-divider class="my-2"></v-divider>
            <div>
              {{ $t("custom.car_long") }}:
              <router-link :to="{ path: `/cars/${booking.car_id}` }">{{ booking.car_name }} - {{ booking.car_code
                }}</router-link>
            </div>
            <div>{{ $t("bookings.start_date") }}: {{ booking.start_date }}</div>
            <div>{{ $t("bookings.end_date") }}: {{ booking.end_date }}</div>
            <div v-if="!booking.hourly && !booking.monthly">{{ $t("custom.days") }}: {{ booking.days }}</div>
            <div v-if="booking.hourly">{{ $t("custom.hours") }}: {{ booking.hourly_hours }}</div>
            <div v-if="booking.monthly">{{ $t("custom.months") }}: {{ booking.monthly_months }}</div>
            <div v-if="booking.additional_hours > 0">
              {{ $t("bookings.add_hours") }}: {{ booking.additional_hours }}
            </div>
            <div>
              {{ $t("bookings.checkin") }}: {{ booking.location_start }}
            </div>
            <div>{{ $t("bookings.checkout") }}: {{ booking.location_end }}</div>
            <div v-if="!booking.technical">
              {{ $t("bookings.current_price") }}: {{ booking.price }}
            </div>
            <div v-if="!booking.technical && booking.sale_cash">
              {{ $t("bookings.sale") }}: {{ booking.sale_cash }}
            </div>
            <div v-if="!booking.technical && booking.sale">
              {{ $t("bookings.sale") }}: {{ booking.sale }}%
            </div>
            <div v-if="!booking.technical && booking.rental_cost_sale">
              {{ $t("bookings.rental_cost_sale") }}: {{ booking.rental_cost_sale }}%
            </div>
            <div v-if="!booking.technical && booking.rental_cost_sale_cash">
              {{ $t("bookings.rental_cost_sale") }}: {{ booking.rental_cost_sale_cash }}
            </div>
            <div v-if="booking.tariff_id">
              {{ $t("bookings.tariff") }}: {{ findTariff(booking.tariff_id) }}
            </div>
            <div v-if="booking.selected_price != booking.start_price &&
              booking.start_price != 0
            ">
              {{ $t("bookings.start_price") }}: {{ booking.start_price
              }}
            </div>
            <v-divider v-if="booking.chair ||
              booking.chair_less_1_year ||
              booking.booster ||
              booking.navigator ||
              booking.mp3 ||
              booking.charger ||
              booking.wifi_router
            " class="mt-2 mb-2"></v-divider>
            <h3 v-if="booking.chair ||
              booking.chair_less_1_year ||
              booking.booster ||
              booking.navigator ||
              booking.mp3 ||
              booking.charger ||
              booking.wifi_router
            ">
              {{ $t("bookings.add_equipment") }}
            </h3>
            <div v-if="booking.chair">
              {{ $t("bookings.baby_chair") }} - {{ booking.chairs_quantity }}
            </div>
            <div v-if="booking.chair_less_1_year">
              {{ $t("bookings.small_baby_chair") }} -
              {{ booking.chairs_quantity }}
            </div>
            <div v-if="booking.booster">
              {{ $t("bookings.booster") }} - {{ booking.boosters_quantity }}
            </div>
            <div v-if="booking.navigator">{{ $t("bookings.navigator") }}</div>
            <div v-if="booking.mp3">{{ $t("bookings.mp3") }}</div>
            <div v-if="booking.charger">{{ $t("bookings.charger") }}</div>
            <div v-if="booking.wifi_router">{{ $t("bookings.wifi") }}</div>
            <div v-if="booking.other_equipment">
              {{ booking.other_equipment }}
            </div>
            <h3 v-if="booking.kasko ||
              booking.super_kasko ||
              booking.theft ||
              booking.no_franchise
            ">
              {{ $t("bookings.add_insurance") }}
            </h3>
            <div v-if="booking.kasko">{{ $t("bookings.kasko") }}</div>
            <div v-if="booking.super_kasko">
              {{ $t("bookings.super_kasko") }}
            </div>
            <div v-if="booking.theft">
              {{ $t("bookings.theft_insurance") }}
            </div>
            <div v-if="booking.no_franchise">
              {{ $t("bookings.no_franchise") }}
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <!-- <h3 v-if="!booking.technical">{{ $t("bookings.calculate") }}</h3> -->
            <div v-if="!booking.technical && booking.aggr_commission == 0" class="font-weight-bold">
              {{ $t("bookings.rental_cost") }}: {{ parseFloat(booking.rental_cost).toFixed(2) }}
            </div>
            <div v-if="!booking.technical && booking.aggr_commission > 0" class="font-weight-bold">
              {{ $t("bookings.rental_cost") }}: {{ booking.rental_cost
              }} - {{ booking.aggr_commission }} = {{ (booking.rental_cost - booking.aggr_commission) }}
            </div>
            <div class="font-weight-bold" v-if="booking.hours_cost > 0 && !booking.technical">
              {{ $t("bookings.hours_cost") }}: {{ booking.hours_cost
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.hours_cost_end > 0 && !booking.technical">
              {{ $t("bookings.hours_cost_end") }}: {{ booking.hours_cost_end
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.delivery > 0 && !booking.technical">
              {{ $t("bookings.delivery") }}: {{ booking.delivery
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.delivery_end > 0 && !booking.technical">
              {{ $t("bookings.checkout") }}: {{ booking.delivery_end
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.equipment > 0 && !booking.technical">
              {{ $t("bookings.equipment") }}: {{ booking.equipment
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.clean_payment > 0 && !booking.technical">
              {{ $t("bookings.clean") }}: {{ booking.clean_payment
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.damage > 0 && !booking.technical">
              {{ $t("bookings.damages") }}: {{ booking.damage }}
            </div>
            <div class="font-weight-bold" v-if="booking.gas > 0">
              {{ $t("bookings.for_gas") }}: {{ booking.gas }}
            </div>
            <div class="font-weight-bold" v-if="booking.add_drivers_cost > 0">
              {{ $t("bookings.for_add_drivers") }}: {{ booking.add_drivers_cost
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.insurance > 0">
              {{ $t("bookings.for_add_insurance") }}: {{ booking.insurance
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.fine > 0">
              {{ $t("bookings.for_fines") }}: {{ booking.fine }}
            </div>
            <div class="font-weight-bold" v-if="booking.other > 0">
              {{ $t("bookings.for_other") }}: {{ booking.other }}
            </div>
            <div class="font-weight-bold" v-if="booking.other_end > 0">
              {{ $t("bookings.for_other_end") }}: {{ booking.other_end
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.mileage_cost > 0">
              {{ $t("bookings.mileage_cost") }}: {{ booking.mileage_cost
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.aggr_commission > 0">
              <!-- <v-icon>mdi-alpha-a-circle-outline</v-icon> -->
              {{ $t("bookings.aggr_commission") }}: {{ booking.aggr_commission }}
            </div>
            <div class="font-weight-bold" v-if="!booking.technical">
              {{ $t("bookings.deposit") }}: {{ currentDeposit() }}
            </div>
            <div class="subtitle-1 font-weight-bold" v-if="!booking.technical">
              {{ $t("custom.total") }}:
              {{
                parseFloat(
                  booking.rental_cost +
                  booking.delivery +
                  booking.delivery_end +
                  booking.equipment +
                  booking.insurance +
                  booking.clean_payment +
                  booking.add_drivers_cost +
                  booking.hours_cost +
                  booking.mileage_cost +
                  booking.damage +
                  booking.gas +
                  booking.fine +
                  booking.other +
                  booking.other_end +
                  booking.hours_cost_end +
                  currentDeposit()
                ).toFixed(2)
              }}
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <div :class="'subtitle-1 font-weight-bold ' +
              `${calculatePayments(booking) == $t('bookings.paid')
                ? 'success--text'
                : ''
              }`
              " v-if="!booking.technical">
              {{ $t("bookings.paid") }}: {{ calculatePaymentsSum(booking)
              }}
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <div v-if="(booking.description &&
              booking.description != '' &&
              booking.description != ' ') || booking.failure_reason
            ">
              <h3>{{ $t("bookings.description") }}</h3>
              <p v-if="booking.description">{{ booking.description }}</p>
              <p v-if="booking.failure_reason">{{ booking.failure_reason }}</p>
            </div>
            <div v-if="booking.in_rent || booking.ride">
              {{ $t("bookings.clean_start") }}:
              <v-icon color="green" v-if="booking.clean_start">
                mdi-plus
              </v-icon>
              <v-icon color="red" v-else> mdi-minus </v-icon>
            </div>
            <div v-if="booking.ride">
              {{ $t("bookings.clean_end") }}:
              <v-icon color="green" v-if="booking.clean_end"> mdi-plus </v-icon>
              <v-icon color="red" v-else> mdi-minus </v-icon>
            </div>
            <div v-if="(booking.in_rent || booking.ride) && !booking.gas_start_full
            ">
              {{ $t("bookings.gas_start") }}: {{ booking.gas_start }}
            </div>
            <div v-if="(booking.in_rent || booking.ride) && booking.gas_start_full">
              {{ $t("bookings.gas_start_full") }}
            </div>
            <div v-if="!booking.gas_end_full && booking.ride">
              {{ $t("bookings.gas_end_short") }}: {{ booking.gas_end }}
            </div>
            <div v-if="booking.gas_end_full && booking.ride">
              {{ $t("bookings.gas_end_full") }}
            </div>
            <div v-if="(booking.in_rent || booking.ride) && booking.start_mileage > 0
            ">
              {{ $t("bookings.mileage_start") }}: {{ booking.start_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.end_mileage > 0">
              {{ $t("bookings.mileage_end") }}: {{ booking.end_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.booking_mileage > 0">
              {{ $t("bookings.booking_mileage") }}:
              {{ booking.booking_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.booking_mileage > 0">
              {{ $t("bookings.average_mileage") }}:
              {{ parseFloat((booking.booking_mileage / booking.days).toFixed(2)) }}
              {{ $t("bookings.km_day") }}
            </div>
          </v-flex>
        </v-card-text>
        <v-card-actions class="mt-0 pt-0">
          <v-btn
            color="info"
            v-if="booking && booking.id"
            block
            @click="replaceToBooking(booking)"
          >
          {{ $t('bookings.description_2') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import firebase from "firebase/app";
// import "firebase/auth";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import PullToRefresh from "pulltorefreshjs";
// moment.locale("ru");
/* eslint-disable */

export default {
  data() {
    return {
      bookings: [],
      review_booking: {},
      events: [],
      payments: [],
      cars: [],
      all_cars: [],
      filtred_bookings_car_ids: [],
      states: [
        { id: 1, state: this.$t('states.normal') },
        { id: 2, state: this.$t('states.in_service') },
        { id: 3, state: this.$t('states.critical') },
        { id: 4, state: this.$t('states.long_rent') },
        { id: 5, state: this.$t('states.no_rent') },
        { id: 6, state: this.$t('states.service_needed') }
      ],
      th_color: "",
      showEconom: false,
      showMiddle: false,
      showBusiness: false,
      showCommercial: false,
      days: "",
      dialog: false,
      booking: {},
      giftsPanel: false,
      days_count: -2,
      number_days: 30,
      headers: [],
      items: [],
      dialog: false,
      isLoading: false,
      error: "",
      sort_class: null,
      sort_cars: null,
      select: [],
      filtered_bookings: [],
      isMobile: false,
      debouncedGetData: null, // Для debounce запросов к API
      searchQuery: '', // Добавляем новое поле
    };
  },
  mounted() {
    PullToRefresh.destroyAll();
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    // Создаем debounced версию getDataFromApi
    this.debouncedGetData = this.debounce((start, end) => {
      this.getDataFromApi(start, end);
    }, 300);

    if (this.$store.getters.isLoggedIn) {
      let start_date = moment()
        .add(this.days_count, "days")
        .format("DD-MM-YYYY");
      let end_date = moment()
        .add(this.days_count + this.number_days, "days")
        .format("DD-MM-YYYY");

      this.getDataFromApi(start_date, end_date);
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    showEconom() {
      // Усложнить и при выборе совместно другого класса показывать оба
      if (this.showEconom) {
        this.cars = this.cars.filter(car => car.car_class == "Эконом");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function(arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach(id =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showMiddle() {
      if (this.showMiddle) {
        this.cars = this.cars.filter(car => car.car_class == "Средний");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function(arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach(id =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showBusiness() {
      if (this.showBusiness) {
        this.cars = this.cars.filter(car => car.car_class == "Бизнес");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function(arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach(id =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showCommercial() {
      if (this.showCommercial) {
        this.cars = this.cars.filter(car => car.car_class == "Коммерческий");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function(arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach(id =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    days_count() {
      let start_date = moment()
        .add(this.days_count, "days")
        .format("DD-MM-YYYY");
      let end_date = moment()
        .add(this.days_count + this.number_days, "days")
        .format("DD-MM-YYYY");
      // Используем debounced версию при изменении дат
      this.debouncedGetData(start_date, end_date);
    },
    // number_days() {
    //   let start_date = moment()
    //     .add(this.days_count, "days")
    //     .format("DD-MM-YYYY");
    //   let end_date = moment()
    //     .add(this.days_count + this.number_days, "days")
    //     .format("DD-MM-YYYY");
    //   this.getDataFromApi(start_date, end_date);
    // }
  },
  computed: {
    start_date() {
      return moment().add(this.days_count, "days");
    },
    end_date() {
      return moment().add(this.days_count + this.number_days, "days");
    },
    all_days() {
      return moment.duration(this.end_date.diff(this.start_date)).asDays();
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    filteredCars() {
      if (!this.searchQuery) return this.cars;

      const query = this.searchQuery.toLowerCase();
      return this.cars.filter(car => {
        return car.code.toLowerCase().includes(query) ||
               (car.name && car.name.toLowerCase().includes(query));
      });
    },
  },
  methods: {
    // Добавляем метод debounce
    debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },
    // Оптимизируем метод получения данных
    async getDataFromApi(start, end) {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const response = await this.axios.get(
            `/api/v1/index_for_calendar?start=${start}&end=${end}`,
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken
              }
            }
          );

          // Очищаем массивы перед обновлением
          this.cars = [];
          this.all_cars = [];

          // Обрабатываем машины
          response.data.cars.data.forEach((car) => {
            const carData = car.attributes;
            carData.bookings = []; // Инициализируем пустой массив броней
            this.cars.push(carData);
            this.all_cars.push(carData);
          });

          // Сортируем машины
          const sortCars = (a, b) => a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0;
          this.cars.sort(sortCars);
          this.all_cars.sort(sortCars);

          // Обрабатываем бронирования
          response.data.bookings.data.forEach((element) => {
            const booking = element.attributes;
            booking.counts = [];
            booking.gifts = [];

            // Обрабатываем counts и gifts
            if (response.data.bookings.included) {
              element.relationships.counts.data.forEach((item) => {
                const included_counts = response.data.bookings.included.filter(
                  (count) => count.id === item.id
                );
                booking.counts.push(...included_counts.map(count => count.attributes));
              });

              element.relationships.gifts.data.forEach((item) => {
                const included_gifts = response.data.bookings.included.filter(
                  (gift) => gift.id === item.id
                );
                booking.gifts.push(...included_gifts.map(gift => gift.attributes));
              });
            }

            // Добавляем бронирование к соответствующей машине
            const carIndex = this.cars.findIndex(car => car.id === booking.car_id);
            if (carIndex >= 0) {
              this.cars[carIndex].bookings.push(booking);
            }
          });

        } catch (error) {
          this.setError(error, this.$t('errors.bookings_load'));
        } finally {
          this.isLoading = false;
        }
      }
    },
    dates(day) {
      let number = moment()
        .add(day, "days")
        .format("DD");
      let weekday = moment()
        .add(day, "days")
        .format("dd");
      return `${number}<br>${weekday}`;
    },
    month(day) {
      return moment()
        .add(day, "days")
        .format("MM");
      // Показывать только в начале месяца
      // if (
      //   moment()
      //     .add(day, "days")
      //     .isSame(
      //       moment()
      //         .add(day, "days")
      //         .startOf("month"),
      //       "day"
      //     )
      // ) {
      //   return moment()
      //     .add(day, "days")
      //     .format("MMM");
      // }
    },
    datesCheck(day, bookings) {
      let result = false;
      let now = moment().format("DD-MM-YYYY");
      bookings.forEach(booking => {
        if (
          moment(now, "DD-MM-YYYY")
            .add(day, "days")
            .isBetween(
              moment(booking.start_date, "DD-MM-YYYY H:mm").subtract(
                2,
                "days"
              ),
              moment(booking.end_date, "DD-MM-YYYY H:mm").subtract(
                1,
                "days"
              ),
              "day"
            )
        ) {
          result = true;
        }
      });
      return result;
    },
    calcDays(day, bookings) {
      let result = false;
      bookings.forEach(booking => {
        if (booking.active) {
          let now = moment().format("DD-MM-YYYY");
          if (
            moment(now, "DD-MM-YYYY")
              .add(day, "days")
              .isBetween(
                moment(booking.start_date, "DD-MM-YYYY H:mm").subtract(
                  2,
                  "days"
                ),
                moment(booking.end_date, "DD-MM-YYYY H:mm"),
                "day"
              )
          ) {
            result = true;
          }
        }
      });
      return result;
    },
    calculateRentPaymentsSum(booking) {
      if (booking && booking.counts && booking.counts.length > 0) {
        let paid = 0;
        booking.counts.forEach((count) => {
          if (count.group == "Оплата аренды") {
            // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
            if (count.completed) {
              if (count.operation) {
                paid += count.sum;
              } else {
                paid -= count.sum;
              }
            }
          }
        });
        return parseFloat(paid.toFixed(2)) || 0;
      } else {
        return 0;
      }
    },
    paidDays(booking) {
      let days =
        Math.round(
          this.calculateRentPaymentsSum(booking) /
          (booking.rental_cost / booking.days)
        ) || 0;
      if (booking.gifts && booking.gifts.length > 0) {
        let gift_days = 0;
        let gift_paid = 0;
        booking.gifts.forEach((gift) => {
          if (gift.gift_type == 0) {
            gift_days += gift.count;
            gift_paid += gift.count * gift.price;
          }
        });
        let fact_days = days - gift_days;
        days =
          Math.round(
            (this.calculateRentPaymentsSum(booking) /
              ((booking.rental_cost + gift_paid) / days)) *
            100
          ) / 100;
      }
      return days;
    },
    checkDayPaid(day, bookings) {
      // Ищем бронь на этот день
      let finded_bookings = [];
      // moment(booking.start_date, "DD-MM-YYYY H:mm").set('hour', 12).subtract(1, "days"),
      // moment(booking.end_date, "DD-MM-YYYY H:mm").subtract(1, "days"),
      bookings.forEach(booking => {
        if (
          moment()
            .add(day, "days")
            .isBetween(
              moment(booking.start_date, "DD-MM-YYYY H:mm").set('hour', 0).subtract(1, "days"),
              moment(booking.end_date, "DD-MM-YYYY H:mm").set('hour', 0).subtract(1, "days"),
              "hours"
            )
        ) {
          finded_bookings.push(booking);
        }
      });
      // Если бронь нашлась
      if (finded_bookings.length > 0) {
        // / ${this.calculateRentPaymentsSum(finded_bookings[0])}
        if(finded_bookings[0] && finded_bookings[0].technical) {
          if (finded_bookings[0].repair) {
            return 'repair_booking';
          }
          if (finded_bookings[0].technical) {
            return 'technical_booking';
          }
        } else {
          // if(this.paidDays(finded_bookings[0]) == finded_bookings[0].days || this.day_is_payed(day + 2, finded_bookings[0])) {
          let checkDate = moment().add(day, 'days');
          const today = moment();
          if(this.paidDays(finded_bookings[0]) == finded_bookings[0].days || this.day_is_payed(day + 2, finded_bookings[0])) {
            if (!this.day_is_payed(day + 2, finded_bookings[0])) {
              if (finded_bookings[0].days > 1) {
                checkDate = moment().add(day + 1, 'days');
              }
              return checkDate.isSameOrBefore(today, 'day') ?
                'not_paided_day' :
                'not_paided_day_future';
            }
            return "paided_day";
          } else {
            if (finded_bookings[0].days > 1) {
              checkDate = moment().add(day + 1, 'days');
            }
            return checkDate.isSameOrBefore(today, 'day') ? 'not_paided_day' : 'not_paided_day_future';
          }
        }
      } else {
        return "";
      }
    },
    day_is_payed(day, booking) {
      let checked_date = moment().add(day, "days");
      let start_date = moment(booking.start_date, "DD-MM-YYYY H:mm");
      let payed_days = this.paidDays(booking);
      let payed_until = start_date.add(payed_days, "days");
      if (payed_until.isSameOrAfter(checked_date, "day")) {
        return true;
      } else {
        return false;
      }
    },
    loadingPercent(car_id) {
      // Используем nextTick чтобы дождаться обновления DOM
      return this.$nextTick().then(() => {
        let car_tr = document.getElementById(`car_id_${car_id}`);
        if (!car_tr) {
          return 0;
        }

        // Получаем все ячейки с бронированиями
        let paided_days = car_tr.getElementsByClassName("paided_day").length;
        let not_paided_days = car_tr.getElementsByClassName("not_paided_day").length;
        let not_paided_days_future = car_tr.getElementsByClassName("not_paided_day_future").length;
        let technical_days = car_tr.getElementsByClassName("technical_booking").length + car_tr.getElementsByClassName("repair_booking").length;

        // Общее количество дней с бронированиями
        let booking_days = paided_days + not_paided_days + not_paided_days_future;

        // Защита от деления на ноль
        if (this.number_days === 0) return 0;

        // Округляем результат до целого числа
        return Math.round((booking_days / this.number_days) * 100);
      });
    },
    showBooking(day, bookings) {
      let finded_bookings = [];
      bookings.forEach(booking => {
        if (
          moment()
            .add(day, "days")
            .isBetween(
              moment(booking.start_date, "DD-MM-YYYY").subtract(2, "days"),
              moment(booking.end_date, "DD-MM-YYYY").subtract(1, "days"),
              "days"
            )
        ) {
          finded_bookings.push(booking);
        }
      });
      if (finded_bookings.length > 0) {
        this.booking = finded_bookings[0];
        this.dialog = true;
      }
    },
    checkToday(day) {
      if (moment().add(day, "days").isSame(moment(), "day")) {
        return ' error white--text';
      } else {
        return '';
      }
    },
    calculatePayments(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        if(booking.counts) {
          booking.counts.forEach((count) => {
            // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
            if (count.completed) {
              if (count.operation) {
                paid += count.sum;
              } else {
                paid -= count.sum;
              }
            }
          });
        }
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += this.currentDeposit();
        }
        if (paid == booking_sum) {
          return self.$t("bookings.paid");
        } else {
          return paid - booking_sum >= 0
            ? `+ ${parseFloat((paid - booking_sum).toFixed(2))}`
            : ` ${parseFloat((paid - booking_sum).toFixed(2))}`;
        }
      }
    },
    calculatePaymentsSum(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0.0;
        // Проверяем что значения числовые
        let check_number_rental_cost = Number(booking.rental_cost) === booking.rental_cost;
        let check_number_delivery = Number(booking.delivery) === booking.delivery;
        let check_number_delivery_end = Number(booking.delivery_end) === booking.delivery_end;
        let check_number_equipment = Number(booking.equipment) === booking.equipment;
        let check_number_insurance = Number(booking.insurance) === booking.insurance;
        let check_number_clean_payment = Number(booking.clean_payment) === booking.clean_payment;
        let check_number_add_drivers_cost = Number(booking.add_drivers_cost) === booking.add_drivers_cost;
        let check_number_hours_cost = Number(booking.hours_cost) === booking.hours_cost;
        let check_number_mileage_cost = Number(booking.mileage_cost) === booking.mileage_cost;
        let check_number_damage = Number(booking.damage) === booking.damage;
        let check_number_gas = Number(booking.gas) === booking.gas;
        let check_number_fine = Number(booking.fine) === booking.fine;
        let check_number_other = Number(booking.other) === booking.other;
        let check_number_other_end = Number(booking.other_end) === booking.other_end;
        let rental_cost = check_number_rental_cost ? booking.rental_cost : 0;
        let delivery = check_number_delivery ? booking.delivery : 0;
        let delivery_end = check_number_delivery_end ? booking.delivery_end : 0;
        let equipment = check_number_equipment ? booking.equipment : 0;
        let insurance = check_number_insurance ? booking.insurance : 0;
        let clean_payment = check_number_clean_payment ? booking.clean_payment : 0;
        let add_drivers_cost = check_number_add_drivers_cost ? booking.add_drivers_cost : 0;
        let hours_cost = check_number_hours_cost ? booking.hours_cost : 0;
        let mileage_cost = check_number_mileage_cost ? booking.mileage_cost : 0;
        let damage = check_number_damage ? booking.damage : 0;
        let gas = check_number_gas ? booking.gas : 0;
        let fine = check_number_fine ? booking.fine : 0;
        let other = check_number_other ? booking.other : 0;
        let other_end = check_number_other_end ? booking.other_end : 0;
        let hours_cost_end = booking.hours_cost_end;
        let booking_sum =
          parseFloat((rental_cost +
            delivery +
            delivery_end +
            equipment +
            insurance +
            clean_payment +
            add_drivers_cost +
            hours_cost +
            mileage_cost +
            damage +
            gas +
            fine +
            other +
            other_end +
            hours_cost_end).toFixed(2));
        if(booking.counts) {
          booking.counts.forEach((count) => {
            // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
            if (count.completed) {
              if (count.operation) {
                paid += parseFloat((count.sum).toFixed(2));
              } else {
                paid -= parseFloat((count.sum).toFixed(2));
              }
            }
          });
        }
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += this.currentDeposit();
        }
        return parseFloat(paid.toFixed(2));
      }
    },
    currentDeposit() {
      if (!this.booking.hourly && !this.booking.monthly) {
        let check_number = Number(this.booking.deposit) === this.booking.deposit;
        return check_number ? parseFloat(this.booking.deposit.toFixed(2)) : 0;
      } else if (this.booking.hourly) {
        let check_number = Number(this.booking.hourly_deposit) === this.booking.hourly_deposit;
        return check_number ? parseFloat(this.booking.hourly_deposit.toFixed(2)) : 0;
      } else if (this.booking.monthly) {
        let check_number = Number(this.booking.monthly_deposit) === this.booking.monthly_deposit;
        return check_number ? parseFloat(this.booking.monthly_deposit.toFixed(2)) : 0;
      } else {
        let check_number = Number(this.booking.deposit) === this.booking.deposit;
        return check_number ? parseFloat(this.booking.deposit.toFixed(2)) : 0;
      }
    },
    titleForDay(day, bookings) {
      switch (this.checkDayPaid(day, bookings)) {
        case 'paided_day':
          return this.$t('bookings.paided_day');
        case 'not_paided_day':
          return this.$t('bookings.not_paided_day');
        case 'not_paided_day_future':
          return this.$t('bookings.not_paided_day_future');
        case 'technical_booking':
          return this.$t('bookings.technical_booking');
        case 'repair_booking':
          return this.$t('bookings.repair_booking');
        default:
          return '';
      }
    },
    updateCarState(id, state) {
      if ((id, state)) {
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/cars/${id}`,
            {
              cars: {
                state: state
              }
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken
              }
            }
          )
          .then(() => {
            this.cars.find(car => car.id == id).state = state;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t('cars.change_state')
            });
          })
          .catch(error => {
            this.isLoading = false;
            this.setError(error, this.$t('errors.change_car_state'));
          })
          .finally(() => (this.isLoading = false));
      }
    },
    setCarStateClass(state) {
      if (state == 1) {
        return "green";
      } else if (state == 2) {
        return "black";
      } else if (state == 3) {
        return "red";
      } else if (state == 4) {
        return "pink";
      } else if (state == 5) {
        return "blue";
      } else if (state == 6) {
        return "orange";
      }
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("LL H:mm");
    },
    checkMobile() {
      if (this.$vuetify.breakpoint.name == "xs" || this.$vuetify.breakpoint.name == "sm") {
        this.isMobile = true;
        return true;
      } else {
        this.isMobile = false;
        return false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    highlightColumn(columnIndex) {
      const table = document.querySelector('.v-data-table');
      if (!table) return;

      const rows = table.querySelectorAll('tr');

      rows.forEach(row => {
        const cell = row.cells[columnIndex];
        if (cell && !cell.classList.contains('paided_day') &&
            !cell.classList.contains('not_paided_day') &&
            !cell.classList.contains('not_paided_day_future') &&
            !cell.classList.contains('technical_booking')) {
          cell.style.backgroundColor = this.$vuetify.theme.dark ?
            'rgba(255, 255, 255, 0.05)' :
            'rgba(0, 0, 0, 0.05)';
        }
      });
    },
    removeHighlight() {
      const cells = document.querySelectorAll('.v-data-table td, .v-data-table th');
      cells.forEach(cell => {
        if (!cell.classList.contains('paided_day') &&
            !cell.classList.contains('not_paided_day') &&
            !cell.classList.contains('technical_booking')) {
          cell.style.backgroundColor = '';
        }
      });
    }
  },
  components: {
    AsyncValue: {
      props: ['value'],
      data() {
        return {
          resolvedValue: null
        }
      },
      watch: {
        value: {
          immediate: true,
          handler(promise) {
            if (promise && typeof promise.then === 'function') {
              promise.then(value => {
                this.resolvedValue = value;
              });
            } else {
              this.resolvedValue = promise;
            }
          }
        }
      },
      template: `<span>{{ resolvedValue !== null ? resolvedValue : '0' }}%</span>`    }
  }
};
</script>
<style scoped>
.calendarTable {
  border-collapse: collapse;
  position: relative;
  border: 1px solid grey;
}

.calendarTable tr:hover {
  background-color: lightgrey;
}

/* Стили для заголовка таблицы в светлой теме */
.v-data-table thead th:not(.error) {
  background-color: #FFFFFF !important;
}

/* Стили для ячейки с классом error в светлой теме */
.v-data-table thead th.error {
  background-color: #ff5252 !important;
  color: #ffffff !important;
}

/* Стили для заголовка таблицы */
.theme--dark .v-data-table thead th:not(.error) {
  background-color: #1E1E1E !important;
}

/* Стили для ячейки с классом error */
.theme--dark .v-data-table thead th.error {
  background-color: #ff5252 !important;
  color: #ffffff !important;
}

/* Сохраняем существующие стили для ячеек */
.v-data-table td,
.v-data-table th {
  border-left: 1px solid rgba(128, 128, 128, 0.3) !important;
  border-right: 1px solid rgba(128, 128, 128, 0.3) !important;
  padding: 0 !important;
  min-width: 30px !important;
  max-width: 30px !important;
  width: 30px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Для темной темы границы */
.theme--dark .v-data-table td,
.theme--dark .v-data-table th {
  border-left: 1px solid rgba(128, 128, 128, 0.2) !important;
  border-right: 1px solid rgba(128, 128, 128, 0.2) !important;
}

.sticky-th th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.redCell {
  background-color: #4caf50;
  justify-content: center;
  align-items: center;
}
.vuecal__event.booking .vuecal__event-time {
  display: none;
  align-items: center;
}
.vuecal__no-event {
  display: none;
}
.not_paided_day {
  color: white;
  background: rgba(255, 0, 0, 0.5);
  cursor: pointer;
}
.not_paided_day:hover {
  color: white;
  background: rgba(255, 0, 0, 1);
  cursor: pointer;
}
.paided_day {
  color: white;
  background-color: rgba(21, 180, 21, 0.7);
  cursor: pointer;
}
.paided_day:hover {
  color: white;
  background-color: rgba(21, 180, 21, 1);
  cursor: pointer;
}
.not_paided_day_future {
  color: white;
  background-color: rgba(255, 0, 0, 0.3);
  cursor: pointer;
}
.not_paided_day_future:hover {
  color: white;
  background-color: rgba(255, 0, 0, 0.7) !important;
  cursor: pointer;
}

.technical_booking {
  color: white;
  background-color: rgba(101, 103, 101, 0.5);
  cursor: pointer;
}
.technical_booking:hover {
  color: white;
  background-color: rgba(101, 103, 101, 1);
  cursor: pointer;
}

.today {
  background-color: rgba(37, 38, 37, 0.3);
  color: red !important;
  border-color: red !important;
}

/* Добавляем стили для перекрестия */
.v-data-table tbody tr td {
  position: relative;
}

.v-data-table tbody tr td:hover::before {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 10000px;
  left: 0;
  top: -5000px;
  pointer-events: none;
  z-index: 1;
}

/* Чтобы подсветка не перекрывала фиксированные колонки */
.v-data-table td:nth-child(1)::before,
.v-data-table td:nth-child(2)::before {
  display: none;
}

/* Убедимся что контент ячейки поверх подсветки */
.v-data-table tbody tr td > * {
  position: relative;
  z-index: 2;
}

/* Для темной темы */
.theme--dark .v-data-table tbody tr td:hover::before {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Общие стили для таблицы */
.v-data-table {
  border-collapse: collapse !important;
}

/* Единые стили для всех ячеек, включая шапку */
.v-data-table td,
.v-data-table th {
  border: 0.5px solid rgba(128, 128, 128, 0.2) !important;
  padding: 0 !important;
  min-width: 30px !important;
  max-width: 30px !important;
  width: 30px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Только фон для шапки */
.v-data-table thead th {
  background-color: #f5f5f5 !important;
  font-weight: normal !important;
}

/* Для темной темы */
.theme--dark .v-data-table thead th {
  background-color: #1E1E1E !important;
}

.theme--dark .v-data-table td,
.theme--dark .v-data-table th {
  border: 0.5px solid rgba(128, 128, 128, 0.15) !important;
}

/* Стили для repair_booking */
.repair_booking {
  background-color: rgba(255, 152, 0, 0.3) !important;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

/* При наведении на ячейку */
.repair_booking:hover {
  background-color: rgba(255, 152, 0, 1) !important;
}

/* Темная тема */
.theme--dark .repair_booking {
  background-color: rgba(255, 152, 0, 0.2) !important;
}

/* При наведении на ячейку в темной теме */
.theme--dark .repair_booking:hover {
  background-color: rgba(255, 152, 0, 0.8) !important;
}

.loading-calendar-table td:first-child,
.loading-calendar-table th:first-child {
  width: 30px !important;
  max-width: 60px !important;
  min-width: 30px !important;
}

.loading-calendar-table td:first-child a {
  display: block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

/* Стили при наведении на строку */
.loading-calendar-table tr:hover td.paided_day,
.loading-calendar-table tr:hover td.not_paided_day,
.loading-calendar-table tr:hover td.not_paided_day_future,
.loading-calendar-table tr:hover td.technical_booking,
.loading-calendar-table tr:hover td.repair_booking {
  border: 0.5px solid rgba(0, 0, 0, 0.5) !important;
}

/* Стили для темной темы при наведении */
.theme--dark .loading-calendar-table tr:hover td.paided_day,
.theme--dark .loading-calendar-table tr:hover td.not_paided_day,
.theme--dark .loading-calendar-table tr:hover td.not_paided_day_future,
.theme--dark .loading-calendar-table tr:hover td.technical_booking,
.theme--dark .loading-calendar-table tr:hover td.repair_booking {
  border: 0.5px solid rgba(255, 255, 255, 0.5) !important;
}
</style>

