<template>
  <div>
    <!-- Верхняя панель инструментов -->
    <v-toolbar :height="toolbar_height" text :color="$vuetify.theme.dark ? '' : 'white'" :loading="isLoading">
      <v-row>
        <v-col v-if="toolbar_height > 40" cols="12" lg="6" md="6" sm="12" xs="12" class="d-flex align-center">
          <v-btn small class="mx-1" text @click="$emit('go-back')">
            {{ $t("custom.goback") }}
          </v-btn>
          <v-btn small class="mx-1" text color="green darken-1" to="/calendars/new">
            {{ $t("custom.to_calendar") }}
          </v-btn>
          <v-btn small class="mx-1" text color="blue darken-1" to="/bookings">
            {{ $t("bookings.to_list") }}
          </v-btn>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12" :class="isMobile ? 'd-flex justify-center align-center' : 'd-flex justify-end align-center'">
          <!-- Кнопка истории -->
          <v-btn icon @click="$emit('get-history')" v-tooltip="{
            content: $t('tooltips.booking_history'),
            placement: 'bottom-center',
            classes: ['black--text', 'white'],
            targetClasses: ['it-has-a-tooltip'],
            delay: { show: 500, hide: 500 },
          }">
            <v-icon>mdi-clipboard-text-clock-outline</v-icon>
          </v-btn>

          <!-- Кнопка скачать iCalendar -->
          <v-btn icon @click="$emit('download-icalendar', booking.id)" v-tooltip="{
            content: $t('tooltips.download_icalendar'),
            placement: 'bottom-center',
            classes: ['black--text', 'white'],
            targetClasses: ['it-has-a-tooltip'],
            delay: { show: 500, hide: 500 },
          }">
            <v-icon>mdi-calendar-export</v-icon>
          </v-btn>

          <!-- Индикатор подключения -->
          <div :class="'pulse mt-3 mx-2 ' + (connected ? 'pulse_success' : 'pulse_error')"
              v-tooltip="{
                content: connected ? $t('custom.connected') : $t('custom.disconnected'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: { show: 500, hide: 500 },
              }">
          </div>

          <!-- Кнопка помощи -->
          <v-btn icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${$root.$i18n.locale}/categories/2`"
              target="_blank">
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <!-- Заголовок с состоянием бронирования -->
    <v-card-title class="pt-0 pb-0 start-tour-booking-1" v-if="booking && !isLoading">
      <v-row :class="!booking.technical ? 'd-flex align-center mt-1' : 'd-flex align-center blue-grey lighten-4 mt-1'">
        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="d-flex align-center mb-1">
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" :class="isMobile ? 'd-flex align-center justify-center' : 'd-flex align-center'">
              <!-- Чип для бронирования с агрегатора -->
              <v-chip v-if="booking.vseprokaty_id" class="ma-2"
                      :color="booking.active ? 'warning' : 'secondary'"
                      text-color="white">
                <v-avatar left color="secondary darken-2" v-tooltip="{
                  content: $t('agregator.from_agregator'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: { show: 500, hide: 500 },
                }">
                  <v-icon>mdi-alpha-a-circle-outline</v-icon>
                </v-avatar>
                <v-avatar left v-if="booking.fast_booking" color="error darken-2" v-tooltip="{
                  content: $t('agregator.fast_booking'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: { show: 500, hide: 500 },
                }">
                  <v-icon>mdi-lightning-bolt-circle</v-icon>
                </v-avatar>
                {{ $t("bookings.booking") }} {{ $t("custom.number") }}{{ booking.id }}
              </v-chip>

              <!-- Заголовок для обычного бронирования -->
              <h3 v-else :class="isMobile ? 'headline text-center' : 'headline text-center mx-2'"
                  id="registration-step-64" nowrap>
                {{ $t("bookings.booking") }} {{ $t("custom.number") }}{{ booking.id }}
              </h3>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" :class="isMobile ? 'd-flex align-center justify-center' : 'd-flex align-center justify-end'">
              <!-- Меню состояний бронирования -->
              <v-menu offset-y v-for="state in statesList" :key="state.value" v-if="booking.state === state.value">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" outlined small :color="getStateColor(state.value)" :loading="isLoadingSaveState">
                    <v-icon left>{{ getStateIcon(booking.state) }}</v-icon>
                      {{ getStateTooltip(booking.state) }}
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item v-for="(menuState, index) in bookings_states"
                  :key="index"
                  @click="$emit('save-state', menuState.value, booking.id)">
                    <v-list-item-icon>
                      <v-icon :color="getStateColor(menuState.value)">{{ getStateIcon(menuState.value) }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ menuState.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <v-skeleton-loader v-else type="list-item"></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: 'BookingHeader',
  props: {
    booking: {
      type: Object,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isLoadingSaveState: {
      type: Boolean,
      default: false
    },
    connected: {
      type: Boolean,
      required: true
    },
    bookings_states: {
      type: Array,
      required: true
    }
  },
  computed: {
    statesList() {
      return [
        { value: 'Новая', color: 'primary' },
        { value: 'В обработке', color: 'primary' },
        { value: 'Отказ клиента', color: 'danger' },
        { value: 'Нет машин', color: 'secondary' },
        { value: 'Недозвон', color: 'warning' },
        { value: 'Ожидает ответа клиента', color: 'pink' },
        { value: 'Ожидает оплаты', color: 'lime' },
        { value: 'Активная', color: 'light-blue' },
        { value: 'Отмена', color: 'brown' },
        { value: 'Отъездила', color: 'light-green' },
        { value: 'Вернуть залог', color: 'blue' },
        { value: 'Подтверждена', color: 'success' },
        { value: 'Не подтверждена', color: 'warning' },
        { value: 'Другое', color: 'secondary' }
      ]
    },
    toolbar_height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 40
        case 'sm': return 40
        case 'md': return 50
        case 'lg': return 50
        case 'xl': return 50
        default: return 50
      }
    },
  },
  methods: {
    getStateIcon(state) {
      switch(state) {
        case 'Новая': return 'mdi-new-box'
        case 'Подтверждена': return 'mdi-check-all'
        case 'Не подтверждена': return 'mdi-cellphone-basic'
        case 'В обработке': return 'mdi-autorenew'
        case 'Отказ клиента': return 'mdi-account-remove-outline'
        case 'Нет машин': return 'mdi-car-off'
        case 'Недозвон': return 'mdi-phone-off-outline'
        case 'Ожидает ответа клиента': return 'mdi-account-question-outline'
        case 'Ожидает оплаты': return 'mdi-currency-usd-off'
        case 'Активная': return 'mdi-alpha-a-circle-outline'
        case 'Отмена': return 'mdi-cancel'
        case 'Отъездила': return 'mdi-garage-variant'
        case 'Вернуть залог': return 'mdi-keyboard-return'
        default: return 'mdi-head-question-outline'
      }
    },
    getStateTooltip(state) {
      const translations = {
        'Новая': this.$t('bookings_states.new'),
        'В обработке': this.$t('bookings_states.in_work'),
        'Отказ клиента': this.$t('bookings_states.decline'),
        'Нет машин': this.$t('bookings_states.no_cars'),
        'Недозвон': this.$t('bookings_states.abandoned'),
        'Ожидает ответа клиента': this.$t('bookings_states.wait_answer'),
        'Ожидает оплаты': this.$t('bookings_states.wait_pay'),
        'Активная': this.$t('bookings_states.active'),
        'Отмена': this.$t('bookings_states.cancel'),
        'Отъездила': this.$t('bookings_states.rided'),
        'Вернуть залог': this.$t('bookings_states.return_deposit'),
        'Подтверждена': this.$t('bookings_states.accepted'),
        'Не подтверждена': this.$t('bookings_states.not_accepted'),
        'Другое': this.$t('bookings_states.other')
      }
      return translations[state] || state
    },
    getStateColor(state) {
      switch(state) {
        case 'Новая': return 'primary'
        case 'Подтверждена': return 'success'
        case 'Не подтверждена': return 'warning'
        case 'В обработке': return 'primary'
        case 'Отказ клиента': return 'error'
        case 'Нет машин': return 'secondary'
        case 'Недозвон': return 'warning'
        case 'Ожидает ответа клиента': return 'pink'
        case 'Ожидает оплаты': return 'lime'
        case 'Активная': return 'light-blue'
        case 'Отмена': return 'brown'
        case 'Отъездила': return 'light-green'
        case 'Вернуть залог': return 'blue'
        default: return 'secondary'
      }
    },
  }
}
</script>

<style scoped>
.pulse {
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
}

.pulse.pulse_success {
  background: rgba(0, 179, 0, 1);
  box-shadow: 0 0 0 0 rgba(0, 179, 0, 1);
  animation: pulse-green 2s infinite;
}

.pulse.pulse_error {
  background: rgb(239, 24, 24);
  box-shadow: 0 0 0 0 rgb(239, 24, 24);
  animation: pulse-error 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

@keyframes pulse-error {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 51, 51, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(217, 51, 51, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 51, 51, 0);
  }
}
</style>
