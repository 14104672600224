<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'" class="align-center" :height="isMobile ? 130 : ''">
        <v-flex :class="isMobile ? '' : 'd-flex align-center'">
          <v-switch v-if="ygibdd_active" v-model="showAllFines" value :label="showAllFines ? $t('fines.cars_fines') : $t('fines.all_fines')
            " class="mt-0 pt-0" hide-details></v-switch>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('custom.search')" single-line clearable
            hide-details></v-text-field>
          <v-flex :class="isMobile
            ? 'd-flex align-center justify-center mt-2'
            : 'd-flex align-center justify-center'
            ">
            <v-btn small color="success" to="/new_fine">{{
              $t("fines.new")
              }}</v-btn>
            <v-icon v-if="
              filtredFines &&
              filtredFines.length > 0
            " @click="toXlsx" class="ml-2" style="cursor: pointer" color="success">
              mdi-file-excel-outline
            </v-icon>
            <v-btn icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/categories/4/guides/16`" target="_blank">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </v-flex>
        </v-flex>
      </v-toolbar>
      <v-data-table :headers="ygibdd_active ? headers : headers_not_ygibdd" :items="filtredFines" :loading="isLoading"
        :loading-text="$t('custom.loading_table')" :search="search" :server-items-length.sync="total"
        :options.sync="tableOptions" :page.sync="page" item-key="id" :sort-desc="[true]" mobile-breakpoint="100"
        :items-per-page="50" dense :footer-props="{
          pageText: `{0} ${$t('custom.of')} ${total}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 30, 50, 150, 300, -1],
        }">
        <template slot="item" slot-scope="props">
          <tr :class="selectColor(props.item.pay_status_desc)">
            <td class="text-center">{{ props.item.number }}</td>
            <td class="text-center" v-if="props.item.car_code && props.item.car_id" v-tooltip="{
              content: $t('cars.code'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">
              <router-link :to="{
                name: 'CarCard',
                params: { id: props.item.car_id },
              }">{{ props.item.car_code }}</router-link>
            </td>
            <td class="text-center" v-else>-</td>
            <td class="justify-center px-0" nowrap>
              <v-icon class="green--text mr-1" @click="paymentCompanyStart(props.item.number, props.item.id)" v-tooltip="{
                content: $t('fines.pay_company'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                mdi-cash-multiple
              </v-icon>
              <v-icon class="warning--text mr-1" @click="paymentClientStart(props.item.number, props.item.id)"
                v-tooltip="{
                  content: $t('tooltips.pay_fine'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }">
                mdi-account-arrow-right-outline
              </v-icon>
              <v-icon class="mr-1" @click="editFine(props.item)">
                mdi-pencil-outline
              </v-icon>
              <v-icon class="mr-1" v-if="props.item.uin && props.item.ygibdd_id" v-tooltip="{
                content: $t('fines.download'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }" @click="downloadItem(props.item)">
                mdi-download-circle-outline
              </v-icon>
              <v-icon v-if="checkAdmin() && !props.item.uin" @click="deleteItem(props.item)">
                mdi-delete-forever
              </v-icon>
              <v-icon v-if="props.item.client_id && props.item.booking_id" class="green--text mr-1"
                @click="sendNotificationStart(props.item)" v-tooltip="{
                  content: $t('fines.send_client'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }">
                mdi-email-arrow-right
              </v-icon>
            </td>
            <td class="text-center" v-if="props.item.date">
              {{ returnDate(props.item.date) }}
            </td>
            <td class="text-center" v-else>
              {{ formatCreatedAtWithYear(props.item.violation_date) }}
            </td>
            <!-- Вынесен -->
            <!-- <td class="text-center">
                    {{ formatCreatedAtNoTime(props.item.decision_date) }}
                  </td> -->
            <td v-if="ygibdd_active && showSale(props.item.discount_date)" class="text-center" :style="checkLastDaySale(props.item.discount_date) ? 'color: red;' : ''
              ">
              {{ formatCreatedAtNoTime(props.item.discount_date) }}
            </td>
            <td class="text-center" v-else-if="ygibdd_active && !showSale(props.item.discount_date)">
              -
            </td>
            <td v-if="ygibdd_active" class="text-center" v-tooltip="{
              content: `${props.item.article_description}`,
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">
              {{ props.item.article }}
            </td>
            <td class="text-center" :style="user_role == 'guest' || user_role == 'partner'
              ? 'display: none;'
              : ''
              ">
              <router-link :to="{ path: `/clients/${props.item.client_id}` }">{{
                props.item.client_name
                }}</router-link>
            </td>
            <td class="text-center">
              <v-icon v-if="props.item.auto_select" color="error" small class="mx-1" v-tooltip="{
                content: $t('tooltips.fine_auto_selected'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                mdi-robot-outline
              </v-icon>
              <router-link :to="{ path: `/bookings/${props.item.booking_id}` }">{{ props.item.booking_id
                }}</router-link>
            </td>
            <td v-if="ygibdd_active" class="text-center">
              {{ props.item.amount }}{{ currency }}
            </td>
            <td v-if="ygibdd_active" class="text-center">
              <v-icon v-if="props.item.is_revoked" class="text-center" color="warning" v-tooltip="{
                content: $t('tooltips.fine_declined'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                mdi-plus
              </v-icon>
              <v-icon v-else class="text-center" color="grey" v-tooltip="{
                content: $t('tooltips.fine_not_declined'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                mdi-minus
              </v-icon>
            </td>
            <td class="text-center font-weight-bold">
              {{ props.item.sum }}{{ currency }}
            </td>
            <td class="text-center description-trunc">
              <read-more v-if="
                props.item.description && props.item.description.length > 0
              " :more-str="$t('custom.read')" :text="props.item.description" link="#" :less-str="$t('custom.less')"
                :max-chars="20"></read-more>
            </td>
            <td v-if="ygibdd_active" class="text-center">
              {{ props.item.pay_status_desc }}
            </td>
            <td class="text-center" v-if="props.item.paid">
              <v-icon v-if="props.item.paid" class="text-center"
                @click="updateFinePaid(props.item.id, !props.item.paid)" style="color: green" v-tooltip="{
                  content: $t('tooltips.fine_paid'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }">
                mdi-plus-circle-outline
              </v-icon>
            </td>
            <td class="text-center" v-if="!props.item.paid">
              <v-icon v-if="!props.item.paid" @click="updateFinePaid(props.item.id, !props.item.paid)"
                class="text-center" style="color: tomato" v-tooltip="{
                  content: $t('tooltips.fine_not_paid'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }">
                mdi-minus-circle-outline
              </v-icon>
            </td>
            <!-- Состояние -->
            <td class="text-center">
              <v-menu absolute offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :color="stateColor(props.item.state)" small block v-bind="attrs" v-on="on">
                    {{
                      props.item.state ? translateState(props.item.state) : $t("fines.fresh")
                    }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link v-for="(state, index) in fines_state" :key="index"
                    @click="updateState(props.item.id, state.value)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ $t("custom.no_data_in_table") }}
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
          {{ $t("tables.no_search_result", { msg: search }) }}
        </v-alert>
      </v-data-table>
    </v-card>
    <v-dialog v-model="editDialog" :retain-focus="false" persistent max-width="1200px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("custom.editing") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 md2>
                    <v-text-field v-model.number="editedFine.sum" :disabled="ygibdd_active" :label="$t('custom.amount')"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md5>
                    <v-text-field v-model="editedFine.number" :disabled="ygibdd_active" :label="$t('fines.number')"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md5>
                    <v-text-field v-model.number="editedFine.booking_id"
                      :label="$t('bookings.number_booking')"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm2 md2>
                    <vc-date-picker v-model="fine_date" mode="dateTime" :attributes="attrs_calendar" is24hr
                      :locale="$i18n.locale">
                      <template v-slot="{ inputEvents }">
                        <v-text-field :value="fine_date_formatted" :disabled="ygibdd_active" v-on="inputEvents" :label="$t('custom.date')"
                          readonly hide-details />
                      </template>
                    </vc-date-picker>
                  </v-flex>
                  <v-flex xs12 sm2 md5>
                    <v-select v-model="editedFine.state" clearable :items="fines_state" item-text="text"
                      item-value="value" :label="$t('bookings.select_state')"></v-select>
                  </v-flex>
                  <v-flex xs12 sm3 md5 v-if="showClientSearch">
                    <v-autocomplete v-model="client" item-text="fullname" item-value="id" :loading="isLoadingClients"
                      :search-input.sync="search_client" flat hide-no-data hide-details clearable
                      :label="$t('bookings.select_client')" :items="clients" return-object>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm3 md5 v-if="!showClientSearch && client" @click="showClientSearch = true">
                    <v-text-field readonly :value="client.fullname" :label="$t('custom.client')"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md5 v-if="!showClientSearch && !client" @click="showClientSearch = true">
                    <v-text-field readonly :label="$t('custom.client')"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="editedFine.description" :label="$t('fines.description')"
                      :placeholder="$t('fines.description_2')"></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="
            (editDialog = false), (client = null), (showClientSearch = false)
            ">{{ $t("custom.close") }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveFine()">{{
            $t("custom.save")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="paymentClientDialog" :retain-focus="false" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("fines.pay_client") }}</span>
          <v-icon class="ml-2 green--text" @click="saveClientPayment()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="
            (paymentClientDialog = false),
            (paymentClientData = {
              sum: null,
              type: null,
              state: null,
              number: null,
            })
            ">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex md12 class="text-center align-center">
                    <v-flex md12 class="mt-3 text-center align-center">
                      <v-text-field v-model.number="paymentClientData.sum" :label="$t('bookings.get_payment')"
                        placeholder="0" hide-details></v-text-field>
                    </v-flex>
                    <v-flex md12 class="d-inline-flex payment">
                      <v-checkbox v-model="paymentClientData.type" style="font-size: 12px !important" class="mt-0 mr-1"
                        :label="$t('money.cash')" value="Наличные" :disabled="paymentClientData.type == 'Терминал' ||
                          paymentClientData.type == 'На карту' ||
                          paymentClientData.type == 'Р/с'
                          " dense hide-details></v-checkbox>
                      <v-checkbox v-model="paymentClientData.type" class="mt-0 mr-1" :label="$t('money.terminal_short')"
                        value="Терминал" :disabled="paymentClientData.type == 'Наличные' ||
                          paymentClientData.type == 'На карту' ||
                          paymentClientData.type == 'Р/с'
                          " dense hide-details></v-checkbox>
                      <v-checkbox v-model="paymentClientData.type" class="mt-0 mr-1" :label="$t('money.to_card')"
                        value="На карту" :disabled="paymentClientData.type == 'Наличные' ||
                          paymentClientData.type == 'Терминал' ||
                          paymentClientData.type == 'Р/с'
                          " dense hide-details></v-checkbox>
                      <v-checkbox v-model="paymentClientData.type" class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')" value="Р/с" :disabled="paymentClientData.type == 'Наличные' ||
                          paymentClientData.type == 'Терминал' ||
                          paymentClientData.type == 'На карту'
                          " dense hide-details></v-checkbox>
                    </v-flex>
                    <v-flex md12
                      v-if="company && company.multicurrency_cashboxes && active_company_cashboxes && active_company_cashboxes.length > 0">
                      <v-select v-model="paymentClientData.company_cashbox_id" item-text="name" item-value="id"
                        :items="active_company_cashboxes" :label="$t('companies.select_cashbox')" hide-no-data
                        hide-details></v-select>
                    </v-flex>
                    <v-flex md12 v-if="showSelectWorkerCashbox && worker_accounts && worker_accounts.length > 0">
                      <v-select v-model="worker_account_id"
                        :item-text="item => `${item.company_cashbox ? item.company_cashbox.name : null} ${item.currency ? item.currency.code : null} ${item.cash}`"
                        item-value="id" :items="worker_accounts" :label="$t('companies.select_worker_cashbox')"
                        hide-no-data hide-details></v-select>
                    </v-flex>
                    <v-flex md12 class="mt-3 text-center align-center">
                      <v-select v-model="paymentClientData.state" clearable :items="fines_state" item-text="text"
                        item-value="value" :label="$t('bookings.select_state')"></v-select>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="
            (paymentClientDialog = false),
            (paymentClientData = {
              sum: null,
              type: null,
              state: null,
              number: null,
            })
            ">{{ $t("custom.close") }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveClientPayment()">{{
            $t("custom.save")
            }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="paymentCompanyDialog" :retain-focus="false" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("fines.paid_company") }}</span>
          <v-icon class="ml-2 green--text" @click="saveCompanyPayment()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="
            (paymentCompanyDialog = false),
            (paymentCompanyData = {
              id: null,
              sum: null,
              type: null,
              state: null,
              number: null,
            })
            ">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex md12 class="text-center align-center">
                    <v-flex md12 class="mt-3 text-center align-center">
                      <v-text-field v-model.number="paymentCompanyData.sum" :label="$t('fines.pay')" placeholder="0"
                        hide-details></v-text-field>
                    </v-flex>
                    <v-flex md12 class="d-inline-flex payment">
                      <v-checkbox v-model="paymentCompanyData.type" style="font-size: 12px !important" class="mt-0 mr-1"
                        :label="$t('money.cash')" value="Наличные" :disabled="paymentCompanyData.type == 'Терминал' ||
                          paymentCompanyData.type == 'На карту' ||
                          paymentCompanyData.type == 'Р/с'
                          " dense hide-details></v-checkbox>
                      <v-checkbox v-model="paymentCompanyData.type" class="mt-0 mr-1"
                        :label="$t('money.terminal_short')" value="Терминал" :disabled="paymentCompanyData.type == 'Наличные' ||
                          paymentCompanyData.type == 'На карту' ||
                          paymentCompanyData.type == 'Р/с'
                          " dense hide-details></v-checkbox>
                      <v-checkbox v-model="paymentCompanyData.type" class="mt-0 mr-1" :label="$t('money.to_card')"
                        value="На карту" :disabled="paymentCompanyData.type == 'Наличные' ||
                          paymentCompanyData.type == 'Терминал' ||
                          paymentCompanyData.type == 'Р/с'
                          " dense hide-details></v-checkbox>
                      <v-checkbox v-model="paymentCompanyData.type" class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')" value="Р/с" :disabled="paymentCompanyData.type == 'Наличные' ||
                          paymentCompanyData.type == 'Терминал' ||
                          paymentCompanyData.type == 'На карту'
                          " dense hide-details></v-checkbox>
                    </v-flex>
                    <v-flex md12
                      v-if="company && company.multicurrency_cashboxes && active_company_cashboxes && active_company_cashboxes.length > 0">
                      <v-select v-model="paymentCompanyData.company_cashbox_id" item-text="name" item-value="id"
                        :items="active_company_cashboxes" :label="$t('companies.select_cashbox')" hide-no-data
                        hide-details></v-select>
                    </v-flex>
                    <v-flex md12 v-if="showSelectWorkerCashbox && worker_accounts && worker_accounts.length > 0">
                      <v-select v-model="worker_account_id"
                        :item-text="item => `${item.company_cashbox ? item.company_cashbox.name : null} ${item.currency ? item.currency.code : null} ${item.cash}`"
                        item-value="id" :items="worker_accounts" :label="$t('companies.select_worker_cashbox')"
                        hide-no-data hide-details></v-select>
                    </v-flex>
                    <v-flex md12 class="mt-3 text-center align-center">
                      <v-select v-model="paymentCompanyData.state" clearable :items="fines_state" item-text="text"
                        item-value="value" :label="$t('bookings.select_state')"></v-select>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="
            (paymentCompanyDialog = false),
            (paymentCompanyData = {
              id: null,
              sum: null,
              type: null,
              state: null,
              number: null,
            })
            ">{{ $t("custom.close") }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveCompanyPayment()">{{
            $t("custom.save")
            }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fineNotificationDialog" :retain-focus="false" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("fines.send_client") }}</span>
          <v-icon class="ml-2 green--text" @click="sendFineNotification()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="
            (fineNotificationDialog = false),
            (fine_notify = null),
            (client_notify = null),
            (booking_notify = null),
            (sendEmail = false),
            (sendWhatsapp = false)
            ">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex md6>
                    <h4>{{ $t("bookings.booking") }}</h4>
                    <p v-if="booking_notify">
                      {{ $t("bookings.start_2") }}:
                      {{ booking_notify.start_date }}
                    </p>
                    <p v-if="booking_notify">
                      {{ $t("bookings.end_2") }}: {{ booking_notify.end_date }}
                    </p>
                    <p v-if="booking_notify">
                      {{ $t("custom.client") }}:
                      {{
                        `${booking_notify.last_name} ${booking_notify.first_name} ${booking_notify.middle_name}`
                      }}
                    </p>
                    <p v-if="booking_notify">
                      {{ $t("custom.car") }}: {{ booking_notify.car_code }}
                    </p>
                  </v-flex>
                  <div xs12 md1 class="vl"></div>
                  <v-flex md5>
                    <h4>{{ $t("fines.fine") }}</h4>
                    <p v-if="fine_notify && fine_notify.date">
                      {{ $t("fines.maded") }}:
                      {{ returnDate(fine_notify.date) }}
                    </p>
                    <p v-if="fine_notify && fine_notify.violation_date">
                      {{ $t("fines.maded") }}:
                      {{ returnDate(fine_notify.violation_date) }}
                    </p>
                    <p v-if="fine_notify">
                      {{ $t("fines.uin") }}: {{ fine_notify.uin }}
                    </p>
                    <p v-if="client_notify">
                      {{ $t("custom.email") }}: {{ client_notify.email }}
                    </p>
                    <p v-if="client_notify && client_notify.phone">
                      {{ $t("custom.phone") }}: {{ client_notify.phone }}
                    </p>
                  </v-flex>
                  <v-flex md12 class="text-center align-center">
                    <v-row justify="space-around">
                      <v-switch v-model="sendEmail" @change="checkEmail()" class="mt-0 mr-1 mr-1"
                        :label="$t('custom.email')" dense hide-details></v-switch>
                      <v-switch v-model="sendWhatsapp" v-if="$store.getters.getCompany.company"
                        @change="checkWhatsapp()" class="mt-0 mr-1 mr-1" :label="$t('custom.whatsapp')"
                        :disabled="!$store.getters.getCompany.company.whatsapp" dense hide-details></v-switch>
                    </v-row>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="
            (fineNotificationDialog = false),
            (fine_notify = null),
            (client_notify = null),
            (booking_notify = null),
            (sendEmail = false),
            (sendWhatsapp = false)
            ">{{ $t("custom.close") }}</v-btn>
          <v-btn color="success" @click="sendFineNotification()">{{
            $t("email_templates.send")
            }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import xlsx from "json-as-xlsx";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { saveAs } from "file-saver";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      fines: [],
      fines_state: [
        { text: this.$t("fines_state.created"), value: "Создан" },
        { text: this.$t("fines_state.notify"), value: "Оповещён" },
        { text: this.$t("fines_state.paid"), value: "Оплачен" },
        { text: this.$t("fines_state.refund"), value: "Возмещён" },
      ],
      attrs_calendar: [
        {
          key: "today",
          highlight: {
            color: "blue",
            fillMode: "outline",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      fine_date: null,
      fine_date_formatted: null,
      showAllFines: false,
      cars: [],
      workers: [],
      search: "",
      editedFine: [],
      date: null,
      selected_car: null,
      selected_client: null,
      selected_worker: null,
      selected_booking: null,
      search_client: null,
      showClientSearch: false,
      clients: [],
      client: null,
      isLoadingClients: false,
      fineNotificationDialog: false,
      client_notify: null,
      booking_notify: null,
      fine_notify: null,
      sendEmail: false,
      sendWhatsapp: false,
      paymentClientData: {
        id: null,
        sum: null,
        type: null,
        state: null,
        number: null,
      },
      worker_account_id: null,
      showSelectWorkerCashbox: false,
      paymentCompanyData: {
        id: null,
        sum: null,
        type: null,
        state: null,
        number: null,
      },
      paymentClientDialog: false,
      paymentCompanyDialog: false,
      headers: [
        { text: this.$t("fines.number"), align: "center", value: "number", sortable: true },
        { text: this.$t("custom.car"), align: "center", value: "car_code", sortable: true },
        { text: this.$t("custom.control"), sortable: false, align: "center" },
        {
          text: this.$t("fines.maded"),
          value: "violation_date",
          align: "center",
        },
        // { text: "Вынесен", value: "decision_date", align: "center" },
        {
          text: this.$t("fines.sale_until"),
          value: "discount_date",
          align: "center",
        },
        { text: this.$t("fines.article"), value: "article", align: "center", sortable: false },
        { text: this.$t("custom.client"), value: "client_id", align: "center", sortable: false },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
          sortable: false
        },
        { text: this.$t("custom.amount"), value: "amount", align: "center", sortable: false },
        {
          text: this.$t("fines.declined"),
          value: "is_revoked",
          align: "center",
          sortable: false
        },
        { text: this.$t("bookings.for_pay_2"), value: "sum", align: "center", sortable: false },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("fines.pay_status_desc"),
          value: "pay_status_desc",
          align: "center",
        },
        { text: this.$t("bookings.payment"), value: "paid", align: "center" },
        { text: this.$t("fines.state"), value: "state", align: "center" },
      ],
      headers_not_ygibdd: [
        { text: this.$t("fines.number"), align: "center", value: "number" },
        { text: this.$t("custom.car"), align: "center", value: "car_code" },
        { text: this.$t("custom.control"), sortable: false, align: "center" },
        {
          text: this.$t("fines.maded"),
          value: "violation_date",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "client_id", align: "center", sortable: false },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
          sortable: false
        },
        { text: this.$t("custom.amount"), value: "amount", align: "center", sortable: false },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("bookings.payment"), value: "paid", align: "center" },
        { text: this.$t("fines.state"), value: "state", align: "center" },
      ],
      pagination: { sortBy: "created_at", descending: true },
      editDialog: false,
      page: 1,
      per_page: 50,
      sort_by: "id",
      direction: "desc",
      tableOptions: {},
      total: 0,
      timeout: null,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      // this.getDataFromApi();
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    tableOptions(pagination) {
      this.page = pagination.page;
      this.per_page = pagination.itemsPerPage;
      this.sort_by = pagination.sortBy[0] ? pagination.sortBy[0] : "id";
      this.direction = pagination.sortDesc[0] ? "desc" : "asc";
      this.getDataFromApi();
    },
    search() {
      if (this.search == "") {
        this.search = null;
      } else {
        let self = this;
        this.page = 1;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.getDataFromApi();
          }, 300); // delay
        }
        debounce();
      }
    },
    search_client(val) {
      // if (this.client) {
      //   return;
      // }
      if (this.search_client == "" || this.search_client == " ") {
        this.search_client = null;
      } else {
        let self = this;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.loadClients();
          }, 200); // delay
        }
        debounce();
      }
    },
    fine_date(val) {
      if (val) {
        this.fine_date_formatted = moment(val).format("DD-MM-YYYY HH:mm");
        this.editedFine.date = moment(val).format("YYYY-MM-DD HH:mm:ss");
      }
    },
  },
  computed: {
    company() {
      return this.$store.getters.getCompany.company;
    },
    worker_accounts() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.currency_accounts;
      } else {
        return [];
      }
    },
    filtredFines() {
      if (this.showAllFines) {
        return this.all_fines;
      } else {
        return this.fines;
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    currencies() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currencies
      ) {
        return this.$store.getters.getCompany.company.currencies;
      } else {
        return [];
      }
    },
    company_cashboxes() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.company_cashboxes) {
        let cashboxes = [];
        this.$store.getters.getCompany.company.company_cashboxes.forEach((cashbox) => {
          if (cashbox.id) {
            cashboxes.push(cashbox);
          }
        });
        return cashboxes;
      } else {
        return [];
      }
    },
    active_company_cashboxes() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.company_cashboxes) {
        let cashboxes = [];
        this.$store.getters.getCompany.company.company_cashboxes.forEach((cashbox) => {
          if (cashbox.id && cashbox.is_active) {
            cashboxes.push(cashbox);
          }
        });
        cashboxes.sort((a, b) => {
          // сортировка по is_default
          if (a.is_default && !b.is_default) {
            return -1;
          }
        });
        return cashboxes;
      } else {
        return [];
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    ygibdd_active() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.ygibdd_active
      ) {
        return true;
      } else {
        return false;
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
  },
  methods: {
    getDataFromApi() {
      this.isLoading = true;
      this.axios
        .post("api/v1/all_fines",
          {
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            direction: this.direction,
            search: this.search,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
        .then((response) => {
          this.fines = [];
          this.all_fines = [];
          response.data.fines.data.forEach((fine) => {
            this.all_fines.push(fine.attributes);
          });

          this.total = response.data.total_fines;

          this.all_fines.forEach((fine) => {
            if (!fine.ygibdd_driver_id) {
              this.fines.push(fine);
            }
          });
          if (this.$route.params != {} && this.$route.params.query) {
            this.search = this.$route.params.query;
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.fines_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    editFine(fine) {
      this.isLoading = true;

      if (fine.client_id) {
        this.axios
          .get(`/api/v1/clients/${fine.client_id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            let client = response.data.client;

            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.short_entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                client.ogrn;
            }
            this.client = client;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.client_load"));
            console.log(error);
          });
      }

      this.axios
        .get(`/api/v1/fines/${fine.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.editedFine = response.data;
          this.date = response.data.date;
          this.fine_date = response.data.date;
          this.editDialog = true;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.fine_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    saveFine() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let fine = this.editedFine;
        let self = this;
        this.axios
          .patch(
            `/api/v1/fines/${fine.id}`,
            {
              fines: {
                sum: fine.sum,
                date: fine.date,
                state: fine.state,
                description: fine.description,
                number: fine.number,
                client_id: this.client ? this.client.id : null,
                booking_id: fine.booking_id,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.fines.findIndex(
              (element) => element.id === fine.id
            );
            let fine_data = response.data;
            if (this.client && this.client.name) {
              fine_data.client_name = `${this.client.name} ${this.client.lastname}`;
            }
            this.fines.splice(foundIndex, 1, fine_data);
            this.client = null;
            this.editDialog = false;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("bookings.fine_update_title"),
              text: this.$t("bookings.fine_update_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.edit_fines"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.edit_fines"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    loadClients() {
      this.isLoadingClients = true;
      this.axios
        .post(
          `/api/v1/index_new_booking_page`,
          {
            search: this.search_client,
            meilisearch: true,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          console.log(response.data)
          this.clients = response.data.query ? response.data.query : (response.data ? response.data : []);
          this.clients.forEach((client) => {
            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                (client.entity_phone
                  ? client.entity_phone.slice(client.entity_phone.length - 4)
                  : "");
            }
          });
        })
        .catch((error) => {
          this.isLoadingClients = false;
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingClients = false));
    },
    updateState(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/fines/${id}`,
            {
              fines: {
                state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.editDialog = false;
            let foundIndex = this.fines.findIndex(
              (element) => element.id === id
            );
            this.fines.splice(foundIndex, 1, response.data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("bookings.fine_update_title"),
              text: this.$t("bookings.fine_update_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.edit_fines"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.edit_fines"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateFinePaid(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/fines/${id}`,
            {
              fines: {
                paid: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.editDialog = false;
            let foundIndex = this.fines.findIndex(
              (element) => element.id === id
            );
            this.fines.splice(foundIndex, 1, response.data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("bookings.fine_update_title"),
              text: this.$t("fines.state_update"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.edit_fines"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.edit_fines"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteItem(fine) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/fines/${fine.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.fines.splice(this.fines.indexOf(fine), 1);
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_fine"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal(
              this.$t("custom.deleted"),
              this.$t("fines.deleted_text"),
              "success"
            );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    downloadItem(fine) {
      if (fine.ygibdd_id && fine.uin) {
        window.open(
          `https://ygibdd.ru/api/v4/business/Documents/GetSinglePdf?fineId=${fine.ygibdd_id}&uin=${fine.uin}&save=false`,
          "_blank"
        );
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("errors.error"),
          text: this.$t("fines.no_data"),
        });
      }
    },
    paymentClientStart(number, id) {
      this.paymentClientData = {
        id: id,
        sum: null,
        type: null,
        state: null,
        number: number,
        company_cashbox_id: this.active_company_cashboxes && this.active_company_cashboxes.length > 0 ? this.active_company_cashboxes[0].id : null,
      };
      this.paymentClientDialog = true;
    },
    paymentCompanyStart(number, id) {
      this.paymentCompanyData = {
        id: id,
        sum: null,
        type: null,
        state: null,
        number: number,
        company_cashbox_id: this.active_company_cashboxes && this.active_company_cashboxes.length > 0 ? this.active_company_cashboxes[0].id : null,
      };
      this.paymentCompanyDialog = true;
    },
    saveClientPayment() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.company.multicurrency_cashboxes && this.company.multicurrency_cashboxes == true && this.paymentClientData.type == "Наличные") {
          // проверяем что у пользователя есть выбранный счёт
          let worker_accounts_cashboxes_ids = this.worker_accounts.map(
            (account) => account.company_cashbox_id
          );
          let company_cashbox_id = this.paymentClientData.company_cashbox_id;
          // проверяем что у пользователя есть выбранный счёт
          if (worker_accounts_cashboxes_ids.length > 0 && worker_accounts_cashboxes_ids.includes(company_cashbox_id)) {
            console.log("exists currency_account")
          } else if (worker_accounts_cashboxes_ids.length > 0 && !worker_accounts_cashboxes_ids.includes(company_cashbox_id) && !this.worker_account_id) {
            console.log("not exists currency_account")
            this.showSelectWorkerCashbox = true;
            this.$swal({
              toast: false,
              position: "center",
              showConfirmButton: true,
              icon: "warning",
              title: this.$t("errors.not_exists_worker_currency_account"),
            });
            return;
          } else {
            console.log("currency_account selected")
          }
        }
        this.isLoading = true;
        let self = this;
        if (
          this.paymentClientData.id &&
          this.paymentClientData.sum &&
          this.paymentClientData.type &&
          this.paymentClientData.state &&
          this.paymentClientData.number &&
          this.paymentClientData.company_cashbox_id
        ) {
          this.axios
            .post(
              `/api/v1/fines_payment`,
              {
                id: this.paymentClientData.id,
                sum: this.paymentClientData.sum,
                type: this.paymentClientData.type,
                state: this.paymentClientData.state,
                number: this.paymentClientData.number,
                company_cashbox_id: this.paymentClientData.company_cashbox_id,
                worker_account_id: this.worker_account_id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then(() => {
              let foundIndex = this.fines.findIndex(
                (element) => element.number === self.paymentClientData.number
              );
              this.fines[foundIndex].state = this.paymentClientData.state;
              // this.fines.splice(foundIndex, 1, response.data)

              this.paymentClientDialog = false;
              this.worker_account_id = null;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("fines.paid"),
                text: this.$t("fines.paid_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.fine_paid_error"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.fine_paid_error"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.isLoading = false;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("custom.no_data"),
            text: this.$t("fines.no_data_2"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveCompanyPayment() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.company.multicurrency_cashboxes && this.company.multicurrency_cashboxes == true && this.paymentCompanyData.type == "Наличные") {
          // проверяем что у пользователя есть выбранный счёт
          let worker_accounts_cashboxes_ids = this.worker_accounts.map(
            (account) => account.company_cashbox_id
          );
          let company_cashbox_id = this.paymentCompanyData.company_cashbox_id;
          // проверяем что у пользователя есть выбранный счёт
          if (worker_accounts_cashboxes_ids.length > 0 && worker_accounts_cashboxes_ids.includes(company_cashbox_id)) {
            console.log("exists currency_account")
          } else if (worker_accounts_cashboxes_ids.length > 0 && !worker_accounts_cashboxes_ids.includes(company_cashbox_id) && !this.worker_account_id) {
            console.log("not exists currency_account")
            this.showSelectWorkerCashbox = true;
            this.$swal({
              toast: false,
              position: "center",
              showConfirmButton: true,
              icon: "warning",
              title: this.$t("errors.not_exists_worker_currency_account"),
            });
            return;
          } else {
            console.log("currency_account selected")
          }
        }
        this.isLoading = true;
        let self = this;
        if (
          this.paymentCompanyData.sum &&
          this.paymentCompanyData.type &&
          this.paymentCompanyData.state &&
          this.paymentCompanyData.number &&
          this.paymentCompanyData.company_cashbox_id
        ) {
          this.axios
            .post(
              `/api/v1/fines_company_payment`,
              {
                id: this.paymentCompanyData.id,
                sum: this.paymentCompanyData.sum,
                type: this.paymentCompanyData.type,
                state: this.paymentCompanyData.state,
                number: this.paymentCompanyData.number,
                company_cashbox_id: this.paymentCompanyData.company_cashbox_id,
                worker_account_id: this.worker_account_id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then(() => {
              let foundIndex = this.fines.findIndex(
                (element) => element.number === self.paymentCompanyData.number
              );
              this.fines[foundIndex].state = this.paymentCompanyData.state;
              // this.fines.splice(foundIndex, 1, response.data)

              this.paymentCompanyDialog = false;
              this.worker_account_id = null;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("fines.paid"),
                text: this.$t("fines.paid_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.fine_paid_error"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.fine_paid_error"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.isLoading = false;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("custom.no_data"),
            text: this.$t("fines.no_data_2"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    translateState(state) {
      let state_text = "";
      if (state == "Создан") {
        state_text = this.$t("fines_state.created");
      }
      if (state == "Оповещён") {
        state_text = this.$t("fines_state.notify");
      }
      if (state == "Оплачен") {
        state_text = this.$t("fines_state.paid");
      }
      if (state == "Возмещён") {
        state_text = this.$t("fines_state.refund");
      }
      return state_text;
    },
    stateColor(state) {
      if (state == "Оповещён") {
        return "warning";
      }
      if (state == "Не возмещён") {
        return "error";
      }
      if (state == "Возмещён") {
        return "success";
      }
      if (state == "Оплачен") {
        return "error";
      }
    },
    selectColor(state) {
      if (state == "Оплачен") {
        if (this.$vuetify.theme.dark) {
          return "green darken-4";
        } else {
          return "green lighten-4";
        }
      }
    },
    setWorker(id) {
      if (id != null) {
        if (this.workers.find((worker) => worker.id == id)) {
          return this.workers.find((worker) => worker.id == id).name;
        }
      } else {
        return "-";
      }
    },
    toXlsx() {
      if (this.user_role != "guest" || this.user_role != "partner") {
        this.isLoading = true;

        let headers_with_ygibdd = [
          { label: this.$t("fines.number"), value: "number" },
          { label: this.$t("custom.car"), value: "car_code" },
          {
            label: this.$t("fines.maded"),
            value: "violation_date",
          },
          {
            label: this.$t("fines.sale_until"),
            value: "discount_date",
          },
          { label: this.$t("fines.article"), value: "article" },
          { label: this.$t("custom.client"), value: "client_id" },
          {
            label: this.$t("bookings.booking"),
            value: "booking_id",
          },
          { label: this.$t("custom.amount"), value: "amount" },
          {
            label: this.$t("fines.declined"),
            value: "is_revoked",
          },
          { label: this.$t("bookings.for_pay_2"), value: "sum" },
          {
            label: this.$t("custom.description"),
            value: "description",
          },
          {
            label: this.$t("fines.pay_status_desc"),
            value: "pay_status_desc",
          },
          { label: this.$t("bookings.payment"), value: "paid" },
          {
            label: this.$t("fines.state"),
            value: (row) => {
              return this.translateState(row.state);
            },
          },
        ]
        let headers_without_ygibdd = [
          { label: this.$t("fines.number"), value: "number" },
          { label: this.$t("custom.car"), value: "car_code" },
          {
            label: this.$t("fines.maded"),
            value: "violation_date",
          },
          { label: this.$t("custom.client"), value: "client_id" },
          {
            label: this.$t("bookings.booking"),
            value: "booking_id",
          },
          { label: this.$t("custom.amount"), value: "amount" },
          {
            label: this.$t("custom.description"),
            value: "description",
          },
          { label: this.$t("bookings.payment"), value: "paid" },
          {
            label: this.$t("fines.state"),
            value: (row) => {
              return this.translateState(row.state);
            },
          },
        ]
        let data = [
          {
            sheets: "Fines",
            columns: this.ygibdd_active ? headers_with_ygibdd : headers_without_ygibdd,
            content: this.all_fines,
          },
        ];
        let settings = {
          fileName: "fines", // Name of the resulting spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: "write", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {
            bookType: "xlsx",
            type: "array",
          }, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        };
        let wbout = xlsx(data, settings);
        var blob_data = new Blob([new Uint8Array(wbout)], {type:"application/octet-stream"});
        if (!this.isNative) {
          saveAs(blob_data, 'fines.xlsx');
        } else {
          if (Filesystem.checkPermissions()) {
            const getBase64FromBlob = async (blob) => {
              return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                  const base64data = reader.result;
                  resolve(base64data);
                }
              });
            }
            getBase64FromBlob(blob_data).then(
              (base64) => {
                // save file
                async function writeFile() {
                  return await Filesystem.writeFile({
                    path: 'fines.xlsx',
                    data: base64,
                    directory: Directory.Documents,
                  });
                };
                let file_uri = null
                let writedFile = writeFile().then((getUriResult) => {
                  // share file opened file
                  let shareFile = async () => {
                    await Share.share({
                      title: 'Download document',
                      files: [getUriResult.uri],
                    }).catch((error) => {
                      console.log("shareFile", error)
                    });
                  };
                  let sharedFile = shareFile();
                }).catch((error) => {
                  console.log("shareFile", error)
                });
              }
            );
          } else {
            Filesystem.requestPermissions();
          }
        }
        this.isLoading = false;
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    sendNotificationStart(fine) {
      this.isLoading = true;
      this.axios
        .get(
          `/api/v1/get_client_and_booking?client_id=${fine.client_id}&booking_id=${fine.booking_id}`,
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.client_notify = response.data.client;
          this.booking_notify = response.data.booking;
          this.fine_notify = fine;
          if (this.client_notify.email) {
            this.sendEmail = true;
          }
          if (
            this.client_notify.phone &&
            this.$store.getters.getCompany.company.whatsapp
          ) {
            this.sendWhatsapp = true;
          }
          this.isLoading = false;
          this.fineNotificationDialog = true;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.clients_booking_data"));
          console.log(error);
        });
    },
    sendFineNotification() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .get(
            `/api/v1/send_notification?client_id=${this.client_notify.id}&booking_id=${this.booking_notify.id}&fine_id=${this.fine_notify.id}&email=${this.sendEmail}&whatsapp=${this.sendWhatsapp}`,
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then(() => {
            this.client_notify = null;
            this.booking_notify = null;
            this.fine_notify = null;
            this.sendEmail = false;
            this.sendWhatsapp = false;
            this.fineNotificationDialog = false;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.fine_notification"));
            console.log(error);
          });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkEmail() {
      if (this.client_notify.email) {
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t("errors.bad_email_title"),
          text: this.$t("errors.bad_email_text"),
        });
      }
    },
    checkWhatsapp() {
      if (
        this.client_notify.phone &&
        this.$store.getters.getCompany.company.whatsapp
      ) {
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t("errors.fine_notification_whatsapp"),
          text: this.$t("errors.fine_notification_whatsapp_text"),
        });
      }
    },
    showSale(date) {
      if (moment().isAfter(moment(date), "day")) {
        return false;
      } else {
        return true;
      }
    },
    checkLastDaySale(date) {
      if (moment().isSame(moment(date), "day")) {
        return true;
      } else {
        return false;
      }
    },
    formatCreatedAt(date) {
      if (date) {
        return moment.parseZone(date).format("DD MMM H:mm");
      } else {
        return "-";
      }
    },
    formatCreatedAtWithYear(date) {
      if (date) {
        return moment.utc(date).format("DD-MM-YY H:mm");
      } else {
        return "-";
      }
    },
    formatCreatedAtNoTime(date) {
      if (date && date != "0001-01-01") {
        return moment.parseZone(date).format("DD MMM");
        // return date
      } else {
        return "-";
      }
    },
    checkLateSale(date, state) {
      if (date && date != "0001-01-01" && state != "Оплачен") {
        if (moment(date).isAfter(moment())) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    returnDate(item) {
      return moment(item, "YYYY-MM-DD H:mm").format("DD MMM H:mm");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
  },
};
</script>
<style>
.vl {
  border-left: 1px solid black;
  height: auto;
  margin-right: 1% !important;
  margin-left: 1% !important;
}
</style>
