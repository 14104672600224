<template>
  <v-row v-resize="checkMobile">
    <v-col cols="12" sm="6" offset-sm="3">
      <v-card>
        <v-container fluid>
          <!-- <h2 class="text-center my-2">{{ $t("other.photo_booking") }}</h2> -->
          <draggable
            v-if="photos.length > 0 && !isMobile"
            :value="sortedPhotos"
            @end="movePhoto"
            class="row"
          >
            <v-col
              v-for="(item, index) in sortedPhotos"
              :key="item.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-card flat tile class="d-flex flex-column my-2">
                <v-badge
                  v-if="index == 0"
                  overlap
                  offset-x="auto"
                  offset-y="auto"
                >
                  <v-img
                    :src="item.url"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    @click="screenFullImage(item)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <template v-slot:badge>
                    {{ $t("aggregator.main_photo") }}
                  </template>
                </v-badge>
                <v-img
                  v-else
                  :src="item.url"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="screenFullImage(item)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-row justify="center" class="mt-1 mr-3 ml-3 align-center">
                  <v-icon
                    class="mx-1"
                    color="warning"
                    @click="deleteItem(item)"
                  >
                    mdi-delete-forever
                  </v-icon>
                  <v-icon class="mx-1" color="info" @click="downloadItem(item)">
                    mdi-content-save-outline
                  </v-icon>
                </v-row>
              </v-card>
            </v-col>
          </draggable>
          <v-row v-else-if="photos.length > 0 && isMobile">
            <v-col
              v-for="(item, index) in sortedPhotos"
              :key="item.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-card flat tile class="d-flex flex-column my-2">
                <v-badge
                  v-if="index == 0"
                  overlap
                  offset-x="auto"
                  offset-y="auto"
                >
                  <v-img
                    :src="item.url"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    @click="screenFullImage(item)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <template v-slot:badge>
                    {{ $t("aggregator.main_photo") }}
                  </template>
                </v-badge>
                <v-img
                  v-else
                  :src="item.url"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="screenFullImage(item)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-row justify="center" class="mt-1 mr-3 ml-3 align-center">
                  <v-icon
                    class="mx-1"
                    color="warning"
                    @click="deleteItem(item)"
                  >
                    mdi-delete-forever
                  </v-icon>
                  <v-icon class="mx-1" color="info" @click="downloadItem(item)">
                    mdi-content-save-outline
                  </v-icon>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else class="d-flex text-center justify-center my-3">
            <v-card flat tile class="d-flex text-center justify-center">
              {{ $t("other.no_photo") }}
            </v-card>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog" :retain-focus="false" max-width="1200">
      <v-card class="mt-2">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="(dialog = false), (link_full_image = '')"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-img :src="link_full_image" class="grey lighten-2"> </v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="(dialog = false), (link_full_image = '')"
          >
            {{ $t("custom.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { saveAs } from "file-saver";
import draggable from "vuedraggable";
export default {
  data() {
    return {
      photos: [],
      link_full_image: "",
      dialog: false,
      isMobile: false,
    };
  },
  created() {
    this.axios
      .get(`api/v1/cars_photo_bookings/${this.$route.params.id}`, {
        headers: {
          Authorization: this.$store.getters.getAuthToken,
        },
      })
      .then((response) => {
        this.photos = response.data;
      })
      .catch((error) => {
        this.setError(error, this.$t("errors.load_file"));
        console.log(error);
      });
  },
  computed: {
    sortedPhotos() {
      return this.photos;
      // if (this.photos && this.photos.length > 0) {
      //   return this.photos.sort((a, b) => {
      //     return b.position - a.position;
      //   });
      // } else {
      //   return [];
      // }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    screenFullImage(item) {
      this.isLoading = true;
      this.axios
        .get(`api/v1/cars_photo_bookings_full/${item.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.link_full_image = response.data.url;
          this.dialog = true;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async downloadItem(item) {
      this.isLoading = true;
      this.axios
        .get(`api/v1/cars_photo_bookings_full/${item.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.isLoading = false;
          saveAs(`${response.data.url}`);
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteItem(item) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/cars_photo_bookings/${item.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.photos.splice(this.photos.indexOf(item), 1);
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  icon: "success",
                  title: this.$t("custom.deleted"),
                  text: this.$t("other.delete_file"),
                });
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_file"))
              );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    movePhoto(e) {
      let photo_id = this.photos[e.oldIndex].id;
      this.photos.splice(e.newIndex, 0, this.photos.splice(e.oldIndex, 1)[0]);
      let self = this;
      // console.log(e.newIndex, e.oldIndex);
      // this.photos.forEach((item) => {
      //   item.sort = self.photos.indexOf(item);
      // });
      this.isLoading = true;
      this.axios
        .patch(`api/v1/cars_photo_bookings_sorting/${photo_id}`,
          {
            cars_photo_bookings: {
              new_position: e.newIndex + 1,
              old_position: e.oldIndex + 1,
            },
          },
          {
            headers: {
              Authorization: self.$store.getters.getAuthToken,
            },
          }
        )
        .then(() => {
          // this.photos = this.photos.sort((a, b) => {
          //   return a.sort - b.sort;
          // });
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            icon: "success",
            title: this.$t("custom.saved"),
          });
        })
        .catch((error) => this.setError(error, this.$t("errors.error")))
        .finally(() => (this.isLoading = false));
    },
    sortUp(item) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`api/v1/cars_photo_bookings/sort_up/${item.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then(() => {
            item.sort += 1;
            let foundIndex = self.photos.findIndex(
              (element) => element.id === item.id
            );
            this.photos.splice(foundIndex, 1, item);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("other.sort_files_title"),
              text: this.$t("other.sort_files_text"),
            });
          })
          .catch((error) => this.setError(error, this.$t("errors.sort_file")))
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    sortDown(item) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`api/v1/cars_photo_bookings/sort_down/${item.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then(() => {
            item.sort -= 1;
            let foundIndex = self.photos.findIndex(
              (element) => element.id === item.id
            );
            this.photos.splice(foundIndex, 1, item);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("other.sort_files_title"),
              text: this.$t("other.sort_files_text"),
            });
          })
          .catch((error) => this.setError(error, this.$t("errors.sort_file")))
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkMobile() {
      if (this.$vuetify.breakpoint.name == "xs" || this.$vuetify.breakpoint.name == "sm") {
        this.isMobile = true;
        return true;
      } else {
        this.isMobile = false;
        return false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    draggable,
  },
};
</script>
