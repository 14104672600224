<template>
  <v-container
    v-resize="checkMobile"
    :style="isMobile ? 'padding: 5px !important;' : ''"
  >
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-expansion-panels
          v-if="contractor && (contractor.full_name || contractor.short_name)"
          v-model="dataPanel"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2 class="mb-0 d-flex">
                {{ contractor.full_name ? contractor.full_name : contractor.short_name }}
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container v-if="contractor" grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md4>
                    <v-text-field
                      v-model.trim="contractor.full_name"
                      :label="$t('custom.company_name')"
                      :placeholder="$t('custom.company_name_placeholder')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field
                      v-model.trim="contractor.short_name"
                      :label="$t('custom.company_name_short')"
                      :placeholder="$t('custom.company_name_short_plc')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field
                      v-model.trim="contractor.entity_type"
                      :label="$t('custom.reg_form')"
                      :placeholder="$t('custom.reg_form_plc')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field
                      v-model.trim="contractor.tax_number"
                      :label="$t('custom.tax_number')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field
                      v-model.trim="contractor.tin_number"
                      :label="$t('custom.entity_number')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field
                      v-model.trim="contractor.phone"
                      :label="$t('custom.phone')"
                      type="tel"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md3>
                    <v-text-field
                      v-model.trim="contractor.email"
                      :label="$t('custom.email')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field
                      v-model.trim="contractor.account"
                      :label="$t('custom.entity_bank_number')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field
                      v-model.trim="contractor.bank_name"
                      :label="$t('custom.bank_name')"
                      :placeholder="$t('custom.bank_name_plc')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <v-text-field
                      v-model.trim="contractor.bank_number"
                      :label="$t('custom.bank_number')"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex md12>
                    <v-textarea
                      v-model.trim="contractor.address"
                      :label="$t('custom.entity_address')"
                      outlined
                      rows="1"
                      auto-grow
                    ></v-textarea>
                  </v-flex>
                  <v-flex md12>
                    <v-textarea
                      v-model.trim="contractor.description"
                      :label="$t('custom.description')"
                      outlined
                      rows="1"
                      auto-grow
                    ></v-textarea>
                  </v-flex>
                </v-layout>
                <v-flex class="d-flex align-middle justify-center">
                  <v-btn color="success"
                    @click="saveEditContractor()"
                  >
                    {{ $t("custom.save") }}<v-icon class="mx-auto">
                      mdi-content-save-check-outline
                    </v-icon>
                  </v-btn>
                </v-flex>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 class="text-right mt-3">
            <v-icon
              v-if="selectedCounts && selectedCounts.length > 0"
              @click="processedSelectedCounts()"
              class="mx-2"
              style="cursor: pointer"
              color="warning"
              v-tooltip="{
                content: $t('counts.processed_selected_payment'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 1000,
                  hide: 500,
                },
              }"
            >
              mdi-check-underline-circle-outline
            </v-icon>
            <v-icon
              v-if="(user_role == 'superadmin' || user_role == 'admin')"
              @click="toXlsx()"
              class="mx-2"
              style="cursor: pointer"
              color="success"
            >
              mdi-file-excel-outline
            </v-icon>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="counts"
              :search="search"
              :loading="isLoading"
              :loading-text="$t('custom.loading_table')"
              :items-per-page="20"
              :dense="isMobile"
              mobile-breakpoint="100"
              :sort-by="['created_at']"
              :sort-desc="[true]"
              :class="!isMobile ? '' : 'is-mobile'"
              :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }"
            >
              <template slot="item" slot-scope="props">
                <tr
                  :class="
                    props.item.operation && props.item.sum < 0
                      ? 'red--text'
                      : ''
                  "
                >
                  <td>
                    <v-checkbox
                      v-model="props.item.selected"
                      color="primary"
                    ></v-checkbox>
                  </td>
                  <td class="text-center">
                    <router-link v-if="user_role == 'superadmin' || user_role == 'admin'" :to="{ path: `/company_counts/${props.item.id}` }">{{
                      props.item.id
                    }}</router-link>
                    <span v-else>{{
                      props.item.id
                    }}</span>
                  </td>
                  <td class="text-center" nowrap>
                    {{ formatCompletedAt(props.item.created_at) }}
                  </td>
                  <td class="text-center">
                    {{ translateCategory(props.item.group) }}
                  </td>
                  <td class="text-center">{{ props.item.description }}</td>
                  <td class="text-center">
                    {{ props.item.sum }}{{ currency }}
                  </td>
                  <td class="text-center" v-if="props.item.operation">
                    <v-icon class="text-center" style="color: green">
                      mdi-plus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="!props.item.operation">
                    <v-icon class="text-center" style="color: tomato">
                      mdi-minus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash">
                    <v-icon
                      class="text-center"
                      color="success"
                      v-tooltip="{
                        content: $t('tooltips.cash_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-cash
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cashless">
                    <v-icon
                      class="text-center"
                      color="warning"
                      v-tooltip="{
                        content: $t('tooltips.terminal_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.entity">
                    <v-icon
                      class="text-center"
                      color="secondery"
                      v-tooltip="{
                        content: $t('tooltips.entity_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-bank
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash_card">
                    <v-icon
                      class="text-center"
                      color="info"
                      v-tooltip="{
                        content: $t('money.card_to_card'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-credit-card-check-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.client_balance">
                    <v-icon
                      class="text-center"
                      color="error"
                      v-tooltip="{
                        content: $t('money.client_balance'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-account-arrow-right
                    </v-icon>
                  </td>
                  <td
                    class="text-left"
                    nowrap
                    v-if="
                      props.item.booking_id &&
                      props.item.car_id &&
                      props.item.car_code
                    "
                  >
                    <p>
                      {{ $t("bookings.booking") }}:
                      <router-link
                        :to="{ path: `/bookings/${props.item.booking_id}` }"
                      >
                        {{ props.item.booking_id }}
                      </router-link>
                    </p>
                    <p>
                      {{ $t("custom.car") }}:
                      <router-link
                        :to="{ path: `/cars/${props.item.car_id}` }"
                      >
                        {{ props.item.car_code }}
                      </router-link>
                    </p>
                  </td>
                  <td
                    class="text-center"
                    v-else-if="props.item.car_id && props.item.car_code"
                  >
                    <router-link
                      :to="{ path: `/cars/${props.item.car_id}` }"
                      >{{ props.item.car_code }}</router-link
                    >
                  </td>
                  <td class="text-center" v-else-if="props.item.source">
                    {{ props.item.source }}
                  </td>
                  <td class="text-center" v-else-if="props.item.debt_id">
                    {{ $t("debts.debt") }} {{ $t("custom.number")
                    }}{{ props.item.debt_id }}
                  </td>
                  <td class="text-center" v-else-if="props.item.agent_id">
                    {{ $t("agents.agent") }} {{ $t("custom.number") }}
                    <router-link
                      :to="{ path: `/agent_card/${props.item.agent_id}` }"
                    >
                      {{ props.item.agent_id }}
                    </router-link>
                  </td>
                  <td
                    class="text-center"
                    v-else-if="props.item.investor_id"
                  >
                    <router-link
                      :to="{ path: `/investors/${props.item.investor_id}` }"
                      >{{ $t("employers.partner") }} {{ $t("custom.number")
                      }}{{ props.item.investor_id }}</router-link
                    >
                  </td>
                  <td class="text-center" v-else>-</td>
                  <td class="text-center">
                    <v-icon
                      v-if="props.item.completed"
                      color="success"
                      v-tooltip="{
                        content: $t('counts.completed'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      @click="completedTooltipDialog(props.item)"
                    >
                      mdi-check-circle-outline
                    </v-icon>
                    <v-icon
                      v-else
                      color="error"
                      v-tooltip="{
                        content: $t('counts.not_completed'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      @click="processedCount(props.item)"
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="mdi-alert"
              >
                {{ $t("tables.no_search_result", { msg: search }) }}
              </v-alert>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12>
            <gallery></gallery>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text class="text-center">
        <uppy
          :max-file-size-in-bytes="10000000"
          width="100%"
          :height="250"
          :theme="$vuetify.theme.dark ? 'dark' : 'light'"
        ></uppy>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showProcessedInfoDialog"
      :retain-focus="false"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title v-if="workers && workers.length > 0">
          {{`${$t('counts.completed')}: ${formatCompletedAt(
            editedCount.completed_at
          )}, ${findWorkersName([editedCount.completed_by])}`}}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showProcessedInfoDialog = false, editedCount = {}">{{
            $t("custom.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
/* eslint-disable */
import Gallery from "./GallaryContractor";
import Uppy from "../../../plugins/uppy_contractor";
let uuid = require("uuid");
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { saveAs } from "file-saver";
import xlsx from "json-as-xlsx";
import PullToRefresh from "pulltorefreshjs";

export default {
  data() {
    return {
      contractor: {},
      editedCount: {},
      counts: [],
      selected: [],
      search: null,
      headers: [
        { text: '', sortable: false, align: "center", value: "selected" },
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.group"), value: "group", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.operation"),
          value: "operation",
          align: "center",
        },
        { text: this.$t("custom.type"), value: "cashless", align: "center" },
        { text: this.$t("custom.source"), sortable: false, align: "center" },
        { text: this.$t("counts.completed"), value: "completed", align: "center" },
      ],
      workers: [],
      dataPanel: false,
      showProcessedInfoDialog: false,
      isMobile: false,
      isLoading: false,
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/contractors/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.contractor = response.data.contractor;
          this.counts = response.data.counts;
          this.workers = response.data.workers;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.fines_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    selectedCounts() {
      let selected_counts = [];
      let counts = this.counts.filter((count) => {
        return count.selected;
      });
      counts.forEach((count) => {
        selected_counts.push(count);
      });
      return counts;
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    company() {
      if (this.$store.getters.getCompany) {
        return this.$store.getters.getCompany.company;
      } else {
        return null;
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
  },
  methods: {
    saveEditContractor() {
      if (this.user_role != "guest" || this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/contractors/${this.contractor.id}`,
            {
              contractors: this.contractor,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("custom.saved"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    processedCount(count) {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("counts.processed_payment_not_declined"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/processed_count`,
                {
                  count_id: count.id,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                let foundIndex = this.counts.findIndex(
                  (element) => element.id === count.id
                )
                this.counts.splice(foundIndex, 1, response.data);

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "success",
                  title: this.$t("counts.update_count_title"),
                  text: this.$t("counts.update_count_text"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.update_count"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "error",
                  title: this.$t("errors.update_count"),
                  text: this.error,
                });
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    processedSelectedCounts() {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("counts.processed_payment_not_declined"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          let counts_ids = this.selectedCounts.map((count) => count.id)
          if (result.value && counts_ids && counts_ids.length > 0) {
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/processed_selected_counts`,
                {
                  counts_ids: this.selectedCounts.map((count) => count.id),
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                this.selectedCounts.forEach((count) => {
                  let foundIndex = this.counts.findIndex(
                    (element) => element.id === count.id
                  )
                  count.completed = true;
                  this.counts.splice(foundIndex, 1, count);
                })

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "success",
                  title: this.$t("counts.update_count_title"),
                  text: this.$t("counts.update_count_text"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.update_count"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "error",
                  title: this.$t("errors.update_count"),
                  text: this.error,
                });
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    toXlsx() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        let data = [
          {
            sheets: "Payments",
            columns: [
              { label: "ID", value: "id" },
              { label: this.$t("clients.created_at"), value: "created_at" },
              { label: this.$t("custom.amount"), value: "sum" },
              { label: this.$t("custom.operation"), value: "operation" },
              { label: this.$t("counts.about"), value: "description" },
              { label: this.$t("money.terminal"), value: "cashless" },
              { label: this.$t("money.card_to_card"), value: "cash_card" },
              { label: this.$t("money.cash"), value: "cash" },
              { label: this.$t("money.from_entity"), value: "entity" },
              { label: this.$t("custom.group"), value: (row) => {
                return this.translateCategory(row.group);
              }},
              { label: this.$t("custom.car"), value: "car_code" },
              { label: this.$t("bookings.booking"), value: "booking_id" },
              { label: this.$t("debts.debt"), value: "debt_id" },
              { label: this.$t("employers.partner"), value: "investor_id" },
              { label: this.$t("counts.completed"), value: "completed" },
            ],
            content: this.selectedCounts && this.selectedCounts.length > 0 ? this.selectedCounts : this.counts,
          },
        ];
        let settings = {
          fileName: "contractor_payments", // Name of the resulting spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: "write", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {
            bookType: "xlsx",
            type: "array",
          }, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        };
        let wbout = xlsx(data, settings);
        var blob_data = new Blob([new Uint8Array(wbout)], {type:"application/octet-stream"});
        if (!this.isNative) {
          saveAs(blob_data, 'contractor_payments.xlsx');
        } else {
          if (Filesystem.checkPermissions()) {
            const getBase64FromBlob = async (blob) => {
              return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                  const base64data = reader.result;
                  resolve(base64data);
                }
              });
            }
            getBase64FromBlob(blob_data).then(
              (base64) => {
                // save file
                async function writeFile() {
                  return await Filesystem.writeFile({
                    path: 'contractor_payments.xlsx',
                    data: base64,
                    directory: Directory.Documents,
                  });
                };
                let file_uri = null
                let writedFile = writeFile().then((getUriResult) => {
                  // share file opened file
                  let shareFile = async () => {
                    await Share.share({
                      title: 'Download document',
                      files: [getUriResult.uri],
                    }).catch((error) => {
                      console.log("shareFile", error)
                    });
                  };
                  let sharedFile = shareFile();
                }).catch((error) => {
                  console.log("shareFile", error)
                });
              }
            );
          } else {
            Filesystem.requestPermissions();
          }
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    findWorkersName(ids) {
      let names = [];
      ids.forEach((id) => {
        this.workers.forEach((worker) => {
          if (worker.id == id) {
            names.push(worker.name);
          }
        });
      });
      return names ? names.join(", ") : "";
    },
    completedTooltipDialog(item) {
      if(item && item.completed_at && item.completed_by) {
        this.showProcessedInfoDialog = true;
        this.editedCount = item;
      } else {
        this.showProcessedInfoDialog = false;
      }
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
        case "Пополнение баланса клиента":
          group_text = this.$t("groups_counts.top_up_client_balance");
          break;
        case "Выплаты агентам":
          group_text = this.$t("groups_counts.agent_payments");
          break;
      }
      return group_text;
    },
    formatCompletedAt(date) {
      if (date == null) {
        return "-";
      }
      return moment.parseZone(date).format("lll");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    Gallery,
    Uppy,
  },
};
</script>
