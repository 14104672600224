<template>
  <v-app id="inspire" v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-main v-if="booking == null" class="mt-auto">
      <v-alert v-if="error" :value="true" color="error">
        {{ error }}
      </v-alert>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{ $t("users.enter") }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent="recaptcha()">
                  <v-text-field v-model.trim="last_name" :label="$t('bookings.lastname')" required></v-text-field>
                  <v-text-field v-model.trim="booking_id" :label="$t('bookings.booking_number')"
                    required></v-text-field>
                  <v-flex class="text-center">
                    <v-btn type="submit" block color="primary">{{ $t("users.enter") }}</v-btn>
                  </v-flex>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-card v-else>
      <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
        <v-toolbar-title class="text-h5">
          {{ $t("bookings.booking_number") }} {{ booking.id }} - {{ translateBookingState(booking.state) }}
          <v-btn icon @click="downloadIcalendar(booking.id)" v-tooltip="{
            content: $t('tooltips.download_icalendar'),
            placement: 'bottom-center',
            classes: ['black--text', 'white'],
            targetClasses: ['it-has-a-tooltip'],
            delay: { show: 500, hide: 500 },
          }">
            <v-icon>mdi-calendar-export</v-icon>
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-flex md12 :class="isMobile ? 'mx-3' : 'd-flex mx-3'">
          <v-flex xs12 md4 class="start-tour-booking-2">
            <div v-if="booking.vseprokaty_id">
              {{ $t("agregator.agregator_id") }}: {{ booking.vseprokaty_id }}
            </div>
            <div v-if="booking.fast_booking">
              {{ $t("agregator.fast_booking") }}: +
            </div>
            <div v-if="booking.vseprokaty_id && !booking.fast_booking">
              {{ $t("agregator.fast_booking") }}: -
            </div>
            <div v-else v-tooltip="{
              content: $t('tooltips.creator'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">
              {{ $t("custom.created") }}:
              {{ formatCreatedAt(booking.created_at) }}
            </div>
            <div>
              {{ $t("custom.car_long") }}: {{ booking.car_name }}
            </div>
            <div>{{ $t("bookings.start_date") }}: {{ booking.start_date }}</div>
            <div>{{ $t("bookings.end_date") }}: {{ booking.end_date }}</div>
            <div>{{ $t("custom.days") }}: {{ booking.days }}</div>
            <div v-if="booking.additional_hours > 0">
              {{ $t("bookings.add_hours") }}: {{ booking.additional_hours }}
            </div>
            <div>
              {{ $t("bookings.checkin") }}: {{ booking.location_start }}
            </div>
            <div>{{ $t("bookings.checkout") }}: {{ booking.location_end }}</div>
            <div v-if="!booking.technical">
              {{ $t("bookings.current_price") }}: {{ Math.round(booking.rental_cost / booking.days) }}
            </div>
            <div v-if="!booking.technical && booking.sale_cash">
              {{ $t("bookings.sale") }}: {{ booking.sale_cash }}
            </div>
            <div v-if="!booking.technical && booking.sale">
              {{ $t("bookings.sale") }}: {{ booking.sale }}%
            </div>
            <v-divider v-if="
              booking.chair ||
              booking.chair_less_1_year ||
              booking.booster ||
              booking.navigator ||
              booking.mp3 ||
              booking.charger ||
              booking.wifi_router
            " class="mt-2 mb-2"></v-divider>
            <h3 v-if="
              booking.chair ||
              booking.chair_less_1_year ||
              booking.booster ||
              booking.navigator ||
              booking.mp3 ||
              booking.charger ||
              booking.wifi_router
            ">
              {{ $t("bookings.add_equipment") }}
            </h3>
            <div v-if="booking.chair">
              {{ $t("bookings.baby_chair") }} - {{ booking.chairs_quantity }}
            </div>
            <div v-if="booking.chair_less_1_year">
              {{ $t("bookings.small_baby_chair") }} -
              {{ booking.chairs_quantity }}
            </div>
            <div v-if="booking.booster">
              {{ $t("bookings.booster") }} - {{ booking.boosters_quantity }}
            </div>
            <div v-if="booking.navigator">{{ $t("bookings.navigator") }}</div>
            <div v-if="booking.mp3">{{ $t("bookings.mp3") }}</div>
            <div v-if="booking.charger">{{ $t("bookings.charger") }}</div>
            <div v-if="booking.wifi_router">{{ $t("bookings.wifi") }}</div>
            <div v-if="booking.other_equipment">
              {{ booking.other_equipment }}
            </div>
            <h3 v-if="
              booking.kasko ||
              booking.super_kasko ||
              booking.theft ||
              booking.no_franchise
            ">
              {{ $t("bookings.add_insurance") }}
            </h3>
            <div v-if="booking.kasko">{{ $t("bookings.kasko") }}</div>
            <div v-if="booking.super_kasko">
              {{ $t("bookings.super_kasko") }}
            </div>
            <div v-if="booking.theft">
              {{ $t("bookings.theft_insurance") }}
            </div>
            <div v-if="booking.no_franchise">
              {{ $t("bookings.no_franchise") }}
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <h3 v-if="!booking.technical">{{ $t("bookings.calculate") }}</h3>
            <div v-if="!booking.technical && booking.aggr_commission == 0" class="font-weight-bold">
              {{ $t("bookings.rental_cost") }}: {{ booking.rental_cost
              }}
            </div>
            <div v-if="!booking.technical && booking.aggr_commission > 0" class="font-weight-bold">
              {{ $t("bookings.rental_cost") }}: {{ booking.rental_cost
              }} - {{ booking.aggr_commission }} = {{ (booking.rental_cost - booking.aggr_commission) }}
            </div>
            <div class="font-weight-bold" v-if="booking.hours_cost > 0 && !booking.technical">
              {{ $t("bookings.hours_cost") }}: {{ booking.hours_cost
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.hours_cost_end > 0 && !booking.technical">
              {{ $t("bookings.hours_cost_end") }}: {{ booking.hours_cost_end
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.delivery > 0 && !booking.technical">
              {{ $t("bookings.delivery") }}: {{ booking.delivery
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.delivery_end > 0 && !booking.technical">
              {{ $t("bookings.checkout") }}: {{ booking.delivery_end
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.equipment > 0 && !booking.technical">
              {{ $t("bookings.equipment") }}: {{ booking.equipment
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.clean_payment > 0 && !booking.technical">
              {{ $t("bookings.clean") }}: {{ booking.clean_payment
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.damage > 0 && !booking.technical">
              {{ $t("bookings.damages") }}: {{ booking.damage }}
            </div>
            <div class="font-weight-bold" v-if="booking.gas > 0">
              {{ $t("bookings.for_gas") }}: {{ booking.gas }}
            </div>
            <div class="font-weight-bold" v-if="booking.add_drivers_cost > 0">
              {{ $t("bookings.for_add_drivers") }}: {{ booking.add_drivers_cost
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.insurance > 0">
              {{ $t("bookings.for_add_insurance") }}: {{ booking.insurance
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.fine > 0">
              {{ $t("bookings.for_fines") }}: {{ booking.fine }}
            </div>
            <div class="font-weight-bold" v-if="booking.other > 0">
              {{ $t("bookings.for_other") }}: {{ booking.other }}
            </div>
            <div class="font-weight-bold" v-if="booking.other_end > 0">
              {{ $t("bookings.for_other_end") }}: {{ booking.other_end
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.mileage_cost > 0">
              {{ $t("bookings.mileage_cost") }}: {{ booking.mileage_cost
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.aggr_commission > 0">
              <!-- <v-icon>mdi-alpha-a-circle-outline</v-icon> -->
              {{ $t("bookings.aggr_commission") }}: {{ booking.aggr_commission }}
            </div>
            <div class="font-weight-bold" v-if="!booking.technical">
              {{ $t("bookings.deposit") }}: {{ booking.deposit }}
            </div>
            <div class="subtitle-1 font-weight-bold" v-if="!booking.technical">
              {{ $t("custom.total") }}:
              {{
                booking.rental_cost +
                booking.delivery +
                booking.delivery_end +
                booking.equipment +
                booking.insurance +
                booking.clean_payment +
                booking.add_drivers_cost +
                booking.hours_cost +
                booking.mileage_cost +
                booking.damage +
                booking.gas +
                booking.fine +
                booking.other +
                booking.other_end +
                booking.hours_cost_end +
                booking.deposit
              }}
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <div :class="'subtitle-1 font-weight-bold ' +
              `${calculatePayments(booking) == $t('bookings.paid')
                ? 'success--text'
                : ''
              }`
              " v-if="!booking.technical">
              {{ $t("bookings.paid") }}: {{ calculatePaymentsSum(booking)
              }}
            </div>
            <v-divider v-if="
              calculateRentPaymentsSum(booking) > 0 &&
              booking.rental_cost > 0 &&
              booking.days > 0
            " class="mt-2 mb-2"></v-divider>
            <v-progress-linear v-if="
              calculateRentPaymentsSum(booking) > 0 &&
              booking.rental_cost > 0 &&
              booking.days > 0
            " :value="progressPercent" :color="progressPercent == 100 ? 'success' : 'warning'" rounded height="20">
              <strong class="white--text">{{ paidDays }} {{ $t("other.from") }} {{ booking.days }}
                {{ $t("bookings.days_low") }}</strong>
            </v-progress-linear>
            <v-divider class="mt-2 mb-2"></v-divider>
            <div v-if="
              booking.description &&
              booking.description != '' &&
              booking.description != ' '
            ">
              <h3>{{ $t("bookings.description") }}</h3>
              <p>{{ booking.description }}</p>
            </div>
            <div v-if="booking.in_rent || booking.ride">
              {{ $t("bookings.clean_start") }}:
              <v-icon color="green" v-if="booking.clean_start">
                mdi-plus
              </v-icon>
              <v-icon color="red" v-else> mdi-minus </v-icon>
            </div>
            <div v-if="booking.ride">
              {{ $t("bookings.clean_end") }}:
              <v-icon color="green" v-if="booking.clean_end"> mdi-plus </v-icon>
              <v-icon color="red" v-else> mdi-minus </v-icon>
            </div>
            <div v-if="
              (booking.in_rent || booking.ride) && !booking.gas_start_full
            ">
              {{ $t("bookings.gas_start") }}: {{ booking.gas_start }}
            </div>
            <div v-if="(booking.in_rent || booking.ride) && booking.gas_start_full">
              {{ $t("bookings.gas_start_full") }}
            </div>
            <div v-if="!booking.gas_end_full && booking.ride">
              {{ $t("bookings.gas_end_short") }}: {{ booking.gas_end }}
            </div>
            <div v-if="booking.gas_end_full && booking.ride">
              {{ $t("bookings.gas_end_full") }}
            </div>
            <div v-if="
              (booking.in_rent || booking.ride) && booking.start_mileage > 0
            ">
              {{ $t("bookings.mileage_start") }}: {{ booking.start_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.end_mileage > 0">
              {{ $t("bookings.mileage_end") }}: {{ booking.end_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.booking_mileage > 0">
              {{ $t("bookings.booking_mileage") }}:
              {{ booking.booking_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.booking_mileage > 0">
              {{ $t("bookings.average_mileage") }}:
              {{ Math.trunc(booking.booking_mileage / booking.days) }}
              {{ $t("bookings.km_day") }}
            </div>
          </v-flex>
          <div xs12 md1 class="vl"></div>
          <v-flex xs12 md4 class="mt-2">
            <h2 v-if="booking.counts && booking.counts.length == 0" class="text-center">
              {{ $t("bookings.no_counts") }}
            </h2>
            <v-expansion-panels v-if="booking.counts && booking.counts.length != 0" v-model="paymentsPanel"
              class="start-tour-booking-3">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="mb-0 d-flex">
                    {{ $t("bookings.counts") }}
                  </h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("custom.number") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.date") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.sum") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.type") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.group") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="count in booking.counts" :key="count.index"
                          :class="count.operation ? '' : 'red--text'">
                          <td class="text-center">
                            {{ count.id }}
                          </td>
                          <td class="text-center">
                            {{ formatCreatedAt(count.created_at) }}
                          </td>
                          <td class="text-center">
                            {{ count.operation ? "" : "-" }}{{ count.sum
                            }}
                          </td>
                          <td class="text-center" v-if="count.cash">
                            <v-icon class="text-center" color="success" v-tooltip="{
                              content: $t('money.cash'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }">
                              mdi-cash
                            </v-icon>
                          </td>
                          <td class="text-center" v-if="count.cashless">
                            <v-icon class="text-center" color="warning" v-tooltip="{
                              content: $t('money.terminal'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }">
                              mdi-contactless-payment-circle-outline
                            </v-icon>
                          </td>
                          <td class="text-center" v-if="count.entity">
                            <v-icon class="text-center" color="secondery" v-tooltip="{
                              content: $t('money.to_entity'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }">
                              mdi-bank
                            </v-icon>
                          </td>
                          <td class="text-center" v-if="count.cash_card">
                            <v-icon class="text-center" color="info" v-tooltip="{
                              content: $t('money.card_to_card'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }">
                              mdi-credit-card-check-outline
                            </v-icon>
                          </td>
                          <td class="text-center" v-if="count.client_balance">
                            <v-icon class="text-center" color="error" v-tooltip="{
                              content: $t('money.client_balance'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }">
                              mdi-account-arrow-right
                            </v-icon>
                          </td>
                          <td class="text-center">
                            {{ translateCategory(count.group) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- Продления -->
            <v-expansion-panels v-if="booking.prolongs && booking.prolongs.length != 0" v-model="prolongsPanel"
              class="mt-2">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="mb-0 d-flex">
                    {{ $t("bookings.prolongs") }}
                  </h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ $t("bookings.date_prolong") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.days") }}
                          </th>
                          <th class="text-center">
                            {{ $t("bookings.price") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.total") }}
                          </th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(prolong, index) in booking.prolongs" :key="prolong.index">
                          <td class="text-center">
                            {{ formatPrologDate(prolong.last_date) }}
                          </td>
                          <td class="text-center">
                            {{ prolong.prolong_days }}
                          </td>
                          <td class="text-center">
                            {{ prolong.new_price }}
                          </td>
                          <td class="text-center">
                            {{ prolong.new_price * prolong.prolong_days
                            }}
                          </td>
                          <td class="text-center">

                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- Подарки -->
            <v-expansion-panels v-if="booking.gifts && booking.gifts.length != 0" v-model="giftsPanel" class="mt-2">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="mb-0 d-flex">
                    {{ $t("gifts.title") }}
                  </h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ $t("gifts.created") }}
                          </th>
                          <th class="text-center">
                            {{ $t("gifts.type") }}
                          </th>
                          <th class="text-center">
                            {{ $t("gifts.count_short") }}
                          </th>
                          <th class="text-center">
                            {{ $t("bookings.price") }}
                          </th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="gift in booking.gifts" :key="gift.index">
                          <td class="text-center">
                            {{ formatPrologDate(gift.created_at) }}
                          </td>
                          <td class="text-center">
                            {{
                              gift.gift_type == 0
                                ? $t("gifts.days")
                                : $t("gifts.hours")
                            }}
                          </td>
                          <td class="text-center">
                            {{ gift.count }}
                          </td>
                          <td class="text-center">
                            {{ gift.price }}
                          </td>
                          <td class="text-center">
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
          <div xs12 md1 class="vl"></div>
          <v-flex xs12 md3 class="mx-auto start-tour-booking-4">
            <v-row class="mb-3 mt-2">
              <v-col class="text-center"
                v-if="company && company.tinkoff && company.tinkoff.tinkoff_enabled && client.tinkoff_card_number">
                <h2 class="">
                  {{ $t("tinkoff.card_list") }}:
                </h2>
                <h2 class="subtitle-1 d-flex align-center justify-center mt-3">
                  {{ client.tinkoff_card_number }}
                  <v-icon @click="removeCardCreate()" v-if="client.tinkoff_card_number" class="mx-2"
                    style="cursor: pointer" color="error">
                    mdi-credit-card-remove-outline
                  </v-icon>
                </h2>
              </v-col>
              <v-col class="text-center"
                v-else-if="company && company.tinkoff && company.tinkoff.tinkoff_enabled && !client.tinkoff_card_number">
                <v-btn @click="showRebillDialog = true" class="mx-2" style="cursor: pointer" color="success">
                  {{ $t("transfers.add_card") }}
                  <v-icon class="ml-2">
                    mdi-credit-card-plus-outline
                  </v-icon>
                </v-btn>
              </v-col>
              <!-- <v-col class="text-center" v-else>
                <v-btn class="mx-2" outlined color="primary" v-if="
                  !booking.in_rent &&
                  booking.counts &&
                  booking.counts.length === 0 &&
                  !booking.technical
                ">{{ $t("bookings.pay") }}</v-btn>
                <v-btn class="mx-2" :color="selectColorPaid(booking)" v-if="
                  (booking.counts && booking.counts.length > 0) ||
                  (booking.in_rent && !booking.technical)
                " v-tooltip="{
                  content: `${calculatePayments(booking) != $t('bookings.paid')
                    ? $t('bookings.part_paid')
                    : $t('bookings.full_paid')
                    }`,
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }">{{ calculatePayments(booking) }}</v-btn>
              </v-col> -->
              <v-col v-if="
                booking.booking_photos_data &&
                booking.booking_photos_data.length > 0
              ">
                <hr class="mb-2 mt-3" />
                <booking-photo-gallery :photos="booking.booking_photos_data"></booking-photo-gallery>
                <hr class="mb-2 mt-3" v-if="
                  booking.generated_documents &&
                  booking.generated_documents.length
                " />
                <h3 class="headline text-center" v-if="
                  booking.generated_documents &&
                  booking.generated_documents.length
                ">
                  {{ $t("bookings.signed_documents") }}
                </h3>
                <v-row class="mt-1" justify="space-around" v-if="!booking.technical">
                  <v-simple-table dense v-if="
                    booking.generated_documents &&
                    booking.generated_documents.length
                  ">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ $t("custom.created") }}
                          </th>
                          <th class="text-center">
                            {{ $t("bookings.template") }}
                          </th>
                          <th class="text-center">
                            {{ $t("bookings.control") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="document in booking.generated_documents" :key="document.index">
                          <td class="text-center">
                            {{ formatCreatedAt(document.created_at) }}
                          </td>
                          <td class="text-center">
                            {{ document.name }}
                          </td>
                          <td class="text-center">
                            <v-icon color="secondery" class="mr-2"
                              @click="downloadSignedDocBottom(document.link)">mdi-file-download</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
              </v-col>
            </v-row>
          </v-flex>
        </v-flex>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showRebillDialog" :retain-focus="false" persistent max-width="1000px">
      <v-card>
        <v-card-title class="text-center">
          {{ $t("tinkoff.adding_card") }}
          <v-spacer></v-spacer>
          <v-icon @click.stop="showRebillDialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-flex class="">
            <!-- <v-flex md12 class="pr-5">
              <v-flex class="d-flex">
                <v-text-field v-model.trim="payment_sum" class="mt-5 mr-3" :label="$t('custom.sum')"></v-text-field>
              </v-flex>
            </v-flex> -->
            <v-flex md12 v-if="company && company.tinkoff">
              <p class="text-center subtitle-1">
                <a v-if="company.tinkoff.tarrifs_link" :href="company.tinkoff.tarrifs_link" target="_blank">{{
                  $t("tinkoff.tarrifs_link") }}</a><span v-if="company.tinkoff.tarrifs_link"> | </span>
                <a v-if="company.tinkoff.agreement_link" :href="company.tinkoff.agreement_link" target="_blank">{{
                  $t("tinkoff.agreement_link") }}</a><span v-if="company.tinkoff.agreement_link"> | </span>
                <a v-if="company.tinkoff.privacy_link" :href="company.tinkoff.privacy_link" target="_blank">{{
                  $t("tinkoff.privacy_link") }}</a>
              </p>
            </v-flex>
          </v-flex>
          <p class="text-center">
            {{ $t("tinkoff.payment_for_text") }}
            {{ $t("tinkoff.remove_card_info") }}
          </p>
          <v-checkbox v-model="accept_rebill" class="subtitle-1" hide-details dense
            label="Согласен с условиями использования, тарифами, а так же на обработку персональных данных"></v-checkbox>
          <v-checkbox v-model="accept_rebill_1" class="subtitle-1" hide-details dense
            label="Сохранить карту для дальнейших автоплатежей (продление аренды и других услуг)"></v-checkbox>
        </v-card-text>

        <v-card-actions class="text-center">
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" :disabled="!accept_rebill || !accept_rebill_1" @click="addCardCreate()">{{
            $t("transfers.add_card")
          }}</v-btn>
          <v-btn text
            @click="showRebillDialog = false, payment_sum = 0, accept_rebill = false, accept_rebill_1 = false">{{
              $t("custom.close") }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import BookingPhotoGallery from "./BookingPhotoGallery";
import { Capacitor } from "@capacitor/core";
import { Filesystem } from "@capacitor/filesystem";
import { saveAs } from "file-saver";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { load } from 'recaptcha-v3';
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      booking: null,
      company: null,
      client: null,
      last_name: null,
      booking_id: null,
      prolongsPanel: 0,
      paymentsPanel: 0,
      giftsPanel: 0,
      showRebillDialog: false,
      rebill_limit: 100,
      rebill_sum: 500,
      accept_rebill: false,
      accept_rebill_1: false,
      payment_sum: 0,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    if (this.$route.query.last_name && this.$route.query.booking_id) {
      this.last_name = this.$route.query.last_name;
      this.booking_id = this.$route.query.booking_id;
      this.recaptcha();
    }
  },
  computed: {
    progressPercent() {
      return (
        (this.calculateRentPaymentsSum(this.booking) /
          (this.booking.rental_cost / this.paidDays) /
          this.paidDays) *
        100
      );
    },
    paidDays() {
      let days =
        Math.round(
          this.calculateRentPaymentsSum(this.booking) /
          (this.booking.rental_cost / this.booking.days)
        ) || this.booking.days;
      if (this.gifts && this.gifts.length > 0) {
        let gift_days = 0;
        this.gifts.forEach((gift) => {
          if (gift.gift_type == 0) {
            gift_days += gift.count;
          }
        });
        let fact_days = days - gift_days;
        days =
          Math.round(
            (this.calculateRentPaymentsSum(this.booking) /
              (this.booking.rental_cost / fact_days)) *
            100
          ) / 100;
      }
      return days;
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
  },
  methods: {
    async recaptcha() {
      this.isLoading = true;
      await load("6LcfusYqAAAAAHMLdwvqebnSyqNjrx01deSYPL4U", {
        autoHideBadge: true,
        useRecaptchaNet: true,
        useEnterprise: true,
      })
      .then((recaptcha) => {
        recaptcha.execute("submit").then((token) => {
          this.findBooking();
          this.postToRecaptchaWithToken(token);
        });
      })
      .catch((error) => {
        this.setError(error, this.$t("errors.auth"));
        console.log(error);
      }).finally(() => {
        // this.isLoading = false;
      });
    },
    postToRecaptchaWithToken(token) {
      this.axios({
        url: "/v1/projects/rentprog-b5205/assessments?key=AIzaSyDCQ_inItyElKpGZf_7nxxTtecSGbsO5os",
        baseURL: "https://recaptchaenterprise.googleapis.com",
        method: "post",
        data: {
          "event": {
            "token": token,
            "expectedAction": "Registration",
            "siteKey": "6LcfusYqAAAAAHMLdwvqebnSyqNjrx01deSYPL4U",
          }
        },
      })
        .then((response) => {
          console.log("risk score", response.data.riskAnalysis.score);
        })
        .catch((error) => {
          console.log(error.response.data.error);
        });
    },
    // async recaptcha() {
    //   await this.$recaptchaLoaded()
    //     .then(() => this.findBooking())
    //     .catch((error) => {
    //       this.setError(error, this.$t("errors.auth"));
    //       console.log(error);
    //     });
    // },
    async findBooking() {
      if (this.booking_id == '' || this.last_name == '' || this.booking_id == null || this.last_name == null || isNaN(this.booking_id)) {
        if (isNaN(this.booking_id)) {
          this.$swal({
            position: "center",
            showConfirmButton: true,
            icon: "error",
            title: this.$t("errors.error"),
            text: this.$t("custom.booking_number_is_not_number"),
          });
          return;
        }
        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: this.$t("errors.error"),
          text: this.$t("custom.no_data"),
        });
        return;
      }
      this.isLoading = true;
      await this.axios
        .post(`/api/v1/find_booking_for_client_v2`, {
          booking_id: this.booking_id,
          last_name: this.last_name,
        })
        .then((response) => {
          // проверяем что в текущем роуте нет параметров
          if (this.$route.query.last_name == null || this.$route.query.booking_id == null) {
            this.$router.replace({ query: { last_name: this.last_name, booking_id: this.booking_id } })
          }
          if (response.data.error) {
            this.isLoading = false;
            this.$swal({
              position: "center",
              showConfirmButton: true,
              icon: "error",
              title: this.$t("errors.error"),
              text: this.$t("custom.no_data_in_table"),
            });
            return;
          }
          // if(this.last_name == null || this.last_name == '' || this.booking_id == null || this.booking_id == '') {
          // this.$router.replace({query: { last_name: this.last_name, booking_id: this.booking_id } })
          // }
          this.booking = response.data.booking;
          this.company = response.data.company;
          this.client = response.data.client;
          this.isLoading = false;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.auth"));
          console.log(error);
        });
    },
    translateBookingState(state) {
      switch (state) {
        case "Новая":
          return this.$t("bookings_states.new");
        case "В обработке":
          return this.$t("bookings_states.in_work");
        case "Недозвон":
          return this.$t("bookings_states.abandoned");
        case "Отказ клиента":
          return this.$t("bookings_states.decline");
        case "Нет машин":
          return this.$t("bookings_states.no_cars");
        case "Ожидает ответа клиента":
          return this.$t("bookings_states.wait_answer");
        case "Активная":
          return this.$t("bookings_states.active");
        case "Отмена":
          return this.$t("bookings_states.cancel");
        case "Отъездила":
          return this.$t("bookings_states.rided");
        case "Вернуть залог":
          return this.$t("bookings_states.return_deposit");
        case "Подтверждена":
          return this.$t("bookings_states.accepted");
        case "Не подтверждена":
          return this.$t("bookings_states.not_accepted");
        case "Другое":
          return this.$t("bookings_states.other");
        default:
          return this.$t("bookings_states.in_work");
      }
    },
    addCardCreate() {
      this.isLoading = true;
      let self = this;
      // Создаём транзакцию у нас
      this.axios
        .post(
          "/api/v1/integrations/tinkoff/add_card",
          {
            client_id: this.client.id,
            current_url: window.location.href,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          if (response.data.Success == true && response.data.PaymentURL) {
            // redirect new window
            window.open(response.data.PaymentURL, "_blank");
          } else {
            this.$swal({
              icon: "error",
              title: response.data.Message,
              text: response.data.Details,
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.error"));
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: this.$t("errors.error"),
            text: error,
          });
        })
        .finally(() => (this.isLoading = false));
    },
    removeCardCreate() {
      this.$swal({
        title: this.$t("tinkoff.remove_card_title"),
        text: this.$t("tinkoff.remove_card_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.yes"),
        cancelButtonText: this.$t("custom.no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          // Создаём транзакцию у нас
          this.axios
            .post(
              "/api/v1/integrations/tinkoff/remove_card",
              {
                client_id: this.client.id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then(() => {
              this.client.tinkoff_card_number = null;
            })
            .catch((error) => {
              this.isLoading = false;
              this.setError(error, this.$t("errors.error"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.error"),
                text: error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      });
    },
    calculatePayments(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
          if (count.completed) {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return self.$t("bookings.paid");
        } else {
          return paid - booking_sum >= 0
            ? `+ ${paid - booking_sum}`
            : ` ${paid - booking_sum}`;
        }
      }
    },
    calculatePaymentsSum(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        // Проверяем что значения числовые
        let check_number_rental_cost = Number(booking.rental_cost) === booking.rental_cost;
        let check_number_delivery = Number(booking.delivery) === booking.delivery;
        let check_number_delivery_end = Number(booking.delivery_end) === booking.delivery_end;
        let check_number_equipment = Number(booking.equipment) === booking.equipment;
        let check_number_insurance = Number(booking.insurance) === booking.insurance;
        let check_number_clean_payment = Number(booking.clean_payment) === booking.clean_payment;
        let check_number_add_drivers_cost = Number(booking.add_drivers_cost) === booking.add_drivers_cost;
        let check_number_hours_cost = Number(booking.hours_cost) === booking.hours_cost;
        let check_number_mileage_cost = Number(booking.mileage_cost) === booking.mileage_cost;
        let check_number_damage = Number(booking.damage) === booking.damage;
        let check_number_gas = Number(booking.gas) === booking.gas;
        let check_number_fine = Number(booking.fine) === booking.fine;
        let check_number_other = Number(booking.other) === booking.other;
        let check_number_other_end = Number(booking.other_end) === booking.other_end;
        let rental_cost = check_number_rental_cost ? booking.rental_cost : 0;
        let delivery = check_number_delivery ? booking.delivery : 0;
        let delivery_end = check_number_delivery_end ? booking.delivery_end : 0;
        let equipment = check_number_equipment ? booking.equipment : 0;
        let insurance = check_number_insurance ? booking.insurance : 0;
        let clean_payment = check_number_clean_payment ? booking.clean_payment : 0;
        let add_drivers_cost = check_number_add_drivers_cost ? booking.add_drivers_cost : 0;
        let hours_cost = check_number_hours_cost ? booking.hours_cost : 0;
        let mileage_cost = check_number_mileage_cost ? booking.mileage_cost : 0;
        let damage = check_number_damage ? booking.damage : 0;
        let gas = check_number_gas ? booking.gas : 0;
        let fine = check_number_fine ? booking.fine : 0;
        let other = check_number_other ? booking.other : 0;
        let other_end = check_number_other_end ? booking.other_end : 0;
        let hours_cost_end = booking.hours_cost_end;
        let booking_sum = rental_cost +
          delivery +
          delivery_end +
          equipment +
          insurance +
          clean_payment +
          add_drivers_cost +
          hours_cost +
          mileage_cost +
          damage +
          gas +
          fine +
          other +
          other_end +
          hours_cost_end;
        booking.counts.forEach((count) => {
          // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
          if (count.completed) {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        return paid;
      }
    },
    calculateRentPaymentsSum(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        booking.counts.forEach((count) => {
          if (count.group == "Оплата аренды") {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        return paid;
      }
    },
    selectColorPaid(booking) {
      if (booking != undefined) {
        let paid = 0;
        // Проверяем что значения числовые
        let check_number_rental_cost = Number(booking.rental_cost) === booking.rental_cost;
        let check_number_delivery = Number(booking.delivery) === booking.delivery;
        let check_number_delivery_end = Number(booking.delivery_end) === booking.delivery_end;
        let check_number_equipment = Number(booking.equipment) === booking.equipment;
        let check_number_insurance = Number(booking.insurance) === booking.insurance;
        let check_number_clean_payment = Number(booking.clean_payment) === booking.clean_payment;
        let check_number_add_drivers_cost = Number(booking.add_drivers_cost) === booking.add_drivers_cost;
        let check_number_hours_cost = Number(booking.hours_cost) === booking.hours_cost;
        let check_number_mileage_cost = Number(booking.mileage_cost) === booking.mileage_cost;
        let check_number_damage = Number(booking.damage) === booking.damage;
        let check_number_gas = Number(booking.gas) === booking.gas;
        let check_number_fine = Number(booking.fine) === booking.fine;
        let check_number_other = Number(booking.other) === booking.other;
        let check_number_other_end = Number(booking.other_end) === booking.other_end;
        let rental_cost = check_number_rental_cost ? booking.rental_cost : 0;
        let delivery = check_number_delivery ? booking.delivery : 0;
        let delivery_end = check_number_delivery_end ? booking.delivery_end : 0;
        let equipment = check_number_equipment ? booking.equipment : 0;
        let insurance = check_number_insurance ? booking.insurance : 0;
        let clean_payment = check_number_clean_payment ? booking.clean_payment : 0;
        let add_drivers_cost = check_number_add_drivers_cost ? booking.add_drivers_cost : 0;
        let hours_cost = check_number_hours_cost ? booking.hours_cost : 0;
        let mileage_cost = check_number_mileage_cost ? booking.mileage_cost : 0;
        let damage = check_number_damage ? booking.damage : 0;
        let gas = check_number_gas ? booking.gas : 0;
        let fine = check_number_fine ? booking.fine : 0;
        let other = check_number_other ? booking.other : 0;
        let other_end = check_number_other_end ? booking.other_end : 0;
        let hours_cost_end = booking.hours_cost_end;
        let booking_sum = rental_cost +
          delivery +
          delivery_end +
          equipment +
          insurance +
          clean_payment +
          add_drivers_cost +
          hours_cost +
          mileage_cost +
          damage +
          gas +
          fine +
          other +
          other_end +
          hours_cost_end;
        booking.counts.forEach((count) => {
          // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
          if (count.completed) {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return "success";
        } else if (paid - booking_sum > 0) {
          return "warning";
        } else if (paid - booking_sum < 0) {
          return "error";
        }
      }
    },
    downloadSignedDocBottom(link) {
      saveAs(`https://storage.yandexcloud.net/rentprog/${link}`);
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
        case "Пополнение баланса клиента":
          group_text = this.$t("groups_counts.top_up_client_balance");
          break;
        case "Выплаты агентам":
          group_text = this.$t("groups_counts.agent_payments");
          break;
      }
      return group_text;
    },
    downloadIcalendar(id) {
      this.axios.get(`/api/v1/bookings/icalendar_file/${id}`, {
        headers: {
          Authorization: this.$store.getters.getAuthToken,
        },
      })
        .then((response) => {
          const blob_data = new Blob([response.data], { type: "text/calendar" });
          if (!this.isNative) {
            saveAs(blob_data, 'booking.ics');
          } else {
            if (Filesystem.checkPermissions()) {
              const getBase64FromBlob = async (blob) => {
                return new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                  }
                });
              }
              getBase64FromBlob(blob_data).then(
                (base64) => {
                  // save file
                  async function writeFile() {
                    return await Filesystem.writeFile({
                      path: 'booking.ics',
                      data: base64,
                      directory: Directory.Documents,
                    });
                  };
                  let file_uri = null
                  let writedFile = writeFile().then((getUriResult) => {
                    // share file opened file
                    let shareFile = async () => {
                      await Share.share({
                        title: 'Download document iCalendar format',
                        files: [getUriResult.uri],
                      }).catch((error) => {
                        console.log("shareFile", error)
                      });
                    };
                    let sharedFile = shareFile();
                  }).catch((error) => {
                    console.log("shareFile", error)
                  });
                }
              );
            } else {
              Filesystem.requestPermissions();
            }
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
        });
    },
    formatCreatedAt(date) {
      if (date) {
        return moment.parseZone(date).format("DD-MM-YY H:mm");
      } else {
        return "-";
      }
    },
    formatPrologDate(date) {
      return moment.utc(date).format("DD MMM H:mm");
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.isLoading = false;
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    BookingPhotoGallery,
  }
};
</script>
