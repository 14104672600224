<template>
  <v-row>
    <v-col cols="12" sm="6" offset-sm="3">
      <v-card>
        <v-container fluid>
          <!-- <h2 class="text-center my-2">{{ $t("other.photo_booking") }}</h2> -->
          <v-row v-if="photos.length > 0">
            <v-col
              v-for="(item, index) in sortedPhotos"
              :key="item.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-card flat tile class="d-flex flex-column my-2">
                <v-img
                  :src="source(item)"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="showGallery(index)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-row justify="center" class="mt-5 align-center d-flex">
                  <v-icon
                    class="mr-1"
                    color="blue-grey"
                    @click="showGallery(index)"
                  >
                    mdi-magnify
                  </v-icon>
                  <v-icon
                    class="mr-1"
                    color="warning"
                    @click="deleteItem(item)"
                  >
                    mdi-delete-forever
                  </v-icon>
                  <v-icon class="" color="info" @click="downloadItem(item)">
                    mdi-content-save-outline
                  </v-icon>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else class="d-flex text-center justify-center my-3">
            <v-card flat tile class="d-flex text-center justify-center">
              {{ $t("other.no_photo") }}
            </v-card>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <LightBox ref="lightbox" :media="media" :show-light-box="showLightBox"></LightBox>
  </v-row>
</template>
<script>
import { saveAs } from "file-saver";
import draggable from "vuedraggable";
import LightBox from 'vue-it-bigger'
import('vue-it-bigger/dist/vue-it-bigger.min.css') // when using webpack
export default {
  props: {
    booking_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      photos: [],
      media: [],
      showLightBox: false,
      link_full_image: "",
      dialog: false,
    };
  },
  created() {
    this.axios
      .get(`api/v1/booking_photos/${this.booking_id}`, {
        headers: {
          Authorization: this.$store.getters.getAuthToken,
        },
      })
      .then((response) => {
        this.photos = response.data;
        this.media = this.photos.map((item) => {
          let type = "image";
          let thumb = item.url;
          if (item.content_type && item.content_type.split("/")[0] == "video") {
            type = "video";
            thumb = "/img/play-button96.png";
          }
          if (type == "image") {
            return {
              type: type,
              thumb: thumb,
              src: item.url,
            };
          } else {
            return {
              type: type,
              thumb: thumb,
              sources: [
                {
                  src: item.url,
                  type: item.content_type,
                },
              ],
              width: 800,
              height: 600,
            };
          }
        });
      })
      .catch((error) => {
        this.setError(error, this.$t("errors.load_file"));
        console.log(error);
      });
  },
  computed: {
    sortedPhotos() {
      return this.photos;
      // if (this.photos && this.photos.length > 0) {
      //   return this.photos.sort((a, b) => {
      //     return b.position - a.position;
      //   });
      // } else {
      //   return [];
      // }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    screenFullImage(item) {
      this.isLoading = true;
      this.axios
        .get(`api/v1/booking_photos_full/${item.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.link_full_image = response.data.url;
          this.dialog = true;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showGallery(index) {
      console.log('showGallery')
      this.$refs.lightbox.showImage(index)
    },
    async downloadItem(item) {
      this.isLoading = true;
      this.axios
        .get(`api/v1/booking_photos_full/${item.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.isLoading = false;
          saveAs(`${response.data.url}`);
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    source(item) {
      if (item.content_type && item.content_type.split("/")[0] == "video") {
        return "/img/play-button96.png";
      } else {
        return item.small_url ? item.small_url : item.url;
      }
    },
    deleteItem(item) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/booking_photos/${item.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.photos.splice(this.photos.indexOf(item), 1);
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  icon: "success",
                  title: this.$t("custom.deleted"),
                  text: this.$t("other.delete_file"),
                });
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_file"))
              );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    movePhoto(e) {
      let photo_id = this.photos[e.oldIndex].id;
      this.photos.splice(e.newIndex, 0, this.photos.splice(e.oldIndex, 1)[0]);
      let self = this;
      // console.log(e.newIndex, e.oldIndex);
      // this.photos.forEach((item) => {
      //   item.sort = self.photos.indexOf(item);
      // });
      this.isLoading = true;
      this.axios
        .patch(`api/v1/booking_photos_sorting/${photo_id}`,
          {
            booking_photos: {
              new_position: e.newIndex + 1,
              old_position: e.oldIndex + 1,
            },
          },
          {
            headers: {
              Authorization: self.$store.getters.getAuthToken,
            },
          }
        )
        .then(() => {
          // this.photos = this.photos.sort((a, b) => {
          //   return a.sort - b.sort;
          // });
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            icon: "success",
            title: this.$t("custom.saved"),
          });
        })
        .catch((error) => this.setError(error, this.$t("errors.error")))
        .finally(() => (this.isLoading = false));
    },
    sortUp(item) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`api/v1/booking_photos/sort_up/${item.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then(() => {
            item.sort += 1;
            let foundIndex = self.photos.findIndex(
              (element) => element.id === item.id
            );
            this.photos.splice(foundIndex, 1, item);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("other.sort_files_title"),
              text: this.$t("other.sort_files_text"),
            });
          })
          .catch((error) => this.setError(error, this.$t("errors.sort_file")))
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    sortDown(item) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`api/v1/booking_photos/sort_down/${item.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then(() => {
            item.sort -= 1;
            let foundIndex = self.photos.findIndex(
              (element) => element.id === item.id
            );
            this.photos.splice(foundIndex, 1, item);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("other.sort_files_title"),
              text: this.$t("other.sort_files_text"),
            });
          })
          .catch((error) => this.setError(error, this.$t("errors.sort_file")))
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    draggable,
    LightBox
  },
};
</script>
